/* eslint-disable simple-import-sort/sort */
// @flow

// Staff Pages
import assignCliniciansModalTranslations from 'platform_web/pages/Staff/Individuals/components/AssignCliniciansModal/localizedStrings.json'
import assignIndividualsModalTranslations from 'platform_web/pages/Staff/Clinicians/components/AssignIndividualsModal/localizedStrings.json'
import dischargeModalTranslations from 'platform_web/pages/Staff/Individuals/components/DischargeModal/localizedStrings.json'
import reinviteIndividualsModalTranslations from 'platform_web/pages/Staff/Individuals/components/ReinviteModal/localizedStrings.json'
import removeIndividualsModalTranslations from 'platform_web/pages/Staff/Individuals/components/RemoveIndividualsModal/localizedStrings.json'
import resendInvitationsModalTranslations from 'platform_web/pages/Staff/Individuals/components/ResendInvitationsModal/localizedStrings.json'
import requestAssessmentsModalTranslations from 'platform_web/pages/Staff/Individuals/components/RequestAssessmentsModal/localizedStrings.json'
import staffEmployeesTableTranslations from 'platform_web/pages/Staff/Employees/components/StaffEmployeesTable/localizedStrings.json'
import staffEmployeesMultiSelectActionsTranslations from 'platform_web/pages/Staff/Employees/components/MultiSelectActions/localizedStrings.json'
import staffEmployeesRemoveEmployeesModalTranslations from 'platform_web/pages/Staff/Employees/components/RemoveEmployeesModal/localizedStrings.json'
import staffEmployeesResendEmployeesModalTranslations from 'platform_web/pages/Staff/Employees/components/ResendEmployeesModal/localizedStrings.json'
import staffEscalationsTableDataGridTranslations from 'platform_web/components/Staff/StaffEscalationsTableDataGrid/localizedStrings.json'
import staffIndividualsTableFooterTranslations from 'platform_web/pages/Staff/Individuals/components/StaffIndividualsTableFooter/localizedStrings.json'
import staffIndividualsTableTranslations from 'platform_web/pages/Staff/Individuals/components/IndividualsTable/localizedStrings.json'
import standardIndividualsTableComponentTranslations from 'platform_web/pages/Staff/Individuals/components/StandardIndividualsTableComponent/localizedStrings.json'
import staffCliniciansTableTranslations from 'platform_web/pages/Staff/Clinicians/localizedStrings.json'

const staffTranslations = {
  en: {
    employee: {
      staffEmployeesTableComponent: staffEmployeesTableTranslations.en,
      staffEmployeesMultiSelectComponent:
        staffEmployeesMultiSelectActionsTranslations.en,
      staffEmployeesRemoveEmployeesModalComponent:
        staffEmployeesRemoveEmployeesModalTranslations.en,
      staffEmployeesResendEmployeesModalComponent:
        staffEmployeesResendEmployeesModalTranslations.en,
    },
    individual: {
      assignCliniciansModalPage: assignCliniciansModalTranslations.en,
      dischargeModalPage: dischargeModalTranslations.en,
      individualsTablePage: staffIndividualsTableTranslations.en,
      reinviteIndividualsModalPage: reinviteIndividualsModalTranslations.en,
      resendInvitationsModalPage: resendInvitationsModalTranslations.en,
      removeIndividualsModalPage: removeIndividualsModalTranslations.en,
      requestAssessmentModalPage: requestAssessmentsModalTranslations.en,
      staffIndividualsTableFooterPage:
        staffIndividualsTableFooterTranslations.en,
      standardIndividualsTableComponentPage:
        standardIndividualsTableComponentTranslations.en,
    },
    clinician: {
      clinicianTablePage: staffCliniciansTableTranslations.en,
      assignIndividualsModalPage: assignIndividualsModalTranslations.en,
    },
    esclation: {
      staffEscalationsTableDataGridPage:
        staffEscalationsTableDataGridTranslations.en,
    },
  },
  fr: {
    employee: {
      staffEmployeesTableComponent: staffEmployeesTableTranslations.fr,
      staffEmployeesMultiSelectComponent:
        staffEmployeesMultiSelectActionsTranslations.fr,
      staffEmployeesRemoveEmployeesModalComponent:
        staffEmployeesRemoveEmployeesModalTranslations.fr,
      staffEmployeesResendEmployeesModalComponent:
        staffEmployeesResendEmployeesModalTranslations.fr,
    },
    individual: {
      assignCliniciansModalPage: assignCliniciansModalTranslations.fr,
      dischargeModalPage: dischargeModalTranslations.fr,
      individualsTablePage: staffIndividualsTableTranslations.fr,
      reinviteIndividualsModalPage: reinviteIndividualsModalTranslations.fr,
      resendInvitationsModalPage: resendInvitationsModalTranslations.fr,
      removeIndividualsModalPage: removeIndividualsModalTranslations.fr,
      requestAssessmentModalPage: requestAssessmentsModalTranslations.fr,
      staffIndividualsTableFooterPage:
        staffIndividualsTableFooterTranslations.fr,
      standardIndividualsTableComponentPage:
        standardIndividualsTableComponentTranslations.fr,
    },
    clinician: {
      clinicianTablePage: staffCliniciansTableTranslations.fr,
      assignIndividualsModalPage: assignIndividualsModalTranslations.fr,
    },
    esclation: {
      staffEscalationsTableDataGridPage:
        staffEscalationsTableDataGridTranslations.fr,
    },
  },
}

export default staffTranslations
