// @flow

import React, { useEffect } from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'

import LoadMore from 'react-ui/components/LoadMore/LoadMore'

import {
  cliniciansTableFragment,
  cliniciansTablePaginatedQuery,
} from '../query/CliniciansTable'

import StaffCliniciansTable from './StaffCliniciansTable'

type PropsType = {
  multiSelectPayload: any,
  useIndividualTablePayload: any,
}

const CliniciansTable = (props: PropsType) => {
  const { useIndividualTablePayload, multiSelectPayload } = props

  const { count, queryVariables } = useIndividualTablePayload

  const {
    allSelected,
    deselectedRoleIds,
    setMultiSelectState,
  } = multiSelectPayload

  const queryData = useLazyLoadQuery(
    cliniciansTablePaginatedQuery,
    queryVariables,
  )

  const { data, loadNext, hasNext } = usePaginationFragment(
    cliniciansTableFragment.user,
    queryData?.viewer?.currentUser,
  )

  const clinicians = data.current_role.tenant.roles.edges.map(item => ({
    ...item.node,
  }))

  // MUTLI_SELECT: If all selected, and load more is clicked, this adds newly loaded data to selected items
  useEffect(
    () => {
      if (!allSelected) return

      const ids = clinicians.map(item => item?.id)

      const filteredIds = ids.filter(id => !deselectedRoleIds.includes(id))

      setMultiSelectState({ selectedRoleIds: filteredIds })
    },
    [data],
  )

  return (
    <>
      {/* Table Component */}
      <StaffCliniciansTable
        clinicians={clinicians}
        multiSelectPayload={multiSelectPayload}
      />

      {/* Pagination Loader */}
      {hasNext && <LoadMore handleClick={() => loadNext(count)} />}
    </>
  )
}

export default CliniciansTable
