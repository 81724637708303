// @flow

import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'

type RoleType = 'LEAD_CLINICIAN'

type ACTIONS_FOR_CLINICIAN_TYPE = Array<{
  actionName: string,
  roles: Array<RoleType>,
}>

const ACTIONS_FOR_CLINICIAN: ACTIONS_FOR_CLINICIAN_TYPE = [
  {
    actionName: 'assignIndividuals',
    roles: ['LEAD_CLINICIAN'],
  },
]

const useClinicianActions = () => {
  const { roleType } = useCurrentUserRoles()

  const getAllowedActions = () => {
    const allowedActions: Array<string> = ACTIONS_FOR_CLINICIAN.filter(action =>
      action.roles.includes(roleType),
    ).map(action => action.actionName)

    return allowedActions
  }

  return {
    actions: getAllowedActions(),
  }
}

export default useClinicianActions
