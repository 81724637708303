// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { map } from 'lodash/fp'

import { AnswerSetLoader } from 'components/AnswerSet'
import Accordion, { AccordionItem } from 'react-ui/components/Accordion'

import { healthHistoryUser } from './query/HealthHistory'

import type { HealthHistory_user } from './query/__generated__/HealthHistory_user.graphql'

type UserQuestionnairesQuestionSetType = {
  id: string,
  latest_submitted_non_skipped_answer_set: any,
  question_set: {
    label: string,
  },
}

type PropsType = {
  user: HealthHistory_user,
}

const RenderedAnswerSet = props => {
  const { answer_set, translation } = props

  if (answer_set) {
    return <AnswerSetLoader answer_set={answer_set} />
  }
  return translation('unanswered')
}

export type RenderedUserQuestionnairesQuestionSetProps = {
  user_questionnaires_question_set: UserQuestionnairesQuestionSetType,
}

const RenderedUserQuestionnairesQuestionSet = (
  props: RenderedUserQuestionnairesQuestionSetProps,
) => {
  const { user_questionnaires_question_set } = props

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'healthHistoryPage.healthHistory',
  })

  return (
    <Accordion>
      <AccordionItem
        heading={user_questionnaires_question_set.question_set.label}
      >
        <RenderedAnswerSet
          translation={translation}
          answer_set={
            user_questionnaires_question_set.latest_submitted_non_skipped_answer_set
          }
        />
      </AccordionItem>
    </Accordion>
  )
}

const HealthHistory = (props: PropsType) => {
  const { user: { health_history_user_questionnaires_question_sets } } = props

  return (
    <div data-component-id="HealthHistory">
      {map(
        uqqs => (
          <RenderedUserQuestionnairesQuestionSet
            key={uqqs.id}
            user_questionnaires_question_set={uqqs}
          />
        ),
        health_history_user_questionnaires_question_sets,
      )}
    </div>
  )
}

export const HealthHistoryLoader = createFragmentContainer(
  HealthHistory,
  healthHistoryUser,
)
