// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexContainer } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

const RecoveryTrajectoriesEmptyState = () => {
  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'recoveryTrajectoriesEmptyStateComponent',
  })

  return (
    <FlexContainer
      justifyContent="center"
      height="200px"
      alignItems="center"
      direction="column"
      marginTop="md"
    >
      <TextNew as="p" typography="bodyMdBold">
        {translation('paragraphOne')}
      </TextNew>

      <TextNew as="div" typography="bodyMdBold">
        {translation('paragraphTwo')}
      </TextNew>
    </FlexContainer>
  )
}

export default RecoveryTrajectoriesEmptyState
