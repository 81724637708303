/* eslint-disable simple-import-sort/sort */
// @flow

// Health Pages
import healthCardListTranslations from 'platform_web/features/HealthCard/HealthCardContainer/HealthCardList/localizedStrings.json'
import healthCardTranslations from 'platform_web/features/HealthCard/HealthCard/localizedStrings.json'
import healthPreferencesTranslations from 'platform_web/features/HealthCard/HealthPreferences/localizedStrings.json'
import healthPriorityListTranslations from 'platform_web/features/HealthCard/HealthCardContainer/HealthPriorityList/localizedStrings.json'

const healthTranslations = {
  en: {
    healthCardListPages: healthCardListTranslations.en,
    healthCardPages: healthCardTranslations.en,
    healthPreferencesPages: healthPreferencesTranslations.en,
    healthPriorityListPages: healthPriorityListTranslations.en,
  },
  fr: {
    healthCardListPages: healthCardListTranslations.fr,
    healthCardPages: healthCardTranslations.fr,
    healthPreferencesPages: healthPreferencesTranslations.fr,
    healthPriorityListPages: healthPriorityListTranslations.fr,
  },
}

export default healthTranslations
