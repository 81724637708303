// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'

import Lock from 'react-ui/assets/icons/lock.svg'
import Save from 'react-ui/assets/icons/save.svg'
import Timer from 'react-ui/assets/icons/timer.svg'
import Icon from 'react-ui/components/Icon'
import { Text } from 'care-ui'

import type { StaticQuestionnaireInformation_questionSet } from './__generated__/StaticQuestionnaireInformation_questionSet.graphql'

type PropsType = {
  questionSet: StaticQuestionnaireInformation_questionSet,
}

const container = ({ theme }) => ({
  display: 'flex',
  padding: '0.9rem 0',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  flexDirection: 'column',
  [theme.breakpoints.queries.md]: {
    flexDirection: 'row',
  },
})

const infoStyle = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  ':last-child': {
    border: 'none',
    marginBottom: 0,
  },
  [theme.care.breakpoints.queries.md]: {
    borderRight: '2px solid',
    borderRightColor: theme.palette.themelet.lightGrey.color,
    marginRight: '20px',
    paddingRight: '20px',
    marginBottom: 0,
  },
})

const StaticQuestionnaireInformationBase = (props: PropsType) => {
  const { questionSet } = props

  const { css } = useFela()

  const requiredTimeText = questionSet.time_required
    ? `approx. ${questionSet.time_required} to complete`
    : ''

  const information = [
    { text: requiredTimeText, icon: Timer, isRendered: requiredTimeText },
    { text: 'secure and confidential', icon: Lock, isRendered: true },
    { text: 'saved automatically', icon: Save, isRendered: true },
  ]

  const renderInformation = () =>
    information.map(
      info =>
        info.isRendered && (
          <Text size="sm" bold color="title" extend={infoStyle} key={info.text}>
            <Icon as={info.icon} scale={0.7} extend={{ marginRight: '10px' }} />
            <div>{info.text}</div>
          </Text>
        ),
    )

  return <div className={css(container)}>{renderInformation()}</div>
}

export const StaticQuestionnaireInformationLoader = createFragmentContainer(
  StaticQuestionnaireInformationBase,
  {
    questionSet: graphql`
      fragment StaticQuestionnaireInformation_questionSet on QuestionSet {
        time_required
      }
    `,
  },
)
