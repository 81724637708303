// @flow

import React, { useEffect } from 'react'
import { useRouter } from 'found'

import { useRouterReset } from 'services/routeConfig/Router'
import { LoadingSpinner } from 'care-ui'

export default function() {
  const resetRouter = useRouterReset()
  const { match: { params: { nextLocation } } } = useRouter()

  useEffect(
    () => {
      resetRouter()
      window.location.replace(nextLocation)
    },
    [nextLocation],
  )

  return <LoadingSpinner message="Signing in" />
}
