/* eslint-disable simple-import-sort/sort */
// @flow

// Shared Pages
import dataGridRowFooterContentTranslations from 'platform_web/components/DataGridRowFooter/DataGridRowFooterContent/localizedStrings.json'
import editPasswordFormTranslations from 'shared/ui/Forms/Fields/EnterPassword/localizedStrings.json'
import loadMoreComponentTranslations from 'react-ui/components/LoadMore/localizedStrings.json'
import releaseWarningBannerTranslations from 'platform_web/components/ReleaseWarning/ReleaseWarningBanner/localizedStrings.json'

// User Dashboard
import activityLogTranslations from 'platform_web/features/ActivityPanel/ActivityLog/localizedStrings.json'
import activityPanelTranslations from 'platform_web/features/ActivityPanel/localizedStrings.json'
import chartHeaderTranslations from 'platform_web/components/SummaryGraph/ChartHeader/localizedStrings.json'
import clinicianDropdownTranslations from 'platform_web/components/SummaryGraph/ClinicianDropdown/localizedStrings.json'
import clinicianNoteTranslations from 'platform_web/features/ActivityPanel/ClinicianNote/localizedStrings.json'
import requestAssessmentButtonTranslations from 'platform_web/components/SummaryGraph/RequestAssessmentButton/localizedStrings.json'
import staffDashboardSelectionTranslations from 'platform_web/components/Staff/StaffDashboardSelection/localizedStrings.json'
import staffDownloadReportButtonTranslations from 'platform_web/components/SummaryGraph/StaffDownloadReportButton/localizedStrings.json'
import staffNavigationTabsTranslations from 'platform_web/features/StaffNavigationTabs/localizedStrings.json'
import summaryGraphTranslations from 'platform_web/components/SummaryGraph/SummaryGraph/localizedStrings.json'
import supportPersonModalTranslations from 'platform_web/components/SupportPersonModal/SupportPersonModal/localizedStrings.json'
import supportTaskButtonTranslations from 'platform_web/components/SummaryGraph/SupportTaskButton/localizedStrings.json'
import trackableChartToggleTranslations from 'platform_web/components/SummaryGraph/TrackableChartToggle/localizedStrings.json'
import usePersonalDetailsTranslations from 'platform_web/hooks/usePersonalDetails/localizedStrings.json'

// Health History
import answerSetTranslations from 'platform_web/components/AnswerSet/localizedStrings.json'
import answerTableTranslations from 'platform_web/components/AnswerTable/localizedStrings.json'
import healthHistoryPageTranslations from 'platform_web/pages/Settings/HealthHistoryPage/localizedStrings.json'

// Help Now
import helpNowButtonTranslations from 'platform_web/features/Header/HelpNowButton/localizedStrings.json'

// Fitbit
import fitbitTranslations from 'react-ui/components/Fitbit/localizedStrings.json'

// Role
import roleTranslations from 'platform_web/pages/Role/localizedStrings.json'

// Role and Data Sharing
import researchDataSharingTranslations from 'platform_web/pages/public/ResearchDataSharing/localizedStrings.json'

// User Questionnaire
import userQuestionnaireTranslations from 'platform_web/components/UserQuestionnaire/localizedStrings.json'

// Session Timeout Warning
import sessionTimeoutWarningTranslations from 'platform_web/components/SessionTimeoutWarning/localizedStrings.json'

// Connected Tenants Page
import connectedTenantsTranslations from 'platform_web/pages/Settings/ConnectedTenantsPage/localizedStrings.json'

// Read Only Mode Page
import readOnlyModeTranslations from 'platform_web/components/ReadOnlyBanner/localizedStrings.json'

// Public Pages
import unauthorisedTranslations from 'platform_web/pages/UnauthorizedPage/localizedStrings.json'
import errorPageTranslations from 'platform_web/pages/PageError/localizedStrings.json'

const sharedTranslations = {
  en: {
    activityLogComponent: activityLogTranslations.en,
    activityPanelComponent: activityPanelTranslations.en,
    answerSetComponent: answerSetTranslations.en,
    answerTableComponent: answerTableTranslations.en,
    chartHeaderComponent: chartHeaderTranslations.en,
    clinicianDropdownComponent: clinicianDropdownTranslations.en,
    clinicianNoteComponent: clinicianNoteTranslations.en,
    connectedTenantsPage: connectedTenantsTranslations.en,
    dataGridRowFooterContentComponent: dataGridRowFooterContentTranslations.en,
    enterPasswordPage: editPasswordFormTranslations.en,
    healthHistoryPage: healthHistoryPageTranslations.en,
    errorPage: errorPageTranslations.en,
    helpNowButtonComponent: helpNowButtonTranslations.en,
    fitbitPages: fitbitTranslations.en,
    loadMoreComponent: loadMoreComponentTranslations.en,
    releaseWarningBannerComponent: releaseWarningBannerTranslations.en,
    requestAssessmentButtonComponent: requestAssessmentButtonTranslations.en,
    researchDataSharingPage: researchDataSharingTranslations.en,
    readOnlyModePage: readOnlyModeTranslations.en,
    rolePage: roleTranslations.en,
    staffDownloadReportButtonComponent:
      staffDownloadReportButtonTranslations.en,
    staffNavigationTabs: staffNavigationTabsTranslations.en,
    summaryGraphComponent: summaryGraphTranslations.en,
    supportPersonModalComponent: supportPersonModalTranslations.en,
    sessionTimeoutWarningComponent: sessionTimeoutWarningTranslations.en,
    supportTaskButtonComponent: supportTaskButtonTranslations.en,
    trackableChartToggleComponent: trackableChartToggleTranslations.en,
    userQuestionnaireComponent: userQuestionnaireTranslations.en,
    unauthorisedPage: unauthorisedTranslations.en,
    staff: {
      staffDashboardSelectionComponent: staffDashboardSelectionTranslations.en,
    },
    hooks: {
      usePersonalDetailsHook: usePersonalDetailsTranslations.en,
    },
  },
  fr: {
    activityLogComponent: activityLogTranslations.fr,
    activityPanelComponent: activityPanelTranslations.fr,
    answerSetComponent: answerSetTranslations.fr,
    answerTableComponent: answerTableTranslations.fr,
    chartHeaderComponent: chartHeaderTranslations.fr,
    clinicianDropdownComponent: clinicianDropdownTranslations.fr,
    clinicianNoteComponent: clinicianNoteTranslations.fr,
    connectedTenantsPage: connectedTenantsTranslations.fr,
    dataGridRowFooterContentComponent: dataGridRowFooterContentTranslations.fr,
    enterPasswordPage: editPasswordFormTranslations.fr,
    healthHistoryPage: healthHistoryPageTranslations.fr,
    errorPage: errorPageTranslations.fr,
    helpNowButtonComponent: helpNowButtonTranslations.fr,
    fitbitPages: fitbitTranslations.fr,
    loadMoreComponent: loadMoreComponentTranslations.fr,
    releaseWarningBannerComponent: releaseWarningBannerTranslations.fr,
    requestAssessmentButtonComponent: requestAssessmentButtonTranslations.fr,
    researchDataSharingPage: researchDataSharingTranslations.fr,
    readOnlyModePage: readOnlyModeTranslations.fr,
    rolePage: roleTranslations.fr,
    staffDownloadReportButtonComponent:
      staffDownloadReportButtonTranslations.fr,
    staffNavigationTabs: staffNavigationTabsTranslations.fr,
    summaryGraphComponent: summaryGraphTranslations.fr,
    sessionTimeoutWarningComponent: sessionTimeoutWarningTranslations.fr,
    supportPersonModalComponent: supportPersonModalTranslations.fr,
    supportTaskButtonComponent: supportTaskButtonTranslations.fr,
    trackableChartToggleComponent: trackableChartToggleTranslations.fr,
    userQuestionnaireComponent: userQuestionnaireTranslations.fr,
    unauthorisedPage: unauthorisedTranslations.fr,
    staff: {
      staffDashboardSelectionComponent: staffDashboardSelectionTranslations.fr,
    },
    hooks: {
      usePersonalDetailsHook: usePersonalDetailsTranslations.fr,
    },
  },
}

export default sharedTranslations
