// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'

import Button from 'react-ui/components/Button'
import { H2 } from 'react-ui/components/Heading'
import Modal from 'react-ui/components/Modal'
import Spacer from 'react-ui/components/Spacer'
import { RadioSet } from 'shared/ui/Forms/Fields'
import { useEnums } from 'platform_web/containers/ViewerEnums'

import type { FelaPropsType } from 'react-ui/typing'
import type { UserQuestionnaireQuestionSetFooter_userQuestionnaire } from './__generated__/UserQuestionnaireQuestionSetFooter_userQuestionnaire.graphql'
import type { UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet } from './__generated__/UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet.graphql'

type PropsType = FelaPropsType & {
  busy: boolean,
  handleNext: () => void,
  handleSkipSelect: (v: string) => void,
  skipOnHeader?: boolean,
  skipReason: string,
  submitting: boolean,
  userQuestionnaire: UserQuestionnaireQuestionSetFooter_userQuestionnaire,
  userQuestionnaireQuestionSet: UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet,
}

type QuestionnaireButtonTextPropsType = {
  submitting: boolean,
}

const styleRules = ({ theme }) => ({
  Modal: {
    width: '400px',
  },
  QuestionSetSkipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: theme.Grid.gutter,
  },
  RadioSetWrapper: {
    width: '100%',
  },
  nextButton: {
    marginTop: theme.Grid.gutter,
    width: '100%',
  },
  skipHeader: {
    fontWeight: 'normal',
    fontSize: theme.Font.fontSize,
    color: theme.palette.component.primary.text,
  },
})
const selectOptions = options =>
  options.map(({ key, value }) => ({
    title: value,
    value: key,
  }))

export const qqsButtonText = ({
  submitting,
}: QuestionnaireButtonTextPropsType) => {
  if (submitting) return 'Submitting...'
  return 'Next'
}

export const isNextButtonDisabled = ({
  busy,
  submitting,
  skipReason,
}: {
  busy: boolean,
  skipReason: ?string,
  submitting: boolean,
}): boolean => busy || submitting || skipReason === undefined

const UserQuestionnaireQuestionSetSkipModalBase = (props: PropsType) => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    busy,
    handleSkipSelect,
    handleNext,
    rules,
    styles,
    skipReason,
    submitting,
    skipOnHeader,
  } = props

  const onToggleOpen = () => setIsOpen(prev => !prev)

  const { answer_set: { skip_reason_list } } = useEnums()

  const closeModal = () => {
    onToggleOpen()
    handleSkipSelect('')
  }
  return (
    <>
      <Button
        componentId="Button_qs_skip"
        naked
        extend={skipOnHeader && rules.skipHeader}
        onClick={onToggleOpen}
      >
        Skip
      </Button>
      {isOpen && (
        <Modal
          extend={() => ({ Modal: rules.Modal })}
          isOpen={isOpen}
          id="skip_reason_qs_modal"
          onRequestClose={closeModal}
          showClose={false}
          shrinkwrap
        >
          <div className={styles.QuestionSetSkipWrapper}>
            <H2 inherit>Reason to skip</H2>

            <div className={styles.RadioSetWrapper}>
              <RadioSet
                name="skip_reason"
                onChange={e => handleSkipSelect(e.target.value)}
                options={selectOptions(skip_reason_list)}
                uiStyle="primary"
                value={skipReason}
              />
            </div>

            <Spacer axis="vertical" units={1} />

            <Button
              componentId="nextSubmitQuestionSet"
              extend={rules.nextButton}
              type="submit"
              shape="pill"
              disabled={isNextButtonDisabled({ busy, submitting, skipReason })}
              onClick={handleNext}
            >
              {qqsButtonText({ submitting })}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default connect(styleRules)(UserQuestionnaireQuestionSetSkipModalBase)
