/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ClinicianOnboarding_user$ref = any;
type ConnectedQuestionnaireIndividualPage_user$ref = any;
type IndividualsAdminInvite_current_role$ref = any;
type MultiFactorAuthentication_user$ref = any;
type NavigationProvider_user$ref = any;
type NotificationDisplay_tenant$ref = any;
type NotificationDisplay_user$ref = any;
type SystemNotificationPopUp_notifications$ref = any;
type TenantContext_parent$ref = any;
type UserCardLoader_user$ref = any;
type UserContext_user$ref = any;
type UserNotifications_user$ref = any;
type ViewerEnumProvider_viewer$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RootPlatformPage_viewer$ref: FragmentReference;
declare export opaque type RootPlatformPage_viewer$fragmentType: RootPlatformPage_viewer$ref;
export type RootPlatformPage_viewer = {|
  +remember_me: boolean,
  +currentUser: ?{|
    +id: string,
    +show_clinician_onboarding: boolean,
    +show_individual_onboarding: boolean,
    +mfa_code_required: boolean,
    +user_research_programs_responses: $ReadOnlyArray<{|
      +id: string
    |}>,
    +unanswered_tenant_research_programs: $ReadOnlyArray<{|
      +id: string,
      +research_program: {|
        +id: string,
        +title: string,
        +description: string,
      |},
    |}>,
    +current_role: {|
      +id?: string,
      +see_help_now_button?: boolean,
      +tenant?: ?{|
        +id: string,
        +label: string,
        +created_at: string,
        +bannerText: ?string,
        +multi_roles_enabled: boolean,
        +$fragmentRefs: NotificationDisplay_tenant$ref,
      |},
      +role_type?: RoleTypes,
      +parent?: ?{|
        +$fragmentRefs: TenantContext_parent$ref
      |},
      +individual_detail?: ?{|
        +id: string,
        +read_only: boolean,
      |},
      +$fragmentRefs: IndividualsAdminInvite_current_role$ref,
    |},
    +completed_onboarding: boolean,
    +roles: ?{|
      +nodes: ?$ReadOnlyArray<?{|
        +id?: string,
        +role_type?: RoleTypes,
      |}>
    |},
    +policies: $ReadOnlyArray<{|
      +name: string,
      +enabled: boolean,
    |}>,
    +owner_tenants: ?$ReadOnlyArray<{|
      +id: string,
      +name: string,
      +created_at: string,
    |}>,
    +unacknowledged_pop_ups: $ReadOnlyArray<{|
      +$fragmentRefs: SystemNotificationPopUp_notifications$ref
    |}>,
    +$fragmentRefs: ClinicianOnboarding_user$ref & NotificationDisplay_user$ref & ConnectedQuestionnaireIndividualPage_user$ref & NavigationProvider_user$ref & UserCardLoader_user$ref & UserNotifications_user$ref & MultiFactorAuthentication_user$ref & UserContext_user$ref,
  |},
  +$fragmentRefs: ViewerEnumProvider_viewer$ref,
  +$refType: RootPlatformPage_viewer$ref,
|};
export type RootPlatformPage_viewer$data = RootPlatformPage_viewer;
export type RootPlatformPage_viewer$key = {
  +$data?: RootPlatformPage_viewer$data,
  +$fragmentRefs: RootPlatformPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RootPlatformPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remember_me",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show_clinician_onboarding",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show_individual_onboarding",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mfa_code_required",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserResearchProgramsResponse",
          "kind": "LinkedField",
          "name": "user_research_programs_responses",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantResearchProgram",
          "kind": "LinkedField",
          "name": "unanswered_tenant_research_programs",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ResearchProgram",
              "kind": "LinkedField",
              "name": "research_program",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "current_role",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IndividualDetail",
                  "kind": "LinkedField",
                  "name": "individual_detail",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "read_only",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "IndividualRole",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "see_help_now_button",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tenant",
                  "kind": "LinkedField",
                  "name": "tenant",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bannerText",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "multi_roles_enabled",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "NotificationDisplay_tenant"
                    }
                  ],
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "parent",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "TenantContext_parent"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "IndividualsAdminInvite_current_role"
                }
              ],
              "type": "RoleInterface",
              "abstractKey": "__isRoleInterface"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed_onboarding",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ],
                  "type": "RoleInterface",
                  "abstractKey": "__isRoleInterface"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Policy",
          "kind": "LinkedField",
          "name": "policies",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "owner_tenants",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PopUpNotification",
          "kind": "LinkedField",
          "name": "unacknowledged_pop_ups",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemNotificationPopUp_notifications"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClinicianOnboarding_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NotificationDisplay_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConnectedQuestionnaireIndividualPage_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NavigationProvider_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserCardLoader_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserNotifications_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MultiFactorAuthentication_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserContext_user"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ViewerEnumProvider_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c628c6be29d8b46102013a992058699b';

module.exports = node;
