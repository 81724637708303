// @flow

import React, { useState } from 'react'

import { CheckBox } from 'react-ui/components/Form'
import { PopOverDoneFooter } from 'react-ui/components/PopOver'
import createComponentId from 'shared/services/id'

import ServiceMetricsMultiSelect from '.'

type PropsType = {
  componentId?: string,
  onChange: ($ReadOnlyArray<string>) => void,
  +selectedTenants: $ReadOnlyArray<string>,
  +tenants: $ReadOnlyArray<{
    +id: string,
    +name: string,
  }>,
}

const defaultId = createComponentId(__filename)

const ServiceMetricsTenantsSelectorBase = ({
  componentId = defaultId,
  onChange,
  tenants,
  selectedTenants,
  ...props
}: PropsType) => {
  const [checkedTenants, setCheckedTenants] = useState([...selectedTenants])

  const updateCheckedTenants = tenantIds => {
    setCheckedTenants(tenantIds)
  }

  return (
    <ServiceMetricsMultiSelect
      componentId={componentId}
      data={tenants}
      searchFields={['name']}
      selectedIds={checkedTenants}
      singularLabel="service"
      onChange={updateCheckedTenants}
      renderFooter={footerProps => (
        <PopOverDoneFooter
          {...footerProps}
          label="Update"
          onToggleOpen={() => onChange(checkedTenants)}
        />
      )}
      renderOption={(tenant, optionProps) => (
        <CheckBox
          {...optionProps}
          block
          id={`service-metrics-tenant-selector-${tenant.id}`}
          label={tenant.name}
        />
      )}
      {...(props: any)}
    />
  )
}

export default ServiceMetricsTenantsSelectorBase
