// @flow

import assessmentTranslations from './assessmentPages'
import authTranslations from './authPages'
import careUITranslations from './careUIPages'
import feedbackTranslations from './feedbackPages'
import healthTranslations from './healthPages'
import hooksTranslations from './hooksPages'
import insightsTranslations from './insightsPages'
import onboardingTranslations from './onboardingPages'
import rootPlatformTranslations from './rootPlatformPages'
import scoreCardTranslations from './scoreCardPages'
import settingsTranslations from './settingsPages'
import sharedTranslations from './sharedPages'
import staffTranslations from './staffPages'
import supportingTranslations from './supportingPages'

const resources = {
  en: {
    assessment: assessmentTranslations.en,
    auth: authTranslations.en,
    careUI: careUITranslations.en,
    feedback: feedbackTranslations.en,
    health: healthTranslations.en,
    hooks: hooksTranslations.en,
    insights: insightsTranslations.en,
    onboarding: onboardingTranslations.en,
    rootPage: rootPlatformTranslations.en,
    scoreCard: scoreCardTranslations.en,
    shared: sharedTranslations.en,
    staff: staffTranslations.en,
    supporting: supportingTranslations.en,
    settings: settingsTranslations.en,
  },
  fr: {
    assessment: assessmentTranslations.fr,
    auth: authTranslations.fr,
    careUI: careUITranslations.fr,
    feedback: feedbackTranslations.fr,
    health: healthTranslations.fr,
    hooks: hooksTranslations.fr,
    insights: insightsTranslations.fr,
    onboarding: onboardingTranslations.fr,
    rootPage: rootPlatformTranslations.fr,
    scoreCard: scoreCardTranslations.fr,
    shared: sharedTranslations.fr,
    staff: staffTranslations.fr,
    supporting: supportingTranslations.fr,
    settings: settingsTranslations.fr,
  },
}

export default resources
