// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'

import { FormError } from 'react-ui/components/Form'
import Spacer from 'react-ui/components/Spacer'
import createComponentId from 'shared/services/id'
import { Input } from 'shared/ui/Basics/Input/Input'

import { type PropsType } from '.'

const defaultId = createComponentId(__filename)

const styleRules = () => ({
  Field: {
    display: 'inline',
    marginRight: '2em',
    width: '300px',
    marginBottom: '1em',
  },
  Fields: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
})

function PairInteger({
  autofocus = false,
  componentId = defaultId,
  disabled = false,
  formContext: _formContext,
  id,
  onBlur,
  onChange,
  onFocus,
  options: _options,
  rawErrors,
  readonly = false,
  styles,
  type: _type,
  value,
  ...inputProps
}: PropsType) {
  const key = id ? `${id}-` : ''
  const [firstValue, setFirstValue] = useState(
    value ? Math.floor(value / 12) : '',
  )
  const [touched, setTouched] = useState(false)
  const [secondValue, setSecondValue] = useState(value ? value % 12 : '')
  const [firstValueError, setFirstValueError] = useState('')
  const [secondValueError, setSecondValueError] = useState('')
  const {
    feet_max_value,
    inches_max_value,
    feet_min_value,
    inches_min_value,
  } = inputProps.schema
  const { required = false } = inputProps

  const onTouched = () => setTouched(true)

  const parseNumber = v => (`${parseInt(v, 10)}` === v ? parseInt(v, 10) : '')

  const setFinalValue = (first, second, hasError) => {
    onTouched()
    const finalValueInInches =
      (required && first === '') || (first === '' && second === '') || hasError
        ? ''
        : Number(first || 0) * 12 + Number(second || 0)
    return onChange(finalValueInInches)
  }

  const onFirstValueChange = ({ target: { value: localValue } }) => {
    let error = ''
    let first = parseNumber(localValue)
    if (first && (first < feet_min_value || first > feet_max_value)) {
      first = ''
      error = `The feet should be between ${feet_min_value} - ${feet_max_value}`
    }
    setFirstValue(first)
    setFirstValueError(error)
    setFinalValue(first, secondValue, error)
  }

  const onSecondValueChange = ({ target: { value: localValue } }) => {
    let error = ''
    let second = parseNumber(localValue)
    if (second && (second < inches_min_value || second > inches_max_value)) {
      second = ''
      error = `The inches should be between ${inches_min_value} - ${inches_max_value}`
    }
    setSecondValue(second)
    setSecondValueError(error)
    setFinalValue(firstValue, second, error)
  }

  return (
    <div className={componentId}>
      <div className={styles.Fields}>
        <div className={styles.Field}>
          <Input
            key={`${key}first`}
            autoFocus={autofocus}
            className="form-control"
            disabled={disabled}
            readOnly={readonly}
            required={required}
            {...(inputProps: any)}
            value={firstValue}
            onBlur={onBlur && (_ => onBlur(id, firstValue))}
            onChange={onFirstValueChange}
            onFocus={onFocus && (_ => onFocus(id, firstValue))}
            type="number"
            min={feet_min_value}
            max={feet_max_value}
            fullWidth
          />
          <Spacer units={1} />
          {firstValueError && <FormError>{firstValueError}</FormError>}
        </div>
        <div className={styles.Field}>
          <Input
            key={`${key}second`}
            autoFocus={autofocus}
            className="form-control"
            disabled={disabled}
            readOnly={readonly}
            required={required}
            {...(inputProps: any)}
            value={secondValue}
            onBlur={onBlur && (_ => onBlur(id, secondValue))}
            onChange={onSecondValueChange}
            onFocus={onFocus && (_ => onFocus(id, secondValue))}
            type="number"
            min={inches_min_value}
            max={inches_max_value}
            fullWidth
          />
          <Spacer units={1} />
          {secondValueError && <FormError>{secondValueError}</FormError>}
        </div>
        {touched && rawErrors && <FormError>{rawErrors}</FormError>}
      </div>
      <Spacer units={1} />
    </div>
  )
}

export default connect(styleRules)(PairInteger)
