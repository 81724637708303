// @flow

// Insights Pages
import insightsNewTranslations from 'platform_web/pages/Insights/localizedStrings.json'
import baselineScoresTranslations from 'platform_web/pages/Insights/tabs/Outcomes/components/BaselineScores/localizedStrings.json'
import baselineScoresEmptyStateTranslations from 'platform_web/pages/Insights/tabs/Outcomes/components/BaselineScoresEmptyState/localizedStrings.json'
import recoveryTrajectoriesTranslations from 'platform_web/pages/Insights/tabs/Outcomes/components/RecoveryTrajectories/localizedStrings.json'
import recoveryTrajectoriesEmptyStateTranslations from 'platform_web/pages/Insights/tabs/Outcomes/components/RecoveryTrajectoriesEmptyState/localizedStrings.json'
import clinicianLoginsInsightsGraphTranslations from 'platform_web/pages/Insights/tabs/Overview/components/ClinicianLoginsInsightsGraph/localizedStrings.json'
import individualLoginsInsightsGraphTranslations from 'platform_web/pages/Insights/tabs/Overview/components/IndividualLoginsInsightsGraph/localizedStrings.json'
import individualOnboardingTranslations from 'platform_web/pages/Insights/tabs/Overview/components/IndividualOnboardingInsightsGraph/localizedStrings.json'
import stbEscalationsTranslations from 'platform_web/pages/Insights/tabs/Overview/components/StbEscalationsGraph/localizedStrings.json'
import summaryQuestionnairesInsightsGraphTranslations from 'platform_web/pages/Insights/tabs/Overview/components/SummaryQuestionnairesInsightsGraph/localizedStrings.json'

const insightsTranslations = {
  en: {
    baselineScoresEmptyStateComponent: baselineScoresEmptyStateTranslations.en,
    baselineScoresComponent: baselineScoresTranslations.en,
    recoveryTrajectoriesEmptyStateComponent:
      recoveryTrajectoriesEmptyStateTranslations.en,
    recoveryTrajectoriesComponent: recoveryTrajectoriesTranslations.en,
    clinicianLoginsIsightsGraphComponent:
      clinicianLoginsInsightsGraphTranslations.en,
    individualLoginsIsightsGraphComponent:
      individualLoginsInsightsGraphTranslations.en,
    individualOnboardingIsightsGraphComponent:
      individualOnboardingTranslations.en,
    insightsPage: insightsNewTranslations.en,
    stbEscalationsIsightsGraphComponent: stbEscalationsTranslations.en,
    summaryQuestionnairesInsightsGraphComponent:
      summaryQuestionnairesInsightsGraphTranslations.en,
  },
  fr: {
    baselineScoresEmptyStateComponent: baselineScoresEmptyStateTranslations.fr,
    baselineScoresComponent: baselineScoresTranslations.fr,
    recoveryTrajectoriesEmptyStateComponent:
      recoveryTrajectoriesEmptyStateTranslations.fr,
    recoveryTrajectoriesComponent: recoveryTrajectoriesTranslations.fr,
    clinicianLoginsIsightsGraphComponent:
      clinicianLoginsInsightsGraphTranslations.fr,
    individualLoginsIsightsGraphComponent:
      individualLoginsInsightsGraphTranslations.fr,
    individualOnboardingIsightsGraphComponent:
      individualOnboardingTranslations.fr,
    insightsPage: insightsNewTranslations.fr,
    stbEscalationsIsightsGraphComponent: stbEscalationsTranslations.fr,
    summaryQuestionnairesInsightsGraphComponent:
      summaryQuestionnairesInsightsGraphTranslations.fr,
  },
}

export default insightsTranslations
