// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { ResourceLoader } from 'components/Resource/Resource'
import { UserResourceStatusUpdateLoader } from 'components/UserResource/UserResourceStatusUpdate'
import { CareOptionRelationship } from 'react-ui/components/CareOption'
import ParticipantGroup from 'react-ui/components/ParticipantGroup'
import createComponentId from 'shared/services/id'

import { personDetailsFromUser } from './UserResource/services'

import type { UserResource_user_resource } from './__generated__/UserResource_user_resource.graphql'
import type { UserResource_viewer } from './__generated__/UserResource_viewer.graphql'

type PropsType = {
  componentId?: string,
  user_resource: UserResource_user_resource,
  viewer: UserResource_viewer,
}

const defaultId = createComponentId(__filename)

const UserResourceBase = (props: PropsType) => {
  const {
    componentId = defaultId,
    user_resource,
    user_resource: { resource },
    viewer,
  } = props

  const isClinical = user_resource.resource.clinical
  const { user, supporting_clinician } = user_resource
  const { currentUser } = viewer

  const individual =
    user &&
    ['support_requested', 'in_progress', 'supported'].includes(
      user_resource.status,
    ) &&
    personDetailsFromUser(user)

  const clinician =
    supporting_clinician && personDetailsFromUser(supporting_clinician.user)

  const participants = [individual, clinician]
  const needsEmpty = individual && !clinician && isClinical

  const participantGroup = (
    <ParticipantGroup participants={participants} withEmptySlot={needsEmpty} />
  )

  const status = (
    <CareOptionRelationship
      viewerId={currentUser ? currentUser.id : 'none'}
      individual={individual || null}
      clinician={clinician || null}
      clinicianNeeded={isClinical}
      participants={participantGroup}
    />
  )

  const actions = (
    <UserResourceStatusUpdateLoader user_resource={user_resource} />
  )

  return (
    <div data-component-id={`${componentId}-${user_resource.id}`}>
      <ResourceLoader
        actions={actions}
        status={status}
        hideExternalLink={isClinical}
        resource={resource}
      />
    </div>
  )
}

export const UserResourceLoader = createFragmentContainer(UserResourceBase, {
  user_resource: graphql`
    fragment UserResource_user_resource on UserResource {
      ...UserResourceStatusUpdate_user_resource
      id
      resource {
        ...Resource_resource
        clinical
        id
      }
      status
      user {
        ...AvatarLoader_user
        id
        name
      }
      supporting_clinician {
        user {
          ...AvatarLoader_user
          id
          name
        }
      }
    }
  `,
  viewer: graphql`
    fragment UserResource_viewer on Viewer {
      currentUser {
        id
      }
    }
  `,
})
