// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { Container } from 'react-ui/components/Grid'
import Section from 'react-ui/components/Section'
import { Heading } from 'care-ui'

import Sticky from '../Sticky'

type PropsType = {
  current: number,
  label?: string,
  total: number,
}

const stickyHeaderContainer = ({ theme }) => ({
  paddingLeft: '10px',
  [theme.breakpoints.queries.md]: {
    paddingLeft: '42px',
  },
  paddingBottom: '20px',
})

const stickyHeader = () => ({
  fontSize: '20px',
  fontWeight: 'bold',
  paddingTop: '20px',
})

const stickySection = ({ theme }) => ({
  Section: {
    width: '100vw',
    borderBottomColor: theme.palette.dividerColor,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
})

const mainStickySection = () => ({
  Section: {
    width: '100vw',
  },
})

const OnboardingQuestionnaireHeader = (props: PropsType) => {
  const { current, total } = props

  const { css } = useFela()

  const stickyComponent = () => (
    <Section color="white" noPadding extend={stickySection}>
      <Container>
        <div className={css(stickyHeaderContainer)}>
          <div className={css(stickyHeader)}>
            <Heading level={1}>{`Question ${current} out of ${total}`}</Heading>
          </div>
        </div>
      </Container>
    </Section>
  )

  return (
    <Section color="white" noPadding extend={mainStickySection}>
      <Container>
        <div className={css(stickyHeaderContainer)}>
          <Sticky top={60} stickyComponent={stickyComponent()}>
            <div className={css(stickyHeader)}>
              <Heading level={1}>
                {`Question ${current} out of ${total}`}
              </Heading>
            </div>
          </Sticky>
        </div>
      </Container>
    </Section>
  )
}

export default OnboardingQuestionnaireHeader
