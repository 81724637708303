// @flow

import { useSetState } from 'react-use'

import { colors } from 'care-ui/atoms/colors/colors'

export const BASELINE_SCORES_LEGENDS = [
  { name: 'Skipped', color: colors.surface.neutralDark },
  { name: 'High', color: colors.surface.danger },
  { name: 'Moderate', color: colors.surface.warning },
  { name: 'Mild', color: colors.surface.mild },
  { name: 'Good', color: colors.surface.success },
]

export const RECOVERY_TRAJECTORIES_LEGENDS = [
  { name: 'Improved', color: colors.surface.success },
  { name: 'No Change', color: colors.surface.neutralDark },
  { name: 'Deteriorate', color: colors.surface.danger },
]

export type OutcomeReportingQueryVariableType = {
  quarter_end_date?: string,
  quarter_start_date?: string,
  tenant_id?: string,
}

type UseOutcomeReportingParams = {
  defaultOutcomeReportingQueryVariables: OutcomeReportingQueryVariableType,
}

export type UseOutcomeReportingReturnType = {
  outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
  setOutcomeReportQueryVariables: (
    outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
  ) => void,
}

const useOutcomeReporting = (
  params: UseOutcomeReportingParams,
): UseOutcomeReportingReturnType => {
  const { defaultOutcomeReportingQueryVariables } = params

  const [
    outcomeReportQueryVariables,
    setOutcomeReportQueryVariables,
  ] = useSetState(defaultOutcomeReportingQueryVariables)

  return {
    outcomeReportQueryVariables,
    setOutcomeReportQueryVariables,
  }
}

export default useOutcomeReporting
