// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import FitbitLogo from 'react-ui/assets/images/FitbitLogo.svg'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import { Button } from 'care-ui'

import { styleRules } from './FitbitDisconnectActivity.style'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  authLink: string,
  closeModal?: () => void,
  disconnect: () => void,
}

const FitbitDisconnectActivity = (props: PropTypes) => {
  const { closeModal, disconnect, styles, rules } = props

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'fitbitPages.fitbitDisconnectActivity',
  })

  return (
    <Container extend={rules.container}>
      <FitbitLogo className={styles.logo} />

      <Heading level={2} extend={() => ({ textAlign: 'center' })}>
        {translation('modalHeading')}
      </Heading>

      <p className={styles.note}>{translation('description')}</p>

      <div className={styles.buttonGroup}>
        <Button
          variant="secondary"
          onClick={closeModal}
          dataTestId="fitbit-disconnect-no"
        >
          {translation('cancelButton')}
        </Button>

        <Button
          variant="primary"
          onClick={disconnect}
          dataTestId="fitbit-disconnect-yes"
        >
          {translation('disconnectButtonText')}
        </Button>
      </div>
    </Container>
  )
}

export default connect(styleRules)(FitbitDisconnectActivity)
