// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import type { KeyedAvatarType } from 'react-ui/components/Avatar'

import type { FelaPropsType } from '../../typing'

export type PersonProps = {
  avatar?: KeyedAvatarType,
  id: string,
  name: string,
}

type PropsType = {
  clinician?: ?PersonProps,
  clinicianNeeded?: boolean,
  individual?: ?PersonProps,
  participants: React.Node,
  viewerId: string,
}

type UserNamePropsType = {
  sentenceStart?: boolean,
  user?: PersonProps,
  viewerId: string,
}

const styleRules = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarsContainer: {
    marginRight: 15,
  },
  textContainer: {
    lineHeight: '1.2em',
  },
})

const isViewer = (user, viewerId) => user && user.id === viewerId

const UserNameTag = ({ name }: { name: string }) => <strong>{name}</strong>

const UserName = ({ viewerId, user, sentenceStart }: UserNamePropsType) => {
  if (!user) return 'Unnamed User'
  if (isViewer(user, viewerId)) {
    return sentenceStart ? 'You' : 'you'
  }
  return <UserNameTag name={user.name} />
}

const firstPersonPossessivePronoun = (viewerId: string, user: PersonProps) =>
  isViewer(user, viewerId) ? 'have' : 'has'

const secondPersonPossessivePronoun = (viewerId: string, user: PersonProps) =>
  isViewer(user, viewerId) ? 'your' : 'their'

const beingVerb = (viewerId: string, user: PersonProps) =>
  isViewer(user, viewerId) ? 'are' : 'is'

const CareOptionText = ({
  viewerId,
  individual,
  clinician,
  clinicianNeeded,
}: PropsType) => {
  if (individual && clinician) {
    return (
      <span>
        <UserName viewerId={viewerId} user={clinician} sentenceStart />{' '}
        {beingVerb(viewerId, clinician)} supporting{' '}
        <UserName viewerId={viewerId} user={individual} />
      </span>
    )
  } else if (individual) {
    if (clinicianNeeded) {
      if (isViewer(individual, viewerId)) {
        return (
          <span>
            Waiting for {secondPersonPossessivePronoun(viewerId, individual)}{' '}
            clinician
          </span>
        )
      }
      return (
        <span>
          <UserName viewerId={viewerId} user={individual} sentenceStart />{' '}
          {firstPersonPossessivePronoun(viewerId, individual)} requested support
        </span>
      )
    }
    return (
      <span>
        <UserName viewerId={viewerId} user={individual} sentenceStart />{' '}
        {firstPersonPossessivePronoun(viewerId, individual)} started
      </span>
    )
  }
  return null
}

const CareOptionRelationship = ({
  participants,
  styles,
  ...props
}: PropsType & FelaPropsType) => (
  <div className={styles.container}>
    {participants}
    <div className={styles.textContainer}>
      <CareOptionText participants={participants} {...(props: any)} />
    </div>
  </div>
)

const Connected: React.StatelessFunctionalComponent<PropsType> = connect(
  styleRules,
)(CareOptionRelationship)

export default Connected
