// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { useRouter } from 'found'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import uniq from 'lodash/fp/uniq'

import { UserQuestionSetTableLoader } from 'components/UserQuestionSet/UserQuestionSetTable'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'
import Select from 'react-ui/components/Select'
import titleize from 'platform_web/services/titleize'
import { Button } from 'care-ui'

import type { FelaRulesType } from 'react-ui/typing'
import type { UserQuestionnaireDetailsPage_user_questionnaire } from './__generated__/UserQuestionnaireDetailsPage_user_questionnaire.graphql'

type IncomingPropsType = {
  +user_questionnaire: UserQuestionnaireDetailsPage_user_questionnaire,
}

type PropsType = IncomingPropsType & {
  availableRoleTypes: $ReadOnlyArray<{ key: string, value: string }>,
  displayRoleType: string,
  onChangeRoleType: (
    props: IncomingPropsType,
  ) => (event: SyntheticInputEvent<HTMLInputElement>) => void,
  questionSetsToDisplay: *,
  +relay: Object,
  router?: Object,
  rules: FelaRulesType,
  showSelect: boolean,
}

const styleRules = () => ({
  Select: {
    maxWidth: '15rem',
  },
  Container: {
    marginTop: '1rem',
  },
})

const nameChange = roleTypes =>
  map(roleType => {
    if (roleType === 'INDIVIDUAL') {
      return { value: 'Myself', key: roleType }
    }
    return { value: titleize(roleType), key: roleType }
  })(roleTypes)

const roleTypes = userQuestionSets =>
  flow(map('for_role_type'), uniq, nameChange)(userQuestionSets)

const filteredUserQuestionSets = (userQuestionSets, displayRoleType) =>
  filter({ for_role_type: displayRoleType })(userQuestionSets)

const UserQuestionnaireDetailsPage = ({
  rules,
  user_questionnaire: {
    ensure_questionnaire: { label: title },
    user_question_sets,
  },
}: PropsType) => {
  const [displayRoleType, setDisplayRoleType] = useState('INDIVIDUAL')
  const initialQuestionSetsToDisplay = filteredUserQuestionSets(
    user_question_sets,
    displayRoleType,
  )
  const [questionSetsToDisplay, setQuestionSetsToDisplay] = useState(
    initialQuestionSetsToDisplay,
  )

  const availableRoleTypes = roleTypes(user_question_sets)
  const showSelect = availableRoleTypes.length > 1

  const onChangeRoleType = ({ target: { value } }) => {
    const newQuestionSetsToDisplay = filteredUserQuestionSets(
      user_question_sets,
      value,
    )
    setDisplayRoleType(value)
    setQuestionSetsToDisplay(newQuestionSetsToDisplay)
  }
  const { router } = useRouter()
  return (
    <>
      <Section color="white">
        <Container extend={rules.Container}>
          <Button variant="primary" onClick={() => router && router.go(-1)}>
            Back to details
          </Button>
          <Heading level={3}>Questionnaire: {title}</Heading>
        </Container>
      </Section>
      <Section color="default">
        <Container>
          {showSelect && (
            <>
              <span>View answers from: </span>
              <Select
                data-component-id="viewRolesAnswerSelect"
                extend={rules}
                items={availableRoleTypes}
                value={displayRoleType}
                onChange={onChangeRoleType}
                keyName="key"
                valueName="value"
              />
            </>
          )}
          <UserQuestionSetTableLoader
            user_question_sets={questionSetsToDisplay}
          />
        </Container>
      </Section>
    </>
  )
}

export const UserQuestionnaireDetailsPageLoader = createFragmentContainer(
  connect(styleRules)(UserQuestionnaireDetailsPage),
  {
    user_questionnaire: graphql`
      fragment UserQuestionnaireDetailsPage_user_questionnaire on UserQuestionnaire {
        id
        questionnaire {
          label
        }
        ensure_questionnaire {
          label
        }
        user_question_sets {
          for_role_type
          ...UserQuestionSetTable_user_question_sets
        }
      }
    `,
  },
)

export const UserQuestionnaireDetailsPageQuery = graphql`
  query UserQuestionnaireDetailsPage_Query($id: ID!, $questionnaire_id: ID!) {
    user(id: $id, source: "root") {
      user_questionnaire(questionnaire_id: $questionnaire_id) {
        ...UserQuestionnaireDetailsPage_user_questionnaire
      }
    }
  }
`
