// @flow

import * as React from 'react'
import cx from 'classnames'
import { identity, map } from 'lodash/fp'

import s from './BasicTable.scss'

type PropsType = {
  children: (model: Object, idx: number) => React.Node,
  className?: string,
  data: ?$ReadOnlyArray<?Object>,
  renderHeaders: Object => React.Node,
  sort?: (?$ReadOnlyArray<?Object>) => Function,
}

const BasicTable = (props: PropsType) => {
  const { className, children, data, renderHeaders, sort } = props

  if (!data) {
    throw new Error('No data given to table component')
  }

  const processedData = (sort || identity)(data)
  const renderedBody = map.convert({ cap: false })(children)(processedData)
  if (renderedBody.length === 0) {
    return <p className={cx(className)}>No results found</p>
  }

  return (
    <div className={s.tableContainer}>
      <table className={cx(s.table, className)}>
        <thead>{renderHeaders(processedData)}</thead>
        <tbody>{renderedBody}</tbody>
      </table>
    </div>
  )
}

export default BasicTable
