// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import Section from 'react-ui/components/Section'
import { Button, FlexContainer, Modal, Text } from 'care-ui'
import useModal from 'care-ui/molecules/hooks/useModal'

import { staffDownloadReportButtonIndividual } from './query/StaffDownloadReportButton'
import { buttonContainerRules } from './StaffDownloadReportButton.style'

import type { StaffDownloadReportButton_individual } from './query/__generated__/StaffDownloadReportButton_individual.graphql'

type PropsType = {
  individual: StaffDownloadReportButton_individual,
}

const StaffDownloadReportButton = (props: PropsType) => {
  const { individual } = props

  const { user } = individual
  const { isOpen, closeModal, openModal } = useModal()

  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?user_id=${user.id}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
    closeModal()
  }

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'staffDownloadReportButtonComponent',
  })

  return (
    <>
      <Section extend={buttonContainerRules} noPadding color="white">
        <Button
          onClick={openModal}
          variant="primary"
          ariaLabel={translation('reportButton')}
          dataTestId="report"
        >
          {translation('reportButton')}
        </Button>
      </Section>

      <Modal
        closeModal={closeModal}
        heading={translation('modalHeading')}
        isOpen={isOpen}
      >
        <FlexContainer
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="sm"
        >
          <Text as="p">
            {translation('modalDescription')}
          </Text>

          <Button
            dataTestId="emrDownloadButton"
            onClick={handleClick}
            variant="primary"
            ariaLabel={translation('continueButton')}
          >
            {translation('continueButton')}
          </Button>
        </FlexContainer>
      </Modal>
    </>
  )
}

export default createFragmentContainer(StaffDownloadReportButton, staffDownloadReportButtonIndividual)
