// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import { useRouter } from 'found'
import { flow, get, getOr, map, min, sortBy } from 'lodash/fp'

import { UserTrackableViewLoader } from 'components/UserTrackableView'
import ArrowRight from 'react-ui/assets/icons/arrow-right.svg'
import {
  FancyHealthCard,
  FancyHealthCardNoData,
  FancyHealthCardPending,
  FancyHealthCardSkipped,
} from 'react-ui/components/FancyHealthCard'
import Icon from 'react-ui/components/Icon'
import {
  TrackableResultCard,
  TrackableResultCardAlerts,
  TrackableResultCardBottom,
} from 'react-ui/components/TrackableResultCard'
import { healthCardNoRating } from 'services/colours'
import createComponentId from 'shared/services/id'

import type { UserTrackable_user_trackable } from './__generated__/UserTrackable_user_trackable.graphql'

type PropsType = {
  componentId?: string,
  user_trackable: UserTrackable_user_trackable,
  viewOnly?: boolean,
}

const trackableKind = {
  GAUGE: 'gauge',
  TEXT: 'text',
}

const defaultId = createComponentId(__filename)

const iconContainerStyle = () => ({
  lineHeight: 1,
})

const menuOverrideStyle = ({ theme }) => ({
  display: 'flex',
  fontSize: '16px',
  alignItems: 'center',
  height: '100%',
  color: theme.palette.component.primary.text,
})

const UserTrackableBase = (props: PropsType) => {
  const { css } = useFela()
  const { router } = useRouter()

  const { componentId = defaultId, user_trackable, viewOnly } = props
  const { kind } = user_trackable.trackable
  const title = get('trackable.label')(user_trackable)
  const renderedTrendIndicator = get('trending.label')(user_trackable)

  const viewCardDetails = (
    <UserTrackableViewLoader user_trackable={user_trackable}>
      {kind === trackableKind.TEXT ? (
        <div
          className={css(menuOverrideStyle)}
          data-component-id="view-details"
        >
          Browse care options
        </div>
      ) : (
        <div className={css(iconContainerStyle)}>
          <Icon as={ArrowRight} scale={0.7} />
        </div>
      )}
    </UserTrackableViewLoader>
  )

  const color = getOr(healthCardNoRating, 'user_trackable.formula_range.color')(
    props,
  )

  const updateRequired = (
    <TrackableResultCardAlerts user_trackable={user_trackable} />
  )
  const activeLabel = getOr(null, 'user_trackable.formula_range.label')(props)
  const formulaRanges = getOr(
    [],
    'user_trackable.trackable.formula.formula_ranges',
  )(props)
  const labels = sortBy(['value'])(formulaRanges).map(range => range.label)

  // if using the label don't need to edit, if formula range ratio, need to reverse
  // rotate by the smallest formula range value
  const minIndex = flow(map('value'), min)(formulaRanges) || 1

  const activeIndex =
    getOr(
      labels.findIndex(label => label === activeLabel) + minIndex,
      'user_trackable.current_formula_range_ratio.value',
    )(props) - minIndex

  const userId = getOr(null, 'user_trackable.user.id')(props)
  const primaryQuestionnaire = getOr(
    {},
    'primary_user_questionnaire.questionnaire',
  )(user_trackable)

  const pending = getOr(false, 'user_trackable.formula_range.pending')(props)

  const commonCardProps = {
    componentId: `${componentId}-${user_trackable.id}`,
    color,
    updateRequired,
    title,
    pending,
    trendIndicator: renderedTrendIndicator,
  }

  const routeToBOCByTrackableId = () => {
    router.push({
      name: 'user_score_card',
      params: {
        id: user_trackable.user.id,
        trackable_id: user_trackable.trackable.id,
      },
    })
  }

  let renderCard
  switch (kind) {
    case trackableKind.TEXT: {
      renderCard = (
        <TrackableResultCard
          {...(commonCardProps: any)}
          rating={getOr(null, 'formula_range.label')(user_trackable)}
          timeSinceUpdate={user_trackable.time_since_updated}
          bottomNav={
            <TrackableResultCardBottom
              requireSupport={user_trackable.requires_support}
              participants={user_trackable.participants || null}
              questionnaire={primaryQuestionnaire}
              userId={userId}
              viewCardDetails={viewCardDetails}
            />
          }
          handleClick={routeToBOCByTrackableId}
        />
      )
      break
    }
    case trackableKind.GAUGE: {
      renderCard = (
        <FancyHealthCard
          {...(commonCardProps: any)}
          activeIndex={activeIndex}
          labels={labels}
          lastUpdatedLabel={user_trackable.time_since_updated}
          hasData={user_trackable.answered}
          skipped={
            user_trackable?.formula_range?.__typename === 'SkippedFormulaRange'
          }
          routeToBOCByTrackableId={routeToBOCByTrackableId}
          renderNoData={
            <FancyHealthCardNoData
              title={title}
              questionnaire={primaryQuestionnaire}
              viewOnly={viewOnly}
            />
          }
          renderSkipped={
            <FancyHealthCardSkipped
              title={title}
              routeToBOCByTrackableId={routeToBOCByTrackableId}
              lastUpdatedLabel={user_trackable.time_since_updated}
            />
          }
          renderPending={
            <FancyHealthCardPending title={title} viewOnly={viewOnly} />
          }
        />
      )
      break
    }
    default: {
      renderCard = (
        <div>
          <p>No Defaults</p>
        </div>
      )
    }
  }

  return renderCard
}

export const UserTrackableLoader = createFragmentContainer(UserTrackableBase, {
  user_trackable: graphql`
    fragment UserTrackable_user_trackable on UserTrackable {
      ...UserTrackableView_user_trackable
      ...TrackableResultCardAlerts_user_trackable
      current_data {
        date
        value
      }
      current_formula_range_ratio {
        value
      }
      formula_range {
        __typename
        ... on FormulaRange {
          id
        }
        ... on FormulaRangeInterface {
          color
          label
          description
          pending
        }
      }
      answered
      has_activity
      has_supporting_clinician
      id
      participants {
        ...AvatarLoader_user
        id
        name
      }
      primary_user_questionnaire {
        refresh_required
        questionnaire {
          id
        }
        ensure_questionnaire {
          id
          ...QuestionnaireRefresh_questionnaire
        }
      }
      requires_support
      time_since_updated
      refresh_required
      trackable {
        id
        kind
        label
        formula {
          formula_ranges {
            label
            value
          }
        }
      }
      trending {
        label
      }
      user {
        id
        ...AvatarLoader_user
      }
    }
  `,
})
