// @flow

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createFragmentContainer,
  fetchQuery,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'
import debounce from 'lodash/debounce'

import Link from 'react-ui/components/Link'
import Spacer from 'react-ui/components/Spacer'
import { useTenant } from 'react-ui/contexts/TenantContext'
import { commit as commitUserUpdate } from 'mutations/UserUpdate'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import Sentry from 'shared/services/sentry'
import { Input, RadioSet } from 'shared/ui/Forms/Fields'
import { getLanguageFromLocale } from 'shared/utils/Internationalization'
import FieldUpdater from 'platform_web/components/Settings/FieldUpdater'

import {
  query,
  usernameUniqueCheck,
} from '../../queries/UserSettingsUserFieldPage'

import type { UserSettingsUserFieldPage_user } from '../../queries/__generated__/UserSettingsUserFieldPage_user.graphql'

type PropsType = {
  +user: UserSettingsUserFieldPage_user,
}

function UserSettingsUserFieldPage({ user }: PropsType) {
  const { match: { params: { field } }, router } = useRouter()
  const [value, setValue] = useState(user[field])
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState()
  const environment = useRelayEnvironment()

  const { tenant } = useTenant()
  const { supported_languages = [] } = tenant || {}

  function handleSubmit(event) {
    event.preventDefault()

    commitUserUpdate({
      environment,
      variables: {
        input: {
          user: {
            [field]: value,
          },
        },
      },
      onCompleted: () => router.replace('settings_personal_details'),
    })
  }

  function handleChange({ target }: SyntheticInputEvent<*>) {
    setTouched(true)
    setValue(target.value)
  }

  const commonInputProps = {
    errors: error ? { [field]: error } : {},
    name: field,
    onChange: handleChange,
    touched: { [field]: touched },
    value,
    fullWidth: true,
    style: { maxWidth: '25rem' },
  }

  const { t: translation } = useTranslation('settings', {
    keyPrefix: 'userSettingsUserFieldPage',
  })

  const {
    title,
    editComponent,
    description,
    submit,
    validator = () => true,
  } = (() => {
    switch (field) {
      case 'username':
        return {
          title: translation('usernameTitle'),
          editComponent: ({ setValid, setWaiting }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const validateUsername = useCallback(
              debounce(testUsername => {
                validateUsername.cancel()
                setWaiting(true)
                fetchQuery(environment, usernameUniqueCheck, {
                  testUsername,
                }).subscribe({
                  next: data => {
                    const {
                      viewer: { currentUser: { usernameUniqueness } },
                    } = data
                    setValid(usernameUniqueness)

                    setError(
                      usernameUniqueness
                        ? null
                        : translation('usernameUniquenessError'),
                    )
                    return data
                  },
                  error: e => {
                    Sentry.captureException(e)
                    setError(e.message)
                  },
                })
                setWaiting(false)
              }, 750),
              [],
            )

            return (
              <Input
                {...commonInputProps}
                type="text"
                onChange={event => {
                  handleChange(event)
                  validateUsername(event.target.value)
                }}
              />
            )
          },
          description: translation('whatsYourUsername'),
          submit: translation('submitUsername'),
          validator: () => {
            return false
          },
        }
      case 'email':
        return {
          title: translation('emailTitle'),
          editComponent: () => (
            <Input
              {...commonInputProps}
              type="email"
              title={translation('emailAddressTitle')}
            />
          ),
          description: translation('whatsYourEmail'),
          submit: translation('submitEmail'),
        }
      case 'legal_name':
        return {
          title: translation('legalNameTitle'),
          editComponent: () => (
            <Input
              {...commonInputProps}
              type="text"
              pattern=".*[\w][\s]+[\w].*"
              title={translation('legalNameValidation')}
            />
          ),
          description: translation('whatsYourLegalName'),
          submit: translation('submitUpdateName'),
        }
      case 'preferred_name':
        return {
          title: translation('preferredNameTitle'),
          editComponent: () => <Input {...commonInputProps} type="text" />,
          description: translation('whatsYourPreferredName'),
          submit: translation('submitUpdatePreferredName'),
        }
      case 'preferred_language':
        return {
          title: translation('preferredLanguageTitle'),
          editComponent: () => (
            <RadioSet
              {...commonInputProps}
              options={supported_languages.map(supported_language => ({
                title: getLanguageFromLocale(supported_language),
                value: supported_language,
              }))}
              uiStyle="primary"
            />
          ),
          description: translation('whatsYourPreferredLanguage'),
          submit: translation('submitPreferredLanguage'),
        }
      case 'phone_number':
        return {
          title: translation('phoneNumberTitle'),
          editComponent: () => (
            <Input
              {...commonInputProps}
              pattern="^(04)\d{8}$"
              placeholder={translation('phoneNumberPlaceholder')}
              title={translation('mobileNumberValidation')}
              type="text"
            />
          ),
          description: (
            <>
              {translation('mobileNumberDescriptionOne')}
              <Spacer units={1} />
              {translation('mobileNumberDescriptionTwo')}&nbsp;
              <Link to={privacyPolicyUrl()}>
                {translation('privacyPolicy')}
              </Link>
              <Spacer units={1} />
              <strong>{translation('mobileNumber')}</strong>
            </>
          ),
          submit: translation('submitMobileNumber'),
        }
      default:
        throw new Error('unknown edit type')
    }
  })()

  return (
    <FieldUpdater
      title={title}
      description={description}
      handleSubmit={handleSubmit}
      editComponent={editComponent}
      submit={submit}
      validator={validator}
    />
  )
}

export const UserSettingsUserFieldPageLoader = createFragmentContainer(
  UserSettingsUserFieldPage,
  query,
)
