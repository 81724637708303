// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { uniqueId } from 'lodash'

import { FlexContainer, ScrollableBox, Text } from 'care-ui'

import ClinicianNoteCard from '../ClinicianNoteCard'

import { paginationFragment, query } from './query/ClinicianNoteCardContainer'

type PropsType = {
  userId: string,
}

const ClinicianNoteCardContainer = (props: PropsType) => {
  const { userId } = props

  const { node } = useLazyLoadQuery(query, { id: userId, count: 50 })
  const { data, loadNext, hasNext } =
    usePaginationFragment(paginationFragment, node) || {}
  const { edges } = data?.clinician_notes || {}

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'clinicianNoteComponent',
  })

  const clinicianNotes = edges?.map(({ node: clinicianNote }) => (
    <ClinicianNoteCard
      key={uniqueId('clinician-note-')}
      clinicianNote={clinicianNote}
    />
  ))
  const clinicianNotesEmpty = clinicianNotes?.length === 0

  const handleScroll = () => {
    if (hasNext) {
      loadNext(5)
    }
  }

  if (clinicianNotesEmpty) {
    return (
      <FlexContainer justifyContent="center">
        <Text>{translation('noClinicianNote')}</Text>
      </FlexContainer>
    )
  }

  return (
    <ScrollableBox
      maxHeight="100%"
      onScroll={handleScroll}
      dataTestId="notesContainer"
    >
      <FlexContainer direction="column" gap="sm">
        {clinicianNotes}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default ClinicianNoteCardContainer
