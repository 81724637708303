// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button } from 'care-ui'

import { containerStyle } from './SupportingSupporteeQuestionnaire.style'

type PropsType = {
  answereeUserId: string,
  assessmentRequestId?: string,
  label: string,
  questionnaireId: string,
  ready?: boolean,
  submitted?: boolean,
  supporteeName: ?string,
}

const SupportingSupporteeQuestionnaire = (props: PropsType) => {
  const {
    answereeUserId,
    assessmentRequestId,
    label,
    questionnaireId,
    ready,
    submitted,
    supporteeName,
  } = props

  const { t: translation } = useTranslation('supporting', {
    keyPrefix: 'supportingPage.supportingSupporteeQuestionnaire',
  })

  const { css } = useFela()
  const { start } = useQuestionnaireSession({
    answererRoleType: 'SUPPORT_PERSON',
    answereeUserId,
    questionnaireId,
    assessmentRequestId,
  })

  let buttonLabel = translation('startButtonLabel')

  if (submitted) {
    buttonLabel = translation('submitteduttonLabel')
  } else if (ready) {
    buttonLabel = translation('readyToSubmitButtonLabel')
  }

  return (
    <div className={css(containerStyle)}>
      <span>{supporteeName}</span>

      <span>{label}</span>

      <Button
        dataTestId="start-questionnaire-button"
        onClick={start}
        disabled={ready || submitted}
        variant="primary"
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

export default SupportingSupporteeQuestionnaire
