// @flow

import React from 'react'
import { useFela } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { withFormik } from 'formik'
import {
  filter,
  find,
  flow,
  get,
  getOr,
  includes,
  map,
  sortBy,
  startCase,
  toLower,
} from 'lodash/fp'
import * as yup from 'yup'

import { ModelSelect2AdvancedLoader as ModelSelect } from 'components/ModelSelect2'
import { ButtonGroup } from 'react-ui/components/Button'
import { Input, Radio } from 'react-ui/components/Form'
import Link from 'react-ui/components/Link'
import Select from 'react-ui/components/Select'
import Spacer from 'react-ui/components/Spacer'
import {
  checkActiveIndividualStatus,
  checkAvailableRoleInTenant,
  checkPhoneNumberUniqueness,
} from 'shared/services/api/info'
import { requiredTextField } from 'shared/services/formik'
import { FormError } from 'shared/ui/Forms'
import { useFeatureToggle } from 'platform_web/hooks/useFeatureToggle'
import { Button } from 'care-ui'

import {
  formStyle,
  helpTextStyle,
  inputStyle,
  submitButtonStyle,
} from './UserInviteForm.style'

import type { FelaRulesType, FelaStylesType } from 'react-ui/typing'
import { type FormikProps } from 'services/flow'
import type { UserInviteForm_tenantGroups } from './__generated__/UserInviteForm_tenantGroups.graphql'
import type { UserInviteForm_tenants } from './__generated__/UserInviteForm_tenants.graphql'

export type ValuesType = {
  +clinicianAssignmentId: ?string,
  +email: string,
  +emrUserId?: string,
  +phone_number?: string,
  +roleType: string,
  +tenantGroupId: string,
  +tenantId: string,
}

type EmrIntegrationProps = {
  required: boolean,
  userIdFieldName: string,
}

type PropsType = FormikProps & {
  emrIntegration: ?EmrIntegrationProps,
  globalInvitableRoles: Array<string>,
  globalOnlyInvitableRoles: Array<string>,
  hideMobile: boolean,
  invitableMultiTenantRoleTypes: Array<string>,
  invitableRoleTypes: Array<string>,
  invitableUser: {
    clinicianAssignmentId?: ?string,
    email: string,
    emrUserId?: string,
    phone_number?: string,
    roleType: string,
    tenantGroupId?: string,
    tenantId: string,
  },
  onSubmit: (values: ValuesType) => void,
  rules: FelaRulesType,
  styles: FelaStylesType,
  tenantGroups?: UserInviteForm_tenantGroups,
  tenants?: UserInviteForm_tenants,
  values: ValuesType,
}

const roleTypeItems = flow(
  map((roleType: string) => ({
    key: roleType,
    value: startCase(toLower(roleType)),
  })),
  sortBy('value'),
)

// find the emr information of tenant currently selected for the new role
const getEmrIntegration = (id, tenants) =>
  get('emr_integration')(find({ id })(tenants))

const getAllowAdminClinicalAssignments = (id, tenants) =>
  flow(find({ id }), getOr(false, 'allowAdminClinicalAssignments'))(tenants)

const clinicianQuery = graphql`
  query UserInviteFormTenantCliniciansQuery($id: ID!) {
    tenant(id: $id) {
      assignable_clinicians {
        nodes {
          ... on ClinicianRole {
            id
            name
          }
        }
      }
    }
  }
`

const Form = (props: PropsType) => {
  const environment = useRelayEnvironment()
  const { SMS_INVITE } = useFeatureToggle()
  const { css, theme } = useFela()

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    hideMobile,
    invitableRoleTypes = [],
    isSubmitting,
    isValid,
    tenantGroups = [],
    tenants = [],
    touched,
    values,
  } = props

  const inputProps = id => ({
    id,
    onBlur: handleBlur,
    onChange: handleChange,
    value: values[id],
    extend: inputStyle,
    error: touched[id] && errors[id],
  })

  const tenantOrTenantGroup = ((roleType, checkTypes) => {
    const filteredTenants = filter(tenant => !tenant.deactivated)(tenants)
    const tenantSelect = filteredTenants.length > 1 && (
      <>
        <Spacer units={0.5} />
        <label htmlFor="tenantId">
          <strong>Service</strong>
          <Select
            {...(inputProps('tenantId'): any)}
            alwaysShowEmpty={includes(roleType)(props.globalInvitableRoles)}
            items={sortBy('name', filteredTenants)}
            keyName="id"
            nullLabel="Global"
            valueName="name"
          />
        </label>
        <FormError errors={errors} touched={touched} attr="tenantId" />
      </>
    )

    const tenantGroupSelect = tenantGroups?.length >= 1 && (
      <>
        <Spacer units={0.5} />
        <label htmlFor="tenantGroupId">
          <strong>Service Group</strong>
          <Select
            {...(inputProps('tenantGroupId'): any)}
            items={sortBy('name', tenantGroups)}
            keyName="id"
            valueName="label"
          />
        </label>
        <FormError errors={errors} touched={touched} attr="tenantGroupId" />
      </>
    )

    if (includes(roleType)(props.globalOnlyInvitableRoles)) {
      return null
    }

    return includes(roleType)(checkTypes) ? tenantGroupSelect : tenantSelect
  })(values.roleType, props.invitableMultiTenantRoleTypes)

  const fieldsForIndividuals = (roleType => {
    if (roleType !== 'INDIVIDUAL') return null

    const emrIntegration = getEmrIntegration(values.tenantId, tenants)

    const allowAdminClinicalAssignments = getAllowAdminClinicalAssignments(
      values.tenantId,
      tenants,
    )

    return (
      <>
        {emrIntegration && (
          <>
            <Spacer units={0.5} />
            <label htmlFor="emrUserId">
              <strong>{emrIntegration.emr_provider.user_id_field_name}</strong>
              <Input type="text" {...(inputProps('emrUserId'): any)} />
            </label>
            <FormError errors={errors} touched={touched} attr="emrUserId" />
          </>
        )}
        {!hideMobile && (
          <>
            <Spacer units={0.5} />
            <label htmlFor="phone_number">
              <strong>Mobile Phone</strong>
              <Input type="text" {...(inputProps('phone_number'): any)} />
            </label>
            <FormError errors={errors} touched={touched} attr="phone_number" />
            <p className={css(helpTextStyle)}>
              <small>
                Individuals will receive an SMS from Innowell reminding them to
                complete their onboarding. Individuals can update or remove
                their mobile number on their profile page via the Profile
                Settings menu at any time.
              </small>
            </p>
          </>
        )}

        {allowAdminClinicalAssignments && (
          <>
            <Spacer units={0.5} />
            <label htmlFor="clinicianAssignmentId">
              <strong>Assign Clinician</strong>
            </label>
            <br />
            <ModelSelect
              {...(inputProps('clinicianAssignmentId'): any)}
              id="clinicianAssignmentId"
              environment={environment}
              query={clinicianQuery}
              field="tenant.assignable_clinicians.nodes"
              kind="tenantClinicians"
              name="clinicianAssignmentId"
              nullLabel="-- Assign no one --"
              valueName="name"
              variables={{ id: values.tenantId }}
              pipeline={sortBy('name')}
            />
            <FormError
              errors={errors}
              touched={touched}
              attr="clinicianAssignmentId"
            />
          </>
        )}
        <Spacer units={0.5} />

        {SMS_INVITE && (
          <>
            <label htmlFor="inviteType">
              <strong>Invitation Type</strong>
              <Spacer units={0.5} />
              <Radio
                {...(inputProps('inviteType'): any)}
                id="inviteType_SMS"
                checked={values.inviteType === 'SMS'}
                label="SMS"
                name="inviteType"
                onChange={() => {
                  props.setValues({ ...props.values, inviteType: 'SMS' })
                }}
              />
              <Spacer units={0.5} />
              <Radio
                {...(inputProps('inviteType'): any)}
                id="inviteType_EMAIL"
                checked={values.inviteType === 'EMAIL'}
                label="EMAIL"
                name="inviteType"
                onChange={() => {
                  props.setValues({ ...props.values, inviteType: 'EMAIL' })
                }}
              />
            </label>
            <FormError errors={errors} touched={touched} attr="inviteType" />
          </>
        )}
      </>
    )
  })(values.roleType)

  const fieldsForAllUsers = (
    <>
      {/* No spacer needed here as it is the top of the form */}
      <label htmlFor="email">
        <strong>Email</strong>
        <Input {...(inputProps('email'): any)} type="email" />
      </label>
      <FormError errors={errors} touched={touched} attr="email" />

      {invitableRoleTypes &&
        (invitableRoleTypes.length > 1 ? (
          <>
            <Spacer units={0.5} />
            <label htmlFor="roleType">
              <strong>Role Type</strong>
              <Select
                alwaysShowEmpty={false}
                items={roleTypeItems(invitableRoleTypes)}
                keyName="key"
                {...(inputProps('roleType'): any)}
                valueName="value"
              />
            </label>
            <FormError errors={errors} touched={touched} attr="roleType" />
          </>
        ) : (
          <input type="hidden" name="roleType" value={invitableRoleTypes[0]} />
        ))}

      {tenantOrTenantGroup}
    </>
  )

  const isDisabled = !dirty || !isValid || isSubmitting

  return (
    <form onSubmit={handleSubmit} noValidate className={css(formStyle)}>
      {fieldsForAllUsers}
      {fieldsForIndividuals}
      <ButtonGroup
        buttonsPosition="end"
        extend={{ ButtonGroup: submitButtonStyle({ theme }) }}
      >
        <Button
          type="submit"
          disabled={isDisabled}
          variant="primary"
          ariaLabel="Invite"
          dataTestId="invite"
        >
          Invite
        </Button>
      </ButtonGroup>
    </form>
  )
}

export const tenantValidationText = (
  roleType: string,
  globalRoleTypes: $ReadOnlyArray<string>,
) => {
  const globalNotAllowed = includes(roleType)(globalRoleTypes)
    ? ''
    : ` or ${startCase(toLower(roleType))} cannot be of a global tenant`
  return `Tenant is required${globalNotAllowed}`
}

export const tenantEmrRequirements = ({
  roleType,
  tenantId,
  tenants,
}: {
  roleType: string,
  tenantId: string,
  tenants: UserInviteForm_tenants,
}) => {
  if (roleType === 'INDIVIDUAL') {
    return flow(
      find({ id: tenantId }),
      getOr(false, 'emr_integration.required'),
    )(tenants)
  }
  return false
}

export const buildEmrMessage = ({
  tenantId,
  tenants,
}: {
  tenantId: string,
  tenants: UserInviteForm_tenants,
}) => {
  const patient_field_name = flow(
    find({ id: tenantId }),
    getOr('EMR number', 'emr_integration.emr_provider.user_id_field_name'),
  )(tenants)
  return `${patient_field_name} is required`
}

export const UserInviteForm = withFormik({
  mapPropsToValues: (props: PropsType) => ({
    inviteType: props.invitableUser.inviteType || 'EMAIL',
    email: props.invitableUser.email,
    roleType: props.invitableUser.roleType,
    tenantId: props.invitableUser.tenantId,
    tenantGroupId: props.invitableUser.tenantGroupId,
    emrUserId: props.invitableUser.emrUserId,
    phone_number: props.invitableUser.phone_number,
    clinicianAssignmentId: props.invitableUser.clinicianAssignmentId,
  }),
  handleSubmit: (values: ValuesType, { props, resetForm }) => {
    resetForm()
    props.onSubmit(values, () => {
      resetForm()
    })
  },
  validationSchema: props => {
    const validations: any = {
      inviteType: yup
        .string()
        .when(
          'roleType',
          (value, schema) =>
            value === 'INDIVIDUAL'
              ? schema.required('Invite type is required')
              : schema,
        ),
      phone_number: yup
        .string()
        .trim()
        .matches(/^04\d{8}$/, 'Mobile number must follow 04********')
        .when(
          'inviteType',
          (value, schema) =>
            value === 'SMS'
              ? schema.required('Phone number is required')
              : schema,
        )
        .test('unique-phone', 'Phone validation error', async function(value) {
          if (!value) return true

          const uniqueErr = 'Phone number has already been taken'
          const { email } = this.parent
          const response = await checkPhoneNumberUniqueness(value, email)
          const { unique } = await response.json()

          if (!unique) return this.createError({ message: uniqueErr })

          return true
        }),
      email: yup
        .string()
        .email()
        .trim()
        .when(
          'inviteType',
          (value, schema) =>
            value === 'EMAIL' ? schema.required('Email is required') : schema,
        )
        .test('unique-email', 'Email validation error', async function(value) {
          if (!value) return false

          const { invitableUser: { roleType } } = props
          const isIndividualInvite = roleType === 'INDIVIDUAL'

          const uniqueErr =
            'This individual (email address) is active in another service and cannot be invited'
          const uniqueInTenantErr =
            'This individual (email address) is already active and cannot be invited'

          const response = await checkActiveIndividualStatus(value)
          const { unique, unique_in_tenant } = await response.json()

          if (!unique_in_tenant && isIndividualInvite)
            return this.createError({ message: uniqueInTenantErr })
          if (!unique && isIndividualInvite)
            return this.createError({ message: uniqueErr })

          return true
        })
        .test('available-email', 'Email validation error', async function(
          value,
        ) {
          if (!value) return false

          const { roleType, tenantId } = this.parent
          const staffExists = (
            <>
              This staff member (email address) cannot be used for an individual
              role. For support on how to invite a staff member to an individual
              role - see the <Link to="/resources/faq">FAQs</Link>.
            </>
          )
          const individualExists = (
            <>
              This individual (email address) cannot be used for a staff role.
              For Support on how to invite an individual to a staff role - see
              the <Link to="/resources/faq">FAQs</Link>.
            </>
          )
          const supportPersonExists = (
            <>
              This support person (email address) cannot be used for a staff
              role. For Support on how to invite a support person to a staff
              role - see the <Link to="/resources/faq">FAQs</Link>.
            </>
          )

          const ownerExists = (
            <>
              This owner (email address) cannot be used for a manager role. For
              Support on how to invite an owner to a manager role - see the{' '}
              <Link to="/resources/faq">FAQs</Link>.
            </>
          )

          const isIndividualInvite = roleType === 'INDIVIDUAL'
          const isManagerInvite = roleType === 'MANAGER'

          const currentTenantId = isManagerInvite ? tenantId : undefined

          const response = await checkAvailableRoleInTenant(
            value,
            currentTenantId,
          )

          const {
            staff_exists,
            individual_exists,
            support_person_exists,
            owner_exists,
          } = await response.json()

          if (staff_exists && isIndividualInvite)
            return this.createError({ message: staffExists })
          if (individual_exists && !isIndividualInvite)
            return this.createError({ message: individualExists })
          if (support_person_exists && !isIndividualInvite)
            return this.createError({ message: supportPersonExists })
          if (owner_exists && isManagerInvite)
            return this.createError({ message: ownerExists })

          return true
        }),
      roleType: requiredTextField('Role type'),
      clinicianAssignmentId: yup.string(),
      tenantId: yup
        .string()
        .trim()
        .when(
          'roleType',
          (value, schema) =>
            includes(value)(props.globalInvitableRoles) &&
            !includes(value)(props.invitableMultiTenantRoleTypes)
              ? schema
              : schema.required(
                  tenantValidationText(value, props.globalInvitableRoles),
                ),
        ),
      emrUserId: yup
        .string()
        .when(['tenantId', 'roleType'], (tenantId, roleType, schema) => {
          if (
            tenantEmrRequirements({
              roleType,
              tenantId,
              tenants: props.tenants,
            })
          ) {
            return schema.required(
              buildEmrMessage({ tenantId, tenants: props.tenants }),
            )
          }
          return schema
        }),
      tenantGroupId: yup
        .string()
        .trim()
        .when(
          'roleType',
          (value, schema) =>
            includes(value)(props.invitableMultiTenantRoleTypes)
              ? schema.required(
                  `Service group is required for the ${value} role type`,
                )
              : schema,
        ),
    }
    return yup.object().shape(validations)
  },
  validateOnChange: false,
})(Form)

export const UserInviteFormLoader = createFragmentContainer(UserInviteForm, {
  tenants: graphql`
    fragment UserInviteForm_tenants on Tenant @relay(plural: true) {
      id
      name
      deactivated
      emr_provider_id
      emr_integration {
        emr_provider {
          name
          user_id_field_name
        }
        required
      }
      allowAdminClinicalAssignments
    }
  `,
  tenantGroups: graphql`
    fragment UserInviteForm_tenantGroups on TenantGroup @relay(plural: true) {
      id
      label
    }
  `,
})
