// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { styledRulesLink } from './UserRemoveInstructions.style'

type Props = {
  contract: ?string,
  privacyUrl: ?string,
}

const UserRemoveInstructions = (props: Props) => {
  const { contract, privacyUrl } = props
  const { css } = useFela()

  const isAhs = contract === 'ahs'
  const isLondon = contract === 'london'

  const emailAddress = isAhs ? 'emh@ahs.com' : 'femapresearch@lhsc.on.ca'

  const { t: translation } = useTranslation('settings', {
    keyPrefix: 'userSettingsPersonalDetailsPage',
  })

  return isAhs || isLondon ? (
    <>
      {translation('removeFromPlatform')}&nbsp;
      <a className={css(styledRulesLink)} href={`mailto:${emailAddress}`}>
        {emailAddress}
      </a>
      {isLondon && <span>{translation('isLondongCall')}</span>}
      <br />
      {translation('pleaseReferTo')}&nbsp;
      <a className={css(styledRulesLink)} href={privacyUrl}>
        {translation('privacyPolicy')}
      </a>&nbsp;
      {translation('furtherDetails')}
    </>
  ) : null
}

export default UserRemoveInstructions
