// @flow

import React, { useEffect, useState } from 'react'
import { ThemeProvider, useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import useRouter from 'found/useRouter'
import { merge } from 'lodash/fp'

import * as colors from 'react-ui/themes/synergy-base-theme/colors'
import {
  AssessmentRequesteeProvider,
  AssessmentRequestsLibrary,
  AssessmentRequestsList,
  IndividualTrackablesList,
} from 'platform_web/features/AssessmentRequests'
import useChartToggle from 'platform_web/hooks/useChartToggle'
import { useFeatureToggle } from 'platform_web/hooks/useFeatureToggle'
import { Container, PageHeader, SuspenseLoader, Tabs } from 'care-ui'

import {
  containerStyle,
  tabContainerStyle,
  wrapperStyle,
} from './UserAssessmentRequestsPage.style'

const UserAssessmentRequestsPage = () => {
  const { css, theme } = useFela()
  const { match } = useRouter()
  const { params, location } = match || {}
  const { query } = location || {}
  const { tab } = query || {}
  const { isSummaryGraph } = useChartToggle()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const themelette =
    isSummaryGraph &&
    merge(theme, {
      Button: {
        primary: {
          accent: colors.synBlue['800'],
          active: colors.synBlue['800'],
          base: colors.synBlue['900'],
          mutedBase: colors.synBlue['50'],
          mutedActive: colors.synBlue['100'],
          text: colors.white,
        },
      },
    })

  const requested = <AssessmentRequestsList />

  const library = <AssessmentRequestsLibrary />

  const { t: translation } = useTranslation('assessment', {
    keyPrefix: 'assessmentRequestsPages',
  })

  const tabs = [
    { label: translation('tabs.requested'), content: requested },
    { label: translation('tabs.library'), content: library },
  ]

  const [activeContent, setActiveContent] = useState(tabs[0].content)

  useEffect(
    () => {
      if (tab) {
        setActiveContent(tabs[tab].content)
      } else {
        setActiveContent(tabs[0].content)
      }
    },
    [tab],
  )

  const handleTabChange = index => {
    setActiveContent(tabs[index].content)
  }

  const { REQUEST_ASSESSMENTS } = useFeatureToggle()

  if (!REQUEST_ASSESSMENTS) return null

  const action = <IndividualTrackablesList />

  const linkTo = {
    name: 'user_dashboard',
    params: {
      id: params?.id,
    },
  }

  return (
    <ThemeProvider theme={themelette}>
      <AssessmentRequesteeProvider>
        <PageHeader linkTo={linkTo} action={action}>
          {translation('pageHeader')}
        </PageHeader>

        <Container extend={wrapperStyle}>
          <div className={css(tabContainerStyle)}>
            <Tabs
              tabs={tabs}
              defaultTab={Number(tab)}
              onChange={handleTabChange}
            />
          </div>
        </Container>

        <SuspenseLoader>
          <Container extend={containerStyle}>{activeContent}</Container>
        </SuspenseLoader>
      </AssessmentRequesteeProvider>
    </ThemeProvider>
  )
}

export default UserAssessmentRequestsPage
