// @flow

import * as React from 'react'
import { graphql } from 'react-relay'

import { DonutSummary as DonutSummaryChart } from 'react-ui/components/Charts'
import ReportCard from 'react-ui/components/ReportCard'

import ReportWithSettings from '../ReportWithSettings'
import TenantFormulasLoader from '../TenantFormulasLoader'

type PropsType = {
  +variables: Object,
}

const query = graphql`
  query SafetyInitialResultReportQuery(
    $formula: ID!
    $from: DateTime!
    $tenant: ID!
    $to: DateTime!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        safety_initial_result(
          formula: $formula
          from: $from
          tenant: $tenant
          to: $to
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
            total
          }
        }
      }
    }
  }
`

const SafetyInitialResultReport = ({ variables }: PropsType) => (
  <TenantFormulasLoader variables={{ tenants: variables.tenants }}>
    {tenants =>
      tenants.map(tenant =>
        tenant.safety_important_formulas.map(({ id, formula, label }) => (
          <ReportWithSettings
            key={id}
            query={query}
            variables={{
              formula: formula.id,
              from: variables.from,
              tenant: tenant.id,
              to: variables.to,
              report_view: variables.report_view,
            }}
          >
            {data => {
              const { safety_initial_result } = data.viewer.reports

              return (
                <ReportCard title={`${tenant.name}: ${label}`} key={id}>
                  <DonutSummaryChart
                    traces={safety_initial_result.traces}
                    title="Total individuals"
                  />
                </ReportCard>
              )
            }}
          </ReportWithSettings>
        )),
      )
    }
  </TenantFormulasLoader>
)
export default SafetyInitialResultReport
