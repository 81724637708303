// @flow

// Feedback Pages
import feedbackPageTranslations from 'platform_web/pages/Feedback/localizedStrings.json'

const feedbackTranslations = {
  en: {
    feedbackPage: feedbackPageTranslations.en,
  },
  fr: {
    feedbackPage: feedbackPageTranslations.fr,
  },
}

export default feedbackTranslations
