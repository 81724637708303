// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import AlertIcon from 'react-ui/assets/icons/alert-circle.svg'
import Card from 'react-ui/components/Card'
import { H2 } from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import { Button } from 'care-ui'

import { styledRules } from './PageError.style'

const generateErrorMessage = (errorCode, translation) => {
  if (errorCode === 404) {
    return {
      title: translation('404PageHeading'),
      description: '',
    }
  }

  if (errorCode === 500) {
    return {
      title: translation('genericPageHeading'),
      description: <p>{translation('500ErrorDescrption')}</p>,
    }
  }

  if (errorCode === 502) {
    return {
      title: translation('genericPageHeading'),
      description: <p>{translation('502ErrorDescrption')}</p>,
    }
  }

  if (errorCode === 504) {
    return {
      title: translation('genericPageHeading'),
      description: <p>{translation('504ErrorDescrption')}</p>,
    }
  }

  return {
    title: translation('pageHeading'),
    description: '',
  }
}

const PageError = props => {
  const { errorCode, rules, styles } = props

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'errorPage',
  })

  const errorPayload = generateErrorMessage(errorCode, translation)
  const { description, title } = errorPayload

  return (
    <div className={styles.page}>
      <Card extend={rules.card}>
        <Icon as={AlertIcon} scale={4} />

        <H2 extend={rules.cardHeader}>{title}</H2>

        {description || (
          <Button variant="primary" to="/">
            {translation('backButton')}
          </Button>
        )}

        {errorCode && (
          <small className={styles.errorCode}>
            {translation('pageDescription', { errorCode })}
          </small>
        )}
      </Card>
    </div>
  )
}

export default connect(styledRules)(PageError)
