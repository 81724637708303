// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { map } from 'lodash/fp'

import Accordion from 'react-ui/components/Accordion'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import UserResearchProgramsResponse from 'pages/Role/UserResearchProgramsResponse'
import { SuspenseLoader } from 'care-ui'

import { fragment, query } from '../../queries/ResearchDataSharingPage'

import { linkStyle } from './ResearchDataSharingPage.style'

const ResearchDataSharingPage = () => {
  const { viewer: { currentUser } } = useLazyLoadQuery(query)
  const { user_research_programs_responses: fragmentRef } = currentUser || {}
  const user_research_programs_responses = useFragment(fragment, fragmentRef)

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'rolePage',
  })

  return (
    <Fragment>
      <Heading level={2}>{translation('researchPageHeading')}</Heading>
      <p>
        {translation('description')}&nbsp;
        <Link extend={linkStyle} to={{ name: 'research_data_sharing' }}>
          {translation('dataPolicy')}
        </Link>&nbsp;
        {translation('moreInformation')}
      </p>

      <Accordion>
        {map(urpr => (
          <UserResearchProgramsResponse
            key={urpr.id}
            userResponse={urpr}
            researchProgram={urpr.tenant_research_program.research_program}
          />
        ))(user_research_programs_responses)}
      </Accordion>
    </Fragment>
  )
}

export const ResearchDataSharingPageLoader = () => {
  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'rolePage',
  })

  return (
    <SuspenseLoader message={translation('suspenseLoadingMessage')}>
      <ResearchDataSharingPage />
    </SuspenseLoader>
  )
}
