// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Team from 'react-ui/assets/icons/team.svg'
import Markdown from 'react-ui/components/Markdown'
import QuestionnaireInfoCard from 'react-ui/components/QuestionnaireInfoCard'

import { assignmentFragment } from '../../queries/SupportingSupporteeInfoWrapper'
import SupportingSupporteeRemoveModal from '../SupportingSupporteeRemoveModal'

import {
  displayInline,
  markdownStyle,
} from './SupportingSupporteeInfoWrapper.style'

import type { SupportingSupporteeInfoWrapper_individual_assignments$key } from '../../queries/__generated__/SupportingSupporteeInfoWrapper_individual_assignments.graphql'

type PropsType = {
  individual_assignments: SupportingSupporteeInfoWrapper_individual_assignments$key,
}

const SupportingSupporteeInfoWrapper = (props: PropsType) => {
  const { individual_assignments: assignmentsFragmentRef } = props

  const { css } = useFela()
  const individualAssignments = useFragment(
    assignmentFragment,
    assignmentsFragmentRef,
  )

  const { t: translation } = useTranslation('supporting', {
    keyPrefix: 'supportingPage.supportingSupporteeInfoWrapper',
  })

  const supportPersonHelpList = individualAssignments.map(
    ({ individual }, index) => {
      const { legal_name, tenant } = individual
      const { support_person_help } = tenant || {}

      return (
        support_person_help && (
          <span key={uniqueId('support-person-help-list-')}>
            {index !== 0 && <span>, {translation('or')} </span>}
            <Markdown
              source={support_person_help}
              className={css(markdownStyle)}
              data-testid="websiteLinks"
            />
            {individualAssignments.length > 1 && (
              <span>
                {' '}
                ({translation('for')} {legal_name})
              </span>
            )}
          </span>
        )
      )
    },
  )
  const firstContent = [
    translation('firstContentOne'),
    translation('firstContentTwo'),
  ]

  const contactOptions = (
    <>
      {translation('contactOptionOne')}&nbsp;
      {supportPersonHelpList}
      &nbsp;{translation('contactOptionTwo')}
    </>
  )

  const customNote = (
    <SupportingSupporteeRemoveModal
      individualAssignments={individualAssignments}
    />
  )

  return (
    <div className={css(displayInline)}>
      <QuestionnaireInfoCard
        title={translation('infoCardTitleOne')}
        points={firstContent}
        avatar={Team}
      />

      <QuestionnaireInfoCard
        title={translation('infoCardTitleTwo')}
        points={[contactOptions]}
        avatar={InfoCircle}
        customNote={customNote}
      />
    </div>
  )
}

export default SupportingSupporteeInfoWrapper
