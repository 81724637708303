// @flow

import React, { useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import SupportPersonModal from 'components/SupportPersonModal'
import Can from 'react-ui/utils/Can'

import IndividualTasksItem from './IndividualTasksItem'

import type { SupportPersonTask_individual } from './__generated__/SupportPersonTask_individual.graphql'

type PropsType = {
  individual: SupportPersonTask_individual,
}

function SupportPersonTaskBase({
  individual: {
    individual_detail,
    has_support_person_attached_to_individual: hasSupportPerson,
    support_person_assignments: supportPersonAssignments,
  },
}: PropsType) {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleOpen = () => setIsOpen(prev => !prev)

  return (
    <Can>
      {({
        CAN_CREATE_SUPPORT_PERSON_ASSIGNMENTS: canCreate,
        CAN_USE_SUPPORT_PERSON: featureToggle,
      }) => {
        const isReadOnlyIndividual = Boolean(
          individual_detail && individual_detail.read_only,
        )

        if (!featureToggle || isReadOnlyIndividual || !canCreate) return null

        return (
          <>
            <SupportPersonModal
              hasSupportPerson={hasSupportPerson}
              isOpen={isOpen}
              onToggleOpen={onToggleOpen}
              supportPersonAssignments={supportPersonAssignments}
            />
            <IndividualTasksItem
              header="Invite/Edit support person"
              onClick={onToggleOpen}
            >
              You can invite a support person to answer some questions about
              your overall health and well-being
            </IndividualTasksItem>
          </>
        )
      }}
    </Can>
  )
}

const SupportPersonTask = createFragmentContainer(SupportPersonTaskBase, {
  individual: graphql`
    fragment SupportPersonTask_individual on IndividualRole {
      has_support_person_attached_to_individual
      individual_detail {
        read_only
      }
      support_person_assignments {
        id
        nickname
        relationship
        support_person {
          email
        }
        last_requested_at
        accepted_invitation
        last_completed
      }
    }
  `,
})

export default SupportPersonTask
