// @flow

import React from 'react'

import IndividualTasksItem from './IndividualTasksItem'

export default () => {
  const handleClick = () => {
    const newWindow = window.open(
      '/individual/emr',
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
  }

  return (
    <IndividualTasksItem
      header="Download health history PDF"
      onClick={handleClick}
    >
      It&apos;s your history and you can take it with you.
    </IndividualTasksItem>
  )
}
