// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import {
  useFragment,
  useLazyLoadQuery,
  useRelayEnvironment,
} from 'react-relay'

import Card from 'react-ui/components/Card'
import ToggleButton from 'react-ui/components/Form/ToggleButton'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import { commit as commitNotificationSettings } from 'mutations/IndividualDetail/NotificationPreferenceUpdateInput'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import { SuspenseLoader } from 'care-ui'

import { fragment, query } from "../../queries/UserNotificationsPage"

import { cardStyle, toggleStyle } from './UserNotificationsPage.style'

const UserNotificationsPage = () => {
  const { viewer: { currentUser } } = useLazyLoadQuery(query)
  const user = useFragment(fragment, currentUser)
  const { css } = useFela()
  const environment = useRelayEnvironment()

  const setPreferences = () => {
    commitNotificationSettings({
      environment,
      variables: {
        input: {
          id: user.notification_preference.id,
          notification_preference: {
            sms: !user.notification_preference.sms,
          },
        },
      },
    })
  }

  const { t: translation } = useTranslation('settings', {
    keyPrefix: 'userNotificationPage',
  })

  return (
    <>
      <Heading level={3}>{translation('smsNotificationsHeading')}</Heading>

      <Card>
        <div className={css(cardStyle)}>
          <div className={css(toggleStyle)}>
            <ToggleButton
              id="notification_settings"
              onChange={setPreferences}
              checked={user.notification_preference.sms}
            />
          </div>
        </div>

        <p>
          {translation('descriptionOne')}
        </p>

        <p>
          {translation('descriptionTwo')}

          <Link to={privacyPolicyUrl()}>
            {translation('privacyPolicy')}
          </Link>
        </p>
      </Card>
    </>
  )
}

export const UserNotificationsPageLoader = () => {
  const { t: translation } = useTranslation('settings', {
    keyPrefix: 'userNotificationPage',
  })

  return (
    <SuspenseLoader message={translation('notificationsSettingsLoadingMessage')}>
      <UserNotificationsPage />
    </SuspenseLoader>
  )
} 
