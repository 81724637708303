// @flow

import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'

import useGraphPagination from '../../hooks/useGraphPagination'
import { type OverviewQueryVariableType } from '../../hooks/useOverviewInsights'
import { query as individualLoginsQuery } from '../../queries/IndividualLoginsInsightsQuery'

import { type IndividualLoginsInsightsQuery } from '../../queries/__generated__/IndividualLoginsInsightsQuery.graphql'

type ComponentProps = {
  loadIndividualLoginsQuery: (
    overviewQueryVariables: OverviewQueryVariableType,
  ) => void,
  overviewQueryVariables: OverviewQueryVariableType,
  queryReference: PreloadedQuery<IndividualLoginsInsightsQuery>,
}

const Component = (props: ComponentProps) => {
  const {
    overviewQueryVariables,
    queryReference,
    loadIndividualLoginsQuery,
  } = props
  const queryResult = usePreloadedQuery(individualLoginsQuery, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.individual_logins.series_categories',
  )
  const series = get(queryResult, 'viewer.insights.individual_logins.series')

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    overviewQueryVariables,
    loadQuery: loadIndividualLoginsQuery,
  })

  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'individualLoginsIsightsGraphComponent',
  })

  return (
    <Graph
      graphType="stack"
      series={series}
      seriesCategories={seriesCategories}
      onPaginateLeft={() => onPaginate('left')}
      onPaginateRight={() => onPaginate('right')}
      isRightPaginationDisabled={isRightPaginationDisabled}
      xAxisTitle={translation('xAxisTitle')}
    />
  )
}

type IndividualLoginsInsightsGraphProps = {
  overviewQueryVariables: OverviewQueryVariableType,
}

// Query Loader
const IndividualLoginsInsightsGraph = (
  props: IndividualLoginsInsightsGraphProps,
) => {
  const { overviewQueryVariables } = props

  const [
    individualLoginsQueryReference,
    loadIndividualLoginsQuery,
  ] = useQueryLoader(individualLoginsQuery)

  // Load query when query variables change
  useEffect(
    () => {
      loadIndividualLoginsQuery(overviewQueryVariables)
    },
    [overviewQueryVariables],
  )

  // transalations
  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'individualLoginsIsightsGraphComponent',
  })

  return (
    <GraphContainer
      heading={translation('graphHeading')}
      graphInfoText={translation('graphText')}
    >
      <Suspense>
        {individualLoginsQueryReference != null && (
          <Component
            overviewQueryVariables={overviewQueryVariables}
            queryReference={individualLoginsQueryReference}
            loadIndividualLoginsQuery={loadIndividualLoginsQuery}
          />
        )}
      </Suspense>
    </GraphContainer>
  )
}

export default IndividualLoginsInsightsGraph
