// @flow

import React, { Suspense, useEffect } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { type GraphQLTaggedNode } from 'relay-runtime'

import TinyLoader from 'react-ui/components/LoadingSpinner/TinyLoader'
import UnauthorizedPage from 'pages/UnauthorizedPage'
import { useAuthPolicy } from 'platform_web/features/Policies'
import StaffNavigationTabs from 'platform_web/features/StaffNavigationTabs'
import { Button, Container, Divider, FlexContainer } from 'care-ui'

import useActionModal from '../hooks/useActionModal'
import useCliniciansTable from '../hooks/useCliniciansTable'
import useMultiSelect from '../hooks/useMultiSelect'
import ClinicianAssignmentsCreateMutation from '../Individuals/mutations/ClinicianAssignmentsCreateMutation'

import CliniciansTableControl from './ClinciansTableControl'
import AssignIndividualsModal from './components/AssignIndividualsModal'
import CliniciansTable from './components/CliniciansTable'
import useClinicianActions from './hooks/useClinicianActions'

type ActionButtonType = {
  action: string,
  disabled: boolean,
  label: string,
  modalComponent: any,
  mutationQuery: GraphQLTaggedNode,
}
type actionButtonGroupsType = {
  translation: TFunction,
}

const buildActionButtonGroups = (
  params: actionButtonGroupsType,
): ActionButtonType[] => {
  const { translation } = params

  return [
    {
      action: 'assignIndividuals',
      label: translation('assignIndividuals'),
      disabled: false,
      modalComponent: AssignIndividualsModal,
      mutationQuery: ClinicianAssignmentsCreateMutation,
    },
  ]
}

const StaffCliniciansPage = () => {
  const useIndividualTablePayload = useCliniciansTable()
  const { queryVariables } = useIndividualTablePayload

  const multiSelectPayload = useMultiSelect()

  const { t: translation } = useTranslation('staff', {
    keyPrefix: 'clinician.clinicianTablePage',
  })
  const {
    allSelected,
    hasSelectedRoleIds,
    setMultiSelectState,
  } = multiSelectPayload

  const { actions } = useClinicianActions()

  // Feature Toggle
  const enableMultiSelect = window.GLOBALS?.features?.MULTI_SELECT

  const actionButtonGroups = buildActionButtonGroups({ translation })

  const {
    ModalComponent,
    handleButtonClick,
    handleCloseModal,
    isOpen,
    actionButton,
  } = useActionModal({ actionButtonGroups })

  useEffect(() => {
    setMultiSelectState({
      isMultiSelect: enableMultiSelect && !!actions.length,
    })
  }, [])

  return (
    <>
      <nav>
        <Container>
          <StaffNavigationTabs />
        </Container>
        <Divider />
      </nav>

      <main>
        <Container>
          {/* Table Controls */}
          {!hasSelectedRoleIds &&
            !allSelected && (
              <CliniciansTableControl
                useIndividualTablePayload={useIndividualTablePayload}
              />
            )}
          {ModalComponent && (
            <ModalComponent
              closeModal={handleCloseModal}
              isOpen={isOpen}
              multiSelectPayload={multiSelectPayload}
              mutationQuery={actionButton?.mutationQuery}
              queryVariables={queryVariables}
            />
          )}
          {/* MultiSelect Actions */}
          {(hasSelectedRoleIds || allSelected) && (
            <FlexContainer paddingX="xxs" gap="xxxs">
              {actionButtonGroups.map(
                ({ action, label, disabled }) =>
                  actions.includes(action) && (
                    <Button
                      onClick={
                        !disabled ? () => handleButtonClick(action) : undefined
                      }
                      key={action}
                      variant="primary"
                    >
                      {label}
                    </Button>
                  ),
              )}
            </FlexContainer>
          )}

          {/* Table Data */}
          <Suspense
            fallback={<TinyLoader message={translation('tinyLoaderMessage')} />}
          >
            <CliniciansTable
              useIndividualTablePayload={useIndividualTablePayload}
              multiSelectPayload={multiSelectPayload}
            />
          </Suspense>
        </Container>
      </main>
    </>
  )
}

export const StaffCliniciansPageWithPolicies = () => {
  const { isAuthorized, isLoading } = useAuthPolicy([
    'CAN_VIEW_LEAD_CLINICIAN_ADMIN_NAMESPACE',
    'CAN_LIST_CLINICIANS',
  ])

  if (isLoading) return null

  return isAuthorized ? <StaffCliniciansPage /> : <UnauthorizedPage />
}
