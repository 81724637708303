// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-fela'
import { map } from 'lodash/fp'

import { Container } from 'react-ui/components/Grid'
import MatchMedia from 'react-ui/components/MatchMedia'
import Section from 'react-ui/components/Section'
import HelpProviderCard from 'shared/ui/Elements/HelpProviderCard/HelpProviderCard'
import { Heading } from 'care-ui'

const stylesRules = ({ theme: { palette, breakpoints } }) => ({
  container: {
    padding: '0 85px',
    [breakpoints.queries.xsOnly]: {
      padding: '0',
    },
  },
  title: {
    color: palette.component.primary.text,
    marginBottom: '2rem',
    paddingTop: '2rem',
  },
  immediateHelpCallText: {
    float: 'left',
    margin: 0,
  },
  talkToSomeoneTitle: {
    color: palette.component.primary.base,
    marginBottom: '2rem',
    paddingTop: '1rem',
  },
  immediateHelpBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '28px 21px',
    borderRadius: '4.5px',
    backgroundColor: palette.component.primary.base,
    boxShadow: `0 0.1666rem 0.333rem 0.111rem ${
      palette.component.primary.base
    }`,
  },
})

const emergencyNumberByCountry = (country = window.GLOBALS.geoLocationAlpha2) =>
  country === 'CA' ? '911' : '000'

const HelpNowPage = ({ providers, emergencyNumber, styles, rules }: *) => (
  <MatchMedia>
    {({ md }) => (
      <Fragment>
        <Section color="primary">
          <Container className={styles.container}>
            <Heading level={1} extend={rules.title}>
              Need Help now?
            </Heading>
            <div className={styles.immediateHelpBox}>
              <Heading level={3} extend={rules.immediateHelpCallText}>
                If you or someone you know needs immediate help, call{' '}
                {emergencyNumber || emergencyNumberByCountry()} now.
              </Heading>
            </div>
          </Container>
        </Section>
        <Section>
          <Container color="grey" className={styles.container}>
            <Heading level={2} extend={rules.talkToSomeoneTitle}>
              Talk to Someone
            </Heading>
            <div className="HelpNowProviders">
              {map(
                provider => (
                  <HelpProviderCard
                    mini={!md}
                    key={provider.id || provider.slug}
                    provider={provider}
                  />
                ),
                providers,
              )}
            </div>
          </Container>
        </Section>
      </Fragment>
    )}
  </MatchMedia>
)

export default connect(stylesRules)(HelpNowPage)
