/* eslint-disable simple-import-sort/sort */
// @flow

// Care UI Pages
import graphsTranslations from 'care-ui/organisms/Graphs/localizedStrings.json'
import pageHeaderTranslations from 'care-ui/molecules/Heading/PageHeader/localizedStrings.json'

const careUITranslations = {
  en: {
    graphsComponent: graphsTranslations.en,
    pageHeaderComponent: pageHeaderTranslations.en,
  },
  fr: {
    graphsComponent: graphsTranslations.fr,
    pageHeaderComponent: pageHeaderTranslations.fr,
  },
}

export default careUITranslations
