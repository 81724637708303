// @flow

import React, { type Node, Fragment } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import Heading from 'react-ui/components/Heading'
import Card from 'shared/ui/Layout/Card/Card'
import { Circle } from 'shared/ui/Shapes/Circle'
import { Tick } from 'shared/ui/Typography/Icons'
import { Button } from 'care-ui'

import styleRules from '../../styles'
import OnboardingTemplate from '../OnboardingTemplate'

import { type FelaRulesType } from 'react-ui/typing'

type PropsType = {
  handleQuestionsSubmit?: (event?: SyntheticEvent<any>) => any,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
  userName: Node,
}

const OnboardingFinished = (props: PropsType) => {
  const { handleQuestionsSubmit, rules, styles = {}, userName } = props

  const { t: translation } = useTranslation('onboarding', {
    keyPrefix: 'clinicianOnboardingPage.onboardingFinished',
  })

  return (
    <OnboardingTemplate
      pageTitle={
        <Fragment>
          {translation('pageTitle')} {userName}!
        </Fragment>
      }
      pageContent={translation('pageContent')}
    >
      <Card className={styles.cardContainer}>
        <br />

        <Circle
          fill="green"
          alignment="none"
          size="xl"
          className={styles.circleFinished}
        >
          <Tick size="xl" color="white" />
        </Circle>

        <Heading level={3} extend={rules.cardTitleFinished}>
          {translation('yourProfile')}
        </Heading>

        <div className={styles.cardContentFinished}>
          <p>{translation('completed')}</p>
        </div>

        <Button variant="primary" onClick={handleQuestionsSubmit}>
          {translation('continueButton')}
        </Button>
      </Card>
    </OnboardingTemplate>
  )
}

export default connect(styleRules)(OnboardingFinished)
