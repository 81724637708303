// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { MyEscalationsTableLoader } from 'components/Escalation/MyEscalationsTable'
import { StratifiedScoringResultHistoryLoader } from 'components/StratifiedScoringResultHistory'
import Accordion, { AccordionItem } from 'react-ui/components/Accordion'

import { HealthHistoryLoader } from './HealthHistory'

import type { HealthHistoryTab_user } from './__generated__/HealthHistoryTab_user.graphql'

type PropsType = {
  user: HealthHistoryTab_user,
}

const HealthHistoryTab = ({
  user,
  user: { escalations, stratified_scoring_result, tenant },
}: PropsType) => {
  const { stratified_scoring } = tenant || {}

  return (
    <div>
      <HealthHistoryLoader user={user} />
      {stratified_scoring &&
        stratified_scoring_result && (
          <Accordion>
            <AccordionItem
              data-component-id="InitialClusterStage"
              heading="Initial Cluster Stage"
            >
              <StratifiedScoringResultHistoryLoader
                stratified_scoring_result={stratified_scoring_result}
              />
            </AccordionItem>
          </Accordion>
        )}
      <Accordion>
        <AccordionItem
          data-component-id="EscalationHistory"
          heading="Notifications History"
        >
          <MyEscalationsTableLoader escalations={escalations} />
        </AccordionItem>
      </Accordion>
    </div>
  )
}

const HealthHistoryTabLoader = createFragmentContainer(HealthHistoryTab, {
  user: graphql`
    fragment HealthHistoryTab_user on User {
      ...HealthHistory_user
      escalations {
        ...MyEscalationsTable_escalations
      }
      stratified_scoring_result {
        ...StratifiedScoringResultHistory_stratified_scoring_result
      }
      tenant {
        stratified_scoring
      }
    }
  `,
})

export default HealthHistoryTabLoader
