// @flow

import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns'

import { type DateTimeAxisType } from 'react-ui/components/Charts/HighChartOptions/defaultOptions'
import { localToUtc, toISO } from 'services/dateTime'

import { type GraphWithFiltersQueryVariables } from './__generated__/GraphWithFiltersQuery.graphql'

const dateFnsOptions = { weekStartsOn: 1 }

type datesReturnType = {
  endDate: Date,
  startDate: Date,
}

type periodType = 'Biannual' | 'Quarter' | 'Month' | 'Week'

export function biannualDates(offset: number): datesReturnType {
  const biannualOffset = addMonths(new Date(), offset)
  return {
    startDate: startOfMonth(subMonths(biannualOffset, 4)),
    endDate: startOfMonth(addMonths(biannualOffset, 1)),
  }
}
export function quarterDates(offset: number): datesReturnType {
  const quarterOffset = addMonths(new Date(), offset * 3)
  return {
    startDate: startOfMonth(subMonths(quarterOffset, 2)),
    endDate: endOfMonth(quarterOffset),
  }
}
export function monthDates(offset: number): datesReturnType {
  const monthOffset = addMonths(new Date(), offset)
  return {
    startDate: startOfMonth(monthOffset),
    endDate: endOfMonth(monthOffset),
  }
}
export function weekDates(offset: number): datesReturnType {
  const weekOffset = addWeeks(new Date(), offset)
  return {
    startDate: startOfWeek(weekOffset, dateFnsOptions),
    endDate: endOfWeek(weekOffset, dateFnsOptions),
  }
}

export function getDates(period: periodType, offset: number): datesReturnType {
  switch (period) {
    case 'Biannual':
      return biannualDates(offset)
    case 'Quarter':
      return quarterDates(offset)
    case 'Month':
      return monthDates(offset)
    case 'Week':
      return weekDates(offset)
    default:
      throw new TypeError('The period param must be Quarter, Month, or Week')
  }
}

export function getDateFormat(period: periodType): string {
  switch (period) {
    case 'Biannual':
      return 'MMM ‘yy'
    case 'Quarter':
      return 'MMM ‘yy'
    case 'Month':
      return 'MMMM yyyy'
    case 'Week':
      return 'd MMM ‘yy'
    default:
      throw new TypeError('The period param must be Quarter, Month, or Week')
  }
}

export function getDateDisplay(
  period: periodType,
  endDate: Date,
  startDate: Date,
) {
  const dateFormat = getDateFormat(period)
  if (period === 'Month') return format(startDate, dateFormat)
  return `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`
}

type paramsReturnType = {
  dateDisplay: string,
  dateRange: DateTimeAxisType,
  variables: GraphWithFiltersQueryVariables,
}

export function getParams(
  period: periodType,
  offset: number,
  routerParams?: { [string]: * },
): paramsReturnType {
  const { endDate, startDate } = getDates(period, offset)
  const utcEndDate = localToUtc(endDate)
  const utcStartDate = localToUtc(startDate)

  const dateDisplay = getDateDisplay(period, endDate, startDate)

  const dateRange = {
    localStartDate: startDate,
    localEndDate: endDate,
    utcStartDate,
    utcEndDate,
    selectedFilter: period,
  }

  const variables = {
    ...(routerParams: any),
    trackable_start_time: toISO(startDate),
    trackable_end_time: toISO(endDate),
    fitbit_start_date: toISO(utcStartDate),
    fitbit_end_date: toISO(utcEndDate),
  }

  return { dateRange, variables, dateDisplay }
}

type enhanceFormulaRangeDescriptionPropsType = {
  description: string,
  label: ?string,
}

const skipReasonSuffix =
  'To get a result for this health card you can update your answers anytime.'
export const unAnsweredDescription = `The health assessment for this section is not complete. ${skipReasonSuffix}`
export const skip_reasons = {
  later: `You chose to skip and do this question set at a later time. ${skipReasonSuffix}`,
  with_clinician: `You chose to skip and do this question set with your clinician. This request has been shared with them. ${skipReasonSuffix}`,
  irrelevant: `You chose to skip this question set because you preferred not to answer. ${skipReasonSuffix}`,
  with_support: `You chose to skip and do this question set with a support person. ${skipReasonSuffix}`,
}

export function conditionalFormulaRangeDescription({
  label,
  description,
}: enhanceFormulaRangeDescriptionPropsType) {
  switch (label) {
    case 'Skipped':
      return skip_reasons[description]
    case 'Unanswered':
      return unAnsweredDescription
    default:
      return description
  }
}
