// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import Can from 'react-ui/utils/Can'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

import IndividualTasksItem from './IndividualTasksItem'

import type { InitialQuestionnaireUpdateTask_individual } from './__generated__/InitialQuestionnaireUpdateTask_individual.graphql'

type PropsType = {
  individual: InitialQuestionnaireUpdateTask_individual,
}

const InitialQuestionnaireUpdateTaskComponent = (props: PropsType) => {
  const { individual: { tenant } } = props

  const questionnaireId = tenant?.initial_questionnaire?.id || ''

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  if (!tenant || !tenant.initial_questionnaire) return null

  return (
    <Can>
      {({ CAN_CREATE_USER_QUESTIONNAIRE }) => {
        return CAN_CREATE_USER_QUESTIONNAIRE ? (
          <IndividualTasksItem
            header="Retake Full Questionnaire"
            onClick={start}
          >
            Retaking the Full Questionnaire resets your baseline across all
            domains.
          </IndividualTasksItem>
        ) : null
      }}
    </Can>
  )
}

export default createFragmentContainer(
  InitialQuestionnaireUpdateTaskComponent,
  {
    individual: graphql`
      fragment InitialQuestionnaireUpdateTask_individual on IndividualRole {
        tenant {
          initial_questionnaire {
            id
          }
        }
      }
    `,
  },
)
