/* eslint-disable simple-import-sort/sort */
// @flow

// Assessments Pages
import assessmentRequestsFeaturesTranslations from 'platform_web/features/AssessmentRequests/localizedStrings.json'
import assessmentRequestsTranslations from 'platform_web/pages/AssessmentRequests/localizedStrings.json'
import individualTrackablesListTranslations from 'platform_web/features/AssessmentRequests/IndividualTrackablesList/localizedStrings.json'

const assessmentTranslations = {
  en: {
    assessmentRequestsFeaturesPages: assessmentRequestsFeaturesTranslations.en,
    assessmentRequestsPages: assessmentRequestsTranslations.en,
    individualTrackablesListPages: individualTrackablesListTranslations.en,
  },
  fr: {
    assessmentRequestsFeaturesPages: assessmentRequestsFeaturesTranslations.fr,
    assessmentRequestsPages: assessmentRequestsTranslations.fr,
    individualTrackablesListPages: individualTrackablesListTranslations.fr,
  },
}

export default assessmentTranslations
