// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'found'
import { uniqueId } from 'lodash/fp'

import { usePolicies } from 'platform_web/features/Policies'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Text } from 'care-ui'

import { containerStyle, optionStyle } from './HealthCardMenuOptions.style'

type PropsType = {
  questionnaireId: string,
  trackableId: string,
  userId: string,
}

const HealthCardMenuOptions = (props: PropsType) => {
  const { questionnaireId, trackableId, userId } = props

  const { css } = useFela()
  const policies = usePolicies()
  const { router } = useRouter()
  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  const routeToOptions = () => {
    router.push({
      name: 'user_score_card',
      params: {
        id: userId,
        trackable_id: trackableId,
      },
    })
  }

  const { t: translation } = useTranslation('health', {
    keyPrefix: 'healthCardPages',
  })

  const routeToAnswers = () => {
    router.push({
      name: 'user_questionnaire_details',
      params: {
        id: userId,
        questionnaire_id: questionnaireId,
      },
    })
  }

  const viewCardDetails = (
    <div
      onClick={routeToOptions}
      onKeyPress={routeToOptions}
      role="button"
      tabIndex="0"
    >
      {translation('browseCareOptions')}
    </div>
  )

  const answerQuestions = policies.CAN_CREATE_USER_QUESTIONNAIRE ? (
    <div onClick={start} onKeyPress={start} role="button" tabIndex="0">
      {translation('retakeQuestionnaire')}
    </div>
  ) : null

  const viewAnswers = (
    <div
      onClick={routeToAnswers}
      onKeyPress={routeToAnswers}
      role="button"
      tabIndex="0"
    >
      {translation('viewAnswers')}
    </div>
  )

  const options = [viewCardDetails, answerQuestions, viewAnswers].filter(
    value => !!value,
  )

  return (
    <div className={css(containerStyle)}>
      {options.map(option => (
        <Text extend={optionStyle} key={uniqueId('health-card-menu-option-')}>
          {option}
        </Text>
      ))}
    </div>
  )
}

export default HealthCardMenuOptions
