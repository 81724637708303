//  @flow

import React, { useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addNoDataModule from 'highcharts/modules/no-data-to-display'

import {
  type ActivityType,
  useScoreCardChart,
} from 'react-ui/contexts/ScoreCardChartContext/ScoreCardChartContext'
import { trackFitbitViewActivityGraph } from 'services/analytics/events'
import { asUTC, longDayWeekNameFormat, MILLIS_PER_DAY } from 'services/dateTime'

import {
  type DateTimeAxisType,
  basic,
  chart,
  chartTitle,
  dateTimeAxis,
  responsiveRules,
  stepsOptions,
} from './defaultOptions'
import { calculateGoal, calculateSteps, withinDateRange } from './helpers'

addNoDataModule(Highcharts)

type PropsType = {
  testId?: string,
}

type OptionPropsType = {
  activity?: ?ActivityType,
  broadcastPointData?: Function,
  changeLogEntries?: Array<Object>,
  dateRange: DateTimeAxisType,
  hasAdditionalTrajectory?: boolean,
}

export const activitySeries = (
  data: ActivityType,
  dateRange: DateTimeAxisType,
) => {
  const points = data.map(({ date, goal, steps }) => [
    asUTC(date).getTime(),
    calculateSteps(steps, goal),
  ])

  const goalGraphPoint = data.map(({ date, goal, steps }) => [
    asUTC(date).getTime(),
    calculateGoal(steps, goal),
  ])

  const pointTemplate = ({ color, index }) => {
    const userSteps = data.map(({ steps }) => steps)
    const localSteps = userSteps[index].toLocaleString()
    // This is a default template https://api.highcharts.com/highcharts/tooltip.pointFormat
    return `<span style="color:${color}">\u25CF</span> Steps: <b>${localSteps}  steps</b>`
  }
  const sharedConfig = {
    data: {
      startDate: dateRange.utcStartDate,
      endDate: dateRange.utcEndDate,
    },
    config: {
      type: 'column',
      pointRange: MILLIS_PER_DAY,
      stack: 0,
      width: 20,
      yAxis: 0,
      tooltip: {
        shared: true,
        enabled: false,
        pointFormatter() {
          return pointTemplate(this)
        },
      },
    },
  }

  const goalSeries = {
    name: 'Steps over goal',
    color: '#2D8A3A',
    legendIndex: 1,
    data: withinDateRange({
      series: goalGraphPoint,
      ...sharedConfig.data,
    }),
    ...sharedConfig.config,
  }

  const stepSeries = {
    name: 'Total steps',
    color: '#A3CC3B',
    legendIndex: 0,
    data: withinDateRange({
      series: points,
      ...sharedConfig.data,
    }),
    ...sharedConfig.config,
  }

  return [goalSeries, stepSeries]
}

const options = ({
  activity,
  broadcastPointData,
  dateRange,
}: OptionPropsType) => {
  if (!dateRange) return null

  const seriesSet = []

  if (activity && activity.length > 0) {
    activitySeries(activity, dateRange).map(graph => seriesSet.push(graph))
  }

  if (seriesSet.length < 1) {
    activitySeries([], dateRange).map(graph => seriesSet.push(graph))
  }

  return {
    ...chartTitle(),
    ...basic,
    ...responsiveRules,

    chart: {
      ...chart,
    },
    xAxis: {
      ...dateTimeAxis(dateRange),
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        animation: false,
        stickyTracking: false,
        pointPlacement: 'on',
        events: {
          click: broadcastPointData,
        },
      },
    },
    series: seriesSet,
    yAxis: stepsOptions,
    tooltip: {
      shared: false,
      useHTML: true,
      outside: true,
      padding: 14,
      style: {
        width: '332px',
      },
      hideDelay: 0,
      formatter() {
        const { point } = this
        return `
          <span style="line-height: 20px; font-size: 14px;">
            <b>${longDayWeekNameFormat(new Date(point.x))}</b>
            <br />
            ${point.y}
            <br/>
          </span>
        `
      },
    },
  }
}

/*
 * @prop {activity}: Receive the activity data
 * @prop {displayActivity}: Enable activity graph
 */
const ActivityChart = (props: PropsType) => {
  const { testId } = props

  const scoreCardProps = useScoreCardChart()
  const generatedOptions = options(scoreCardProps)

  useEffect(() => {
    trackFitbitViewActivityGraph()
  }, [])

  return (
    <div data-testid={testId}>
      <HighchartsReact
        highcharts={Highcharts}
        options={generatedOptions}
        immutable
        constructorType="chart"
      />
    </div>
  )
}

export default ActivityChart
