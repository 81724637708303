// @flow

import * as React from 'react'
import { connect } from 'react-fela'

const styleRules = () => ({
  LoaderWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#676767',
    textAlign: 'center',
  },
  LoaderTitle: {
    fontSize: '12px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
const TinyLoader = ({ message, styles }: *) => (
  <div className={styles.LoaderWrapper}>
    {message && <p className={styles.LoaderTitle}>{message}</p>}
  </div>
)

export default connect(styleRules)(TinyLoader)
