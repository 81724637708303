// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { subDays } from 'date-fns'
import { flow, invoke } from 'lodash/fp'
import { commitLocalUpdate } from 'relay-runtime'

import {
  AccessibilityEquityReport,
  ContinuityCoordinationReport,
  EffectivenessOutcomesReport,
  EfficiencyExpenditureCostReport,
  SafetyReport,
  SatisfactionAcceptabilityReport,
  WorkforceReport,
} from './reports'
import { ReportSettingsTenantFetchLoader } from './ReportSettingsTenantFetch'
import ServiceMetricsPageWrapper from './ServiceMetricsPageWrapper'

import type { ServiceMetricsHomePage_viewer } from './__generated__/ServiceMetricsHomePage_viewer.graphql'

type PropsType = {
  match: Object,
  relay: Object,
  viewer: ServiceMetricsHomePage_viewer,
}

const startDate = flow(
  (date: Date): Date => subDays(date, 3),
  invoke('toISOString'),
)

const endDate = invoke('toISOString')

class ServiceMetricsHomePage extends React.Component<PropsType> {
  constructor(props: PropsType) {
    super()

    const {
      viewer: { reports: { available_tenants } },
      relay: { environment },
    } = props

    /*
     * Configure default values for the from / to (start and end of current
     * day), available_tenants (all visible to current role) and clinicians (empty to
     * start with)
     */
    commitLocalUpdate(environment, store => {
      const reportSettings =
        store.get('reportSettings') ||
        store.create('reportSettings', 'ReportSettings')
      reportSettings.setValue(startDate(new Date()), 'from')
      reportSettings.setValue(endDate(new Date()), 'to')
      reportSettings.setValue(available_tenants.map(({ id }) => id), 'tenants')
      reportSettings.setValue([], 'clinicians')
      reportSettings.setValue('last_three_days', 'selectedDateType')

      const viewerLinkedRecord = store.getRoot().getLinkedRecord('viewer')
      const reportsLinkedRecord =
        viewerLinkedRecord && viewerLinkedRecord.getLinkedRecord('reports')

      if (reportsLinkedRecord)
        reportsLinkedRecord.setLinkedRecord(reportSettings, 'settings')
    })
    if (environment.options)
      environment.retain({
        dataID: 'reportSettings',
        node: { selections: [] },
        variables: {},
      })
  }

  render() {
    const { viewer: { reports, currentUser } } = this.props
    const { available_tenants } = reports

    if (!reports.settings) {
      return 'Working...'
    }

    const reportView = this.props.match.params.report_view

    const settings = { ...reports.settings, report_view: reportView }
    let userRoles = []

    if (
      currentUser &&
      currentUser.tenant &&
      currentUser.tenant.multi_roles_enabled &&
      currentUser.roles &&
      currentUser.roles.nodes
    ) {
      userRoles = currentUser.roles.nodes
        .filter(
          v => v && currentUser.tenant && v.tenant_id === currentUser.tenant.id,
        )
        .map(v => v && v.role_type)
        .filter((v, i, a) => a.indexOf(v) === i)
    }

    return (
      <ServiceMetricsPageWrapper
        userRoles={userRoles}
        reportView={reportView}
        actions={
          <ReportSettingsTenantFetchLoader
            settings={settings}
            reportView={reportView}
            tenants={available_tenants}
          />
        }
      >
        <SafetyReport title="Safety" variables={settings} navHash="#safety" />

        <AccessibilityEquityReport
          dimensions={reports.accessibility_breakdown_dimensions}
          title="Accessibility and Equity"
          variables={settings}
          navHash="#accessibility_equity"
        />

        <SatisfactionAcceptabilityReport
          dimensions={reports.satisfaction_individual_sign_in_dimensions}
          title="Satisfaction and Acceptability"
          variables={settings}
          navHash="#satisfaction_acceptability"
        />

        <WorkforceReport
          title="Workforce"
          variables={settings}
          navHash="#workforce"
        />

        <EfficiencyExpenditureCostReport
          title="Efficiency, Expenditure, and Cost"
          variables={settings}
          navHash="#efficiency_expenditure_cost"
        />

        <EffectivenessOutcomesReport
          title="Effectiveness and Outcomes"
          variables={settings}
          navHash="#effectiveness_outcomes"
        />

        <ContinuityCoordinationReport
          title="Continuity and Coordination"
          variables={settings}
          navHash="#continuity_coordination"
        />
      </ServiceMetricsPageWrapper>
    )
  }
}

export const ServiceMetricsHomePageQuery = graphql`
  query ServiceMetricsHomePageQuery {
    viewer {
      ...ServiceMetricsHomePage_viewer
    }
  }
`

export const ServiceMetricsHomePageLoader = createFragmentContainer(
  ServiceMetricsHomePage,
  {
    viewer: graphql`
      fragment ServiceMetricsHomePage_viewer on Viewer {
        reports {
          id
          available_tenants {
            id
            name
          }
          satisfaction_individual_sign_in_dimensions {
            key
            value
          }
          accessibility_breakdown_dimensions {
            key
            value
          }
          settings {
            ...ReportSettingsTenantFetch_settings
            from
            to
            tenants
            clinicians
            selectedDateType
          }
        }
        currentUser {
          tenant {
            id
            multi_roles_enabled
          }
          roles {
            totalCount
            nodes {
              ... on RoleInterface {
                tenant_id
                id
                role_type
              }
            }
          }
        }
      }
    `,
  },
)
