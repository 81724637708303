// @flow

import React from 'react'
import { useFela } from 'react-fela'
import cx from 'classnames'

import Button, { ButtonGroup } from 'react-ui/components/Button'
import createComponentId from 'shared/services/id'
import { phoneLink } from 'shared/services/phoneLink'
import { smsLink } from 'shared/services/smsLink'
import { NewWindow, Phone } from 'shared/ui/Typography/Icons'
import { Heading } from 'care-ui'

import s from './HelpProviderCard.scss'

type ProviderType = {
  ageRange?: string,
  chat_url: string,
  description: string,
  hours: string,
  logo_url: string,
  phone_number: string,
  slug?: string,
  sms: string,
  title: string,
  url: string,
}

type PropsType = {
  componentId?: string,
  mini?: boolean,
  provider: ProviderType,
}

type ChatButtonPropsType = {
  chatUrl: string,
  mini?: boolean,
}

const defaultId = createComponentId(__filename)

const defaultOpeningHours = '24 hours a day, 7 days a week'

const headingStyle = ({ theme }) => ({
  margin: `${theme.spacing.sm} 0`,
})

const contentStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
  marginBottom: theme.care.spacing.sm,
  '& dt': {
    fontWeight: 600,
    width: '30%',
  },

  '& dd': {
    width: '70%',
  },
})

const typographyStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

const renderedAgeRange = (ageRange?: string) => {
  if (ageRange) {
    return (
      <div>
        <dt>Age range</dt>
        <dd>{ageRange}</dd>
      </div>
    )
  }
  return null
}

const RenderedCardBody = ({
  ageRange,
  description,
  hours,
  title,
}: ProviderType) => {
  const { css } = useFela()

  return (
    <div className={s.cardBody}>
      <Heading level={3} extend={headingStyle}>
        {title}
      </Heading>
      <dl className={s.providerDetail}>
        <div className={css(contentStyle)}>
          <dt>Description</dt>
          <dd dangerouslySetInnerHTML={{ __html: description }} />
        </div>

        {renderedAgeRange(ageRange)}

        <div className={css(contentStyle)}>
          <dt>Phone hours</dt>
          <dd> {hours || defaultOpeningHours}</dd>
        </div>
      </dl>
    </div>
  )
}
const RenderedChatButton = ({ mini, chatUrl }: ChatButtonPropsType) => {
  const { css } = useFela()
  if (!mini && chatUrl) {
    return (
      <Button
        className={cx(s.insetButton, css(typographyStyle))}
        href={chatUrl}
        target="_blank"
      >
        <NewWindow className={s.icon} color="primary" size="m" /> Chat
      </Button>
    )
  }

  return null
}

const miniClass = (isMini?: boolean) => {
  if (isMini) {
    return s.mini
  }

  return null
}

const HelpProviderCard = ({
  componentId = defaultId,
  provider,
  mini,
}: PropsType) => {
  const { css } = useFela()

  return (
    <div
      className={cx(
        s.cardContainer,
        componentId,
        `${componentId}-${provider.slug || ''}`,
        miniClass(mini),
      )}
    >
      <div className={s.cardInner}>
        <div className={s.infographic}>
          <img className={s.image} src={provider.logo_url} alt="" />
        </div>
        {!mini ? <RenderedCardBody {...provider} /> : null}
      </div>

      <ButtonGroup
        className={s.actionButtons}
        extend={{ ButtonGroup: { marginLeft: 0, marginRight: 0 } }}
      >
        {!!provider.url && (
          <Button
            className={cx(s.insetButton, css(typographyStyle))}
            href={provider.url}
            target="_blank"
          >
            <NewWindow className={s.icon} color="primary" size="m" /> Website
          </Button>
        )}

        {!provider.url && (
          <>
            <div className={s.buttonSpacer} />
          </>
        )}

        <RenderedChatButton mini={mini} chatUrl={provider.chat_url} />

        {!!provider.phone_number && (
          <Button
            className={cx(s.insetButton, css(typographyStyle))}
            href={phoneLink(provider.phone_number)}
          >
            <Phone className={s.icon} color="primary" kind="phone" size="m" />{' '}
            Call {provider.phone_number}
          </Button>
        )}
        {!!provider.sms && (
          <Button
            className={cx(s.insetButton, css(typographyStyle))}
            href={smsLink(provider.sms)}
          >
            <Phone className={s.icon} color="primary" kind="phone" size="m" />{' '}
            Text {provider.sms}
          </Button>
        )}
      </ButtonGroup>
    </div>
  )
}

export default HelpProviderCard
