// @flow

import React, { useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import Modal from 'react-ui/components/Modal'
import { Button } from 'care-ui'

import IndividualTasksItem from './IndividualTasksItem'

import type { StaffDownloadDataTask_individual } from './__generated__/StaffDownloadDataTask_individual.graphql'

type PropsType = {
  individual: StaffDownloadDataTask_individual,
}
const StaffDownloadDataTaskComponent = ({ individual }: PropsType) => {
  const { user } = individual
  const [emrModalOpen, setEmrModalOpen] = useState(false)

  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?user_id=${user.id}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
  }

  return (
    <>
      <IndividualTasksItem
        header="Health history PDF"
        onClick={() => setEmrModalOpen(true)}
      >
        Download a PDF of their health history.
      </IndividualTasksItem>
      <Modal
        componentId="EmrDownloadModal"
        contentLabel="Results download - security warning"
        isOpen={emrModalOpen}
        onRequestClose={() => setEmrModalOpen(false)}
        showCross
        showClose={false}
        shrinkwrap
      >
        <p>
          Please ensure you take appropriate steps to protect the privacy of
          this document in accordance with the clinical governance requirements
          of your service.
        </p>
        <Button
          dataTestId="emrDownloadButton"
          onClick={handleClick}
          variant="primary"
        >
          Continue
        </Button>
      </Modal>
    </>
  )
}

export default createFragmentContainer(StaffDownloadDataTaskComponent, {
  individual: graphql`
    fragment StaffDownloadDataTask_individual on IndividualRole {
      user {
        id
      }
    }
  `,
})
