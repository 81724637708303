/* eslint-disable simple-import-sort/sort */
// @flow

// Score Card Pages
import currentResultCardForumulaTranslations from 'platform_web/pages/Users/ScoreCards/components/CurrentResultCardFormula/localizedStrings.json'
import currentResultCardTranslations from 'platform_web/pages/Users/ScoreCards/components/CurrentResultCard/localizedStrings.json'
import scoreCardsCareOptionsTranslations from 'platform_web/pages/Users/ScoreCards/components/ScoreCardsCareOptions/localizedStrings.json'
import scoreCardsChangeLogTranslations from 'platform_web/pages/Users/ScoreCards/components/ScoreCardsChangeLog/localizedStrings.json'
import scoreCardsNotificationsTranslations from 'platform_web/pages/Users/ScoreCards/components/ScoreCardsNotifications/localizedStrings.json'
import scoreCardsPreviousAnswersTranslations from 'platform_web/pages/Users/ScoreCards/components/ScoreCardsPreviousAnswers/localizedStrings.json'

const scoreCardTranslations = {
  en: {
    currentResultCardForumulaPage: currentResultCardForumulaTranslations.en,
    currentResultCardPage: currentResultCardTranslations.en,
    scoreCardsCareOptionsPage: scoreCardsCareOptionsTranslations.en,
    scoreCardsChangeLogPage: scoreCardsChangeLogTranslations.en,
    scoreCardsNotificationsPage: scoreCardsNotificationsTranslations.en,
    scoreCardsPreviousAnswersPage: scoreCardsPreviousAnswersTranslations.en,
  },
  fr: {
    currentResultCardForumulaPage: currentResultCardForumulaTranslations.fr,
    currentResultCardPage: currentResultCardTranslations.fr,
    scoreCardsCareOptionsPage: scoreCardsCareOptionsTranslations.fr,
    scoreCardsChangeLogPage: scoreCardsChangeLogTranslations.fr,
    scoreCardsNotificationsPage: scoreCardsNotificationsTranslations.fr,
    scoreCardsPreviousAnswersPage: scoreCardsPreviousAnswersTranslations.fr,
  },
}

export default scoreCardTranslations
