// @flow

import { useState } from 'react'
import { useRouter } from 'found'

import flagAustralia from 'care-ui/atoms/assets/AU.png'
import flagCanada from 'care-ui/atoms/assets/CA.png'

const SUPPORTED_REGIONS = [
  { value: 'au', label: 'Australia', icon: flagAustralia },
  { value: 'ca', label: 'Canada', icon: flagCanada },
]

const getDefaultRegion = (regionInUrl: string) => {
  const defaultRegion = SUPPORTED_REGIONS.find(
    region => region.value === regionInUrl,
  )

  return defaultRegion || SUPPORTED_REGIONS[0]
}

const useRegionSelector = () => {
  // Get default region from URL
  const hostName = window.location.hostname
  const [regionInUrl, ...restUrl] = hostName.split('.')
  const defaultRegion = getDefaultRegion(regionInUrl)

  // Get the current path from url
  const { match: { location } } = useRouter()
  const currentPath = location?.pathname || ''

  const [selectedRegion, setSelectedRegion] = useState(defaultRegion)

  const updateRegion = (region: { [string]: string | any }, email?: string) => {
    setSelectedRegion(region)

    // Return in the local dev environment
    if (window.GLOBALS.appEnvironment === 'development') return

    // Construct the new URL based on the selected region
    const encodedUrlParams = email ? `?email=${encodeURIComponent(email)}` : ''

    const newUrl = `https://${region.value}.${restUrl.join(
      '.',
    )}${currentPath}${encodedUrlParams}`

    // URL redirect
    window.location.href = newUrl
  }

  return {
    supportedRegions: SUPPORTED_REGIONS,
    selectedRegion,
    updateRegion,
  }
}

export default useRegionSelector
