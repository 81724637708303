// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import Link from 'found/Link'
import { get } from 'lodash/fp'

import Menu, {
  MenuItem as MenuItemActual,
  MenuItemGroup,
  MenuWithSubItems,
} from 'react-ui/components/Menu'
import {
  NavigationContext,
  RoleCard,
  TenantCard,
  TenantWithRolesCard,
} from 'react-ui/components/Page/Navigation'
import UserCard from 'react-ui/components/UserCard'
import Can from 'react-ui/utils/Can'
import logo from 'shared/ui/Styles/images/innowell_icon.png'
import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { Badge } from 'care-ui'

import { type NavigationProvider_user } from './__generated__/NavigationProvider_user.graphql'

type RoleType = {
  +id: string,
  +logoUrl?: string,
  +parentType?: string,
  +tenantGroupId: string,
  +tenantOrGroupName: string,
  +title: string,
}

type PropsType = {
  children: React.Node,
  currentRole: RoleType,
  roles: $ReadOnlyArray<RoleType>,
  rolesCount: number,
  +user: NavigationProvider_user,
}

const MenuItem = props => <MenuItemActual as={Link} {...(props: any)} />

function isTenant(parent) {
  if (!parent) return false
  return parent.__typename === 'Tenant'
}

function isTenantGroup(parent) {
  if (!parent) return false
  return parent.__typename === 'TenantGroup'
}

function fetchTenantRoles(tenantId, roles) {
  return roles.filter(r => r && r.tenantGroupId === tenantId)
}

function filterTenants(tenants, roles, currentTenant) {
  return tenants.filter(
    t =>
      t &&
      ((t.multi_roles_enabled && currentTenant && t.id !== currentTenant.id) ||
        (!t.multi_roles_enabled && fetchTenantRoles(t.id, roles).length > 0)),
  )
}

function fetchNoTenantRoles(roles) {
  return roles.filter(r => r && r.parentType !== 'Tenant')
}

function renderSwitchTenant(
  tenant,
  roles,
  currentRole,
  currentTenant,
  isUserMultiRoleWithSpecificRoles,
) {
  if (tenant.multi_roles_enabled && isUserMultiRoleWithSpecificRoles) {
    if (
      !currentTenant ||
      currentTenant.id !== tenant.id ||
      !isUserMultiRoleWithSpecificRoles
    ) {
      return (
        <MenuItem
          key={tenant.id}
          label={
            <TenantCard
              data-component-id={`TenantSwitcher-${tenant.id || ''}`}
              key={tenant.id}
              logoUrl={tenant.logo_display_url}
              tenantName={tenant.name}
            />
          }
          to={{
            name: 'changeTenant',
            params: { tenantId: tenant.id },
          }}
        />
      )
    }
  } else {
    const availableRoles = fetchTenantRoles(tenant.id, roles)
    if (availableRoles.length > 0) {
      return (
        <MenuWithSubItems
          key={tenant.id}
          label={
            <TenantWithRolesCard
              data-component-id={`TenantSwitcher-${tenant.id || ''}`}
              key={tenant.id}
              logoUrl={tenant.logo_display_url}
              tenantName={tenant.name}
              availableRoles={availableRoles}
            />
          }
        />
      )
    }
  }

  return null
}

const NavigationProvider = ({
  children,
  roles,
  user,
  user: { current_role: { parent } },
}: PropsType) => {
  const {
    hasIndividualRole,
    hasSupportPersonRole,
    isMultiRolesEnabled,
  } = useCurrentUserRoles()

  return (
    <Can>
      {policies => {
        const canSeeIndividuals =
          policies.CAN_VIEW_LEAD_CLINICIAN_LINKS ||
          policies.CAN_VIEW_CLINICIAN_LINKS ||
          policies.CAN_LIST_TENANT_INDIVIDUALS
        const userCard = (
          <UserCard link={false} size="large" user={user} noAvatar />
        )

        // Feature Toggle
        const displayNewInsights = window.GLOBALS?.features?.NEW_INSIGHTS

        const isUserMultiRoleWithSpecificRoles =
          hasIndividualRole && hasSupportPersonRole && isMultiRolesEnabled

        return (
          <NavigationContext.Provider
            value={{
              menu: (
                <Menu>
                  {policies.CAN_VIEW_INDIVIDUAL_LINKS && (
                    <MenuItem label="Dashboard" to="dashboard" />
                  )}
                  {canSeeIndividuals && (
                    <MenuItem label="Individuals" to="staff_individuals" />
                  )}
                  {policies.CAN_VIEW_LEAD_CLINICIAN_LINKS && (
                    <MenuItem label="Clinicians" to="staff_clinicians" />
                  )}
                  {policies.CAN_LIST_TENANT_EMPLOYEES &&
                    isTenant(parent) && (
                      <MenuItem label="Employees" to="staff_employees" />
                    )}
                  {policies.CAN_VIEW_NOTIFICATIONS && (
                    <MenuItem label="Notifications" to="staff_notifications" />
                  )}
                  {policies.CAN_VIEW_INSIGHTS && (
                    <MenuItem label="Insights" to="insights" />
                  )}

                  {policies.CAN_VIEW_INSIGHTS &&
                    displayNewInsights && (
                      <MenuItem
                        label={
                          <div>
                            Insights-New <Badge variant="beta" label="BETA" />
                          </div>
                        }
                        to="insights_new"
                      />
                    )}

                  {policies.CAN_LIST_TENANT_MANAGERS &&
                    isTenantGroup(parent) && (
                      <MenuItem label="Managers" to="staff_managers" />
                    )}
                  <MenuItem
                    label="Profile Settings"
                    to="/settings/personal_details"
                  />
                  <Menu label="Resources" data-component-id="Support">
                    <MenuItem
                      label="FAQs"
                      to="resources_frequently_asked_questions"
                    />
                    {policies.CAN_CREATE_FEEDBACKS && (
                      <MenuItem label="Contact us" to="contact_us" />
                    )}
                  </Menu>
                  <Menu label="About Innowell">
                    <MenuItem label="Acknowledgements" to="acknowledgements" />
                    <MenuItem
                      label="Privacy Policy"
                      href={user.current_role.parent?.privacyUrl}
                      external
                    />
                    <MenuItem
                      label="Terms and Conditions"
                      href={user.current_role.parent?.tncUrl}
                      external
                    />
                  </Menu>
                  {policies.CAN_VIEW_CONFIGURATION_SPACE && (
                    <MenuItem
                      as="button"
                      label="Active Admin"
                      onClick={evt => {
                        evt.preventDefault()
                        window.location.assign('/active_admin')
                      }}
                    />
                  )}
                  <MenuItemGroup label="" data-component-id="currentTenant">
                    <MenuWithSubItems
                      key={
                        user.current_role.parent
                          ? user.current_role.parent.id
                          : 'NO_PARENT'
                      }
                      label={
                        <TenantWithRolesCard
                          data-component-id={`TenantSwitcher-${
                            user.current_role.parent &&
                            user.current_role.parent.id
                              ? user.current_role.parent.id
                              : 'NO_PARENT'
                          }`}
                          key={
                            user.current_role.parent
                              ? user.current_role.parent.id
                              : 'NO_PARENT'
                          }
                          logoUrl={
                            user.current_role.parent
                              ? user.current_role.parent.logo_display_url
                              : logo
                          }
                          tenantName={
                            user.current_role.parent
                              ? user.current_role.parent.name
                              : null
                          }
                          noLink
                          availableRoles={
                            user.tenant &&
                            user.tenant.multi_roles_enabled &&
                            !isUserMultiRoleWithSpecificRoles
                              ? []
                              : [user.current_role]
                          }
                        />
                      }
                    />
                  </MenuItemGroup>

                  {(filterTenants(
                    user.current_tenants || [],
                    roles,
                    user.tenant,
                  ).length > 0 ||
                    fetchNoTenantRoles(roles).length > 0 ||
                    isUserMultiRoleWithSpecificRoles) && (
                    <MenuItemGroup
                      label="Switch To"
                      data-component-id="tenantSwitcherList"
                    >
                      {(user.current_tenants || []).map(tenant =>
                        renderSwitchTenant(
                          tenant,
                          roles,
                          user.tenant,
                          isUserMultiRoleWithSpecificRoles,
                        ),
                      )}

                      {fetchNoTenantRoles(roles).map(role => (
                        <MenuItem
                          key={role.id}
                          label={
                            <RoleCard
                              data-component-id={`RoleSwitcher-${role.id ||
                                ''}`}
                              key={role.id}
                              roleName={role.title}
                              tenantName={role.tenantOrGroupName}
                              logoUrl={role.logoUrl}
                            />
                          }
                          to={{
                            name: 'changeRole',
                            params: { roleId: role.id },
                          }}
                        />
                      ))}
                    </MenuItemGroup>
                  )}

                  <MenuItem
                    label="Sign out"
                    extend={() => ({
                      // override for sign out only
                      fontWeight: 700,
                    })}
                    to="signingOut"
                  />
                </Menu>
              ),
              userCard,
            }}
          >
            {children}
          </NavigationContext.Provider>
        )
      }}
    </Can>
  )
}

function mapRoleToProps(role) {
  return {
    id: role.id,
    logoUrl: get('parent.logo_display_url')(role),
    title: role.title,
    tenantOrGroupName: get('parent.name')(role),
    tenantGroupId: get('parent.id')(role),
    parentType: get('parent.__typename')(role),
  }
}

const NavigationProviderLoader = ({ user, children, ...props }) => {
  const currentRole = mapRoleToProps(user.current_role)
  const roles = user.roles.nodes
    ? user.roles.nodes
        .filter(({ id }) => id !== user.current_role.id)
        .map(role => mapRoleToProps(role))
    : []
  const rolesCount = user.roles.totalCount

  return (
    <NavigationProvider
      {...props}
      {...{ currentRole, roles, rolesCount, user }}
    >
      {children}
    </NavigationProvider>
  )
}

export default createFragmentContainer(NavigationProviderLoader, {
  user: graphql`
    fragment NavigationProvider_user on User {
      ...UserCardLoader_user
      current_role {
        ... on RoleInterface {
          id
          removed
          tenant_id
          parent {
            __typename
            ... on Tenant {
              logo_display_url
              privacyUrl
              tncUrl
            }
            ... on TenantGroup {
              privacyUrl
              tncUrl
            }
            ... on RoleParentInterface {
              name
              id
            }
          }
          title
        }
      }
      tenant {
        __typename
        ... on Tenant {
          logo_display_url
          id
          name
          multi_roles_enabled
        }
        ... on RoleParentInterface {
          name
          id
        }
      }
      current_tenants {
        __typename
        ... on Tenant {
          logo_display_url
          id
          name
          multi_roles_enabled
        }
        ... on RoleParentInterface {
          name
          id
        }
      }
      roles {
        totalCount
        nodes {
          ... on RoleInterface {
            id
            removed
            tenant_id
            parent {
              __typename
              ... on Tenant {
                logo_display_url
                multi_roles_enabled
              }
              ... on RoleParentInterface {
                name
                id
              }
            }
            title
          }
        }
      }
    }
  `,
})
