// @flow

// Root Platform Pages
import userNotificationsTranslations from 'platform_web/components/UserNotifications/localizedStrings.json'

const rootPlatformTranslations = {
  en: {
    userNotificationsComponent: userNotificationsTranslations.en,
  },
  fr: {
    userNotificationsComponent: userNotificationsTranslations.fr,
  },
}

export default rootPlatformTranslations
