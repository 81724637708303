// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'

import Markdown from 'react-ui/components/Markdown'
import { BasicTable } from 'shared/ui/Tables'

import type { StratifiedScoringResultAnswerTable_answers } from './__generated__/StratifiedScoringResultAnswerTable_answers.graphql'

type PropsType = {
  answers: ?StratifiedScoringResultAnswerTable_answers,
}

const headerStyle = () => ({
  width: '12rem !important',
})

const numberStyle = () => ({
  width: '1rem',
})
const StratifiedScoringResultAnswerTable = (props: PropsType) => {
  const { answers } = props
  const { css } = useFela()

  return (
    <BasicTable
      data={answers}
      renderHeaders={() => (
        <tr>
          <th colSpan={2}>Question</th>
          <th className={css(headerStyle)}>Answer</th>
        </tr>
      )}
    >
      {(answer, k) => (
        <tr key={answer.id}>
          <td className={css(numberStyle)}>{k + 1}</td>
          <td><Markdown source={answer.question.label} /></td>
          <td><Markdown source={answer.display_value} /></td>
        </tr>
      )}
    </BasicTable>
  )
}

export const StratifiedScoringResultAnswerTableLoader = createFragmentContainer(
  StratifiedScoringResultAnswerTable,
  {
    answers: graphql`
      fragment StratifiedScoringResultAnswerTable_answers on AnswerInterface
        @relay(plural: true) {
        id
        display_value
        question {
          label
        }
      }
    `,
  },
)
