// @flow

import * as React from 'react'
import { graphql } from 'react-relay'

import {
  HeroTableWithPercent,
  HeroTableWithTime,
} from 'react-ui/components/HeroTable'
import ReportCard from 'react-ui/components/ReportCard'

import ReportsView from './ReportsView'
import ReportWithSettings from './ReportWithSettings'

type PropsType = {
  print?: boolean,
  title: React.Node,
  variables: Object,
}

const query = graphql`
  query EfficiencyExpenditureCostReportQuery(
    $from: DateTime!
    $to: DateTime!
    $tenants: [ID!]!
    $clinicians: [ID!]!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        efficiency_initial_assessment(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        efficiency_initial_assessment_completion(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        efficiency_invite_to_initial_assessment_completion(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        efficiency_invite_to_initial_care_option_action(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
      }
    }
  }
`

const EfficiencyExpenditureCostReport = ({
  print,
  title,
  variables,
}: PropsType) => (
  <ReportsView print={print} title={title}>
    <ReportWithSettings query={query} variables={variables}>
      {data => {
        const {
          efficiency_initial_assessment,
          efficiency_initial_assessment_completion,
          efficiency_invite_to_initial_assessment_completion,
          efficiency_invite_to_initial_care_option_action,
        } = data.viewer.reports

        return (
          <ReportCard title="Initial questionnaire completions">
            <div className="EfficiencyInitialAssessmentChart">
              <ReportCard.SubHeading>
                Average time to complete the initial questionnaire
              </ReportCard.SubHeading>
              <HeroTableWithTime
                traces={efficiency_initial_assessment.traces}
              />
            </div>

            <ReportCard.Divider />

            <div className="EfficiencyInviteToInitialAssessmentCompletionChart">
              <ReportCard.SubHeading>
                Average time from Platform invitation to initial questionnaire
                completion
              </ReportCard.SubHeading>
              <HeroTableWithTime
                traces={
                  efficiency_invite_to_initial_assessment_completion.traces
                }
              />
            </div>

            <ReportCard.Divider />

            <div className="EfficiencyInitialAssessmentCompletionChart">
              <ReportCard.SubHeading>
                Initial questionnaire completions
              </ReportCard.SubHeading>
              <HeroTableWithPercent
                traces={efficiency_initial_assessment_completion.traces}
              />
            </div>

            <ReportCard.Divider />

            <div className="EfficiencyInviteToInitialCareOptionActionChart">
              <ReportCard.SubHeading>
                Average time from Platform invitation to individual working on a
                care option
              </ReportCard.SubHeading>
              <HeroTableWithTime
                traces={efficiency_invite_to_initial_care_option_action.traces}
              />
            </div>
          </ReportCard>
        )
      }}
    </ReportWithSettings>
  </ReportsView>
)

export default EfficiencyExpenditureCostReport
