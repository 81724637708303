// @flow

import React from 'react'

import Heading from 'react-ui/components/Heading'
import { Button } from 'care-ui'

import { type BuilderPropsType } from '../QuestionnaireComplete'

export const Clinician = ({
  user,
  onSubmit,
  router,
  submitting,
}: BuilderPropsType) => ({
  title: 'Thank you',
  cta: (
    <center>
      <p>Click submit to enter your clinical input</p>

      <div>
        <Button
          disabled={submitting}
          onClick={onSubmit({
            onCompleted: () => {
              router.push({
                name: 'user_dashboard',
                params: {
                  id: user.id,
                },
              })
            },
          })}
          variant="secondary"
          ariaLabel={submitting ? 'Submitting...' : 'Submit'}
          dataTestId="submit"
        >
          {submitting ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </center>
  ),
  text: (
    <>
      <div>
        <Heading level={3}>Your Input</Heading>
        <small>
          Routinely update your responses to keep your clinical input current in
          this individual’s dashboard. It will help you monitor progress over
          time and support clinical decision making.
        </small>
      </div>

      <div>
        <Heading level={3}>View Input</Heading>
        <small>
          Your answers can be viewed by yourself and other connected Innowell
          clinicians.
        </small>
      </div>
    </>
  ),
})
