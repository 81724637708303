// @flow

/**
 * Questionnaire completed page for an individual
 */

import React from 'react'

import { OnboardingHeader } from 'components/OnboardingHeader'
import { QuestionSetsList } from 'components/QuestionnaireComplete'
import { initialQuestionnaireSubmit } from 'platform_web/services/analytics/events'

import type { BuilderPropsType } from '../QuestionnaireComplete'

const submitButton = ({
  router,
  onSubmit,
  submitting,
}: {
  onSubmit: Function,
  router: { push: Function },
  submitting: boolean,
}) => ({
  disabled: submitting,
  onClick: onSubmit({
    onCompleted: () => {
      router.push({ name: 'dashboard' })
      initialQuestionnaireSubmit()
    },
  }),
  label: submitting ? 'Submitting...' : 'Submit',
})

export const Individual = ({
  user,
  userQuestionnaire,
  router,
  onSubmit,
  submitting,
  setSubmitting,
}: BuilderPropsType) => {
  const name = user.preferred_name || user.name

  const { completeness } = userQuestionnaire
  const percent = completeness.percent ? completeness.percent : 0
  const completedList = userQuestionnaire.completedQuestionSets
  const skippedList = userQuestionnaire.skippedQuestionSets
  const hasCompletedList = completedList && completedList.length > 0
  const hasSkippedList = skippedList && skippedList.length > 0

  const incompleteTitle = `Well done ${name}. You're almost done.`
  const incompleteMessage = (
    <>
      <p>{`${percent}% of the initial questionnaire completed.`}</p>
      <p>
        You can still submit your answers and return to the skipped/incomplete
        questions anytime or if you prefer you can choose to do them with a
        support person or your clinician
      </p>
    </>
  )

  const completeTitle = `Well done ${name}.`
  const completeMessage = (
    <>
      <p>
        Your dashboard has sorted your results into health cards so you can
        focus on what’s important and explore a range of care options that help
        you meet your health goals.
      </p>
      <p>
        You can discuss your results with your clinician. Your clinician will
        work with you through the dashboard to tailor treatment to your needs.
      </p>
    </>
  )

  const commonButtonProps = {
    router,
    onSubmit,
    submitting,
    setSubmitting,
  }

  return {
    header: (
      <OnboardingHeader
        title={hasSkippedList ? incompleteTitle : completeTitle}
        infoText={hasSkippedList ? incompleteMessage : completeMessage}
        button={submitButton(commonButtonProps)}
      />
    ),
    completedList: hasCompletedList && (
      <QuestionSetsList
        list={completedList}
        title="Completed Sections"
        user_questionnaire={userQuestionnaire}
        completed
      />
    ),
    skippedList: hasSkippedList && (
      <QuestionSetsList
        list={skippedList}
        title="Skipped and Incomplete Sections"
        user_questionnaire={userQuestionnaire}
      />
    ),
  }
}
