// @flow

import * as React from 'react'
import { isArray } from 'lodash/fp'

import { Error } from 'shared/ui/Typography/Icons'

import s from './Error.scss'

type PropsType = {
  errors: Array<*>,
}

export const ErrorListTemplate = (props: PropsType) => {
  const { errors } = props
  const size = errors.length
  const message = isArray(errors)
    ? `${size} answer${size > 1 ? 's' : ''} need${
        size > 1 ? '' : 's'
      } completing`
    : errors
  return (
    <div className={s.errors}>
      <div className={s.icon}>
        <Error size="l" color="danger" />
      </div>
      <div className={s.content}>{message}</div>
    </div>
  )
}
