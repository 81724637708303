// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createFragmentContainer } from 'react-relay'
import { find } from 'lodash/fp'

import SimpleHeading from 'react-ui/components/Heading/SimpleHeading'
import useChartToggle from 'platform_web/hooks/useChartToggle'
import useRouteParams from 'platform_web/hooks/useRouteParams'
import { possibleTabs } from 'platform_web/pages/UserDashboardPage'
import { Button } from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'

import CommonTasksButtons from '../CommonTasksButtons'

import { chartHeaderQuery } from './query/ChartHeader'

import type { ChartHeader_individual } from './query/__generated__/ChartHeader_individual.graphql'
import type { ChartHeader_user } from './query/__generated__/ChartHeader_user.graphql'

type PropsType = {
  individual: ChartHeader_individual,
  user: ?ChartHeader_user,
}

const buttonWrapperStyle = () => ({
  maxWidth: 'fit-content',
})

const headerStyle = ({ theme }) => ({
  fontSize: '2rem',
  color: theme.palette.summaryGraphs.textColor,
})

const ChartHeader = ({ individual, user }: PropsType) => {
  const { isSummaryGauge, isSummaryGraph } = useChartToggle()
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])
  const { id, currentRouteName } = useRouteParams()
  const { css } = useFela()

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'chartHeaderComponent',
  })

  if (isSummaryGauge && !isIndividual) return null

  const dashboardActive =
    currentRouteName === 'user_dashboard' || currentRouteName === 'dashboard'

  const allTabs = possibleTabs(translation)
  const currentRoute = find(['routeName', currentRouteName])(allTabs)
  const graphHeader = dashboardActive && isSummaryGraph

  return (
    <>
      {!dashboardActive &&
        id && (
          <div className={css(buttonWrapperStyle)}>
            <Button
              to={{
                name: 'user_dashboard',
                params: {
                  id,
                },
              }}
              variant="text"
              ariaLabel={translation('backToDashboard')}
              dataTestId="back-to-dashboard"
              leftIcon={ChevronLeft}
            >
              {translation('backToDashboard')}
            </Button>
          </div>
        )}

      <SimpleHeading
        extend={graphHeader && headerStyle}
        text={currentRoute.label}
        button={
          isSummaryGraph && (
            <CommonTasksButtons individual={individual} user={user} />
          )
        }
        spread
      />
    </>
  )
}

export default createFragmentContainer(ChartHeader, chartHeaderQuery)
