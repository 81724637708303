// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'found/Link'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import { Layout as l } from 'shared/ui/Styles'

const Page = () => {
  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'researchDataSharingPage',
  })

  return (
    <Section color="white">
      <Container>
        <Heading level={2}>{translation('researchDataSharingHeading')}</Heading>

        <p>{translation('lastModified')}</p>

        <div className={l.bulkTextBlock}>
          <p>{translation('thankYouMessage')}</p>

          <p>{translation('personalInformationMessage')}</p>

          <p>{translation('personalInformationDescription')}</p>

          <p>{translation('contributingMessage')}</p>

          <p>
            {translation('forMoreInformation')}
            <br />
            <Link to={privacyPolicyUrl()}>
              https://www.innowell.org/privacy-policy/
            </Link>
          </p>
        </div>
      </Container>
    </Section>
  )
}
export default Page
