// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useLazyLoadQuery } from 'react-relay'
import { useRouter } from 'found'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import Section from 'react-ui/components/Section'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { SuspenseLoader } from 'care-ui'

import { listQuery } from '../../queries/SupportingSupporteeQuestionnaireSubmissionPage'
import SupportingSupporteeQuestionnaireSubmissionComponent from '../SupportingSupporteeQuestionnaireSubmissionComponent'

import { informationStyle, wrapperStyle } from './SupportingSupporteeQuestionnaireSubmissionPage.style'

const SupportingSupporteeQuestionnaireSubmissionPageLoader = () => {
  const { css } = useFela()

  const { match } = useRouter()
  const { params: { id: supporteeUserId } } = match
  const { id } = useCurrentUser()
  const { node } = useLazyLoadQuery(
    listQuery,
    { id, supporteeUserId, count: 10, cursor: null },
    { fetchPolicy: 'network-only' },
  )
  const { current_role, contract } = node || {}
  const { supportees: supporteesFragmentRef } = current_role || {}

  const { t: translation } = useTranslation('supporting', {
    keyPrefix: 'supportingPage.supportingSupporteeQuestionnaireSubmissionPage',
  })

  return (
    <div className={css(wrapperStyle)}>
      <Section center color="white">
        <Container>
          <Heading level={2} extend={informationStyle}>
            {translation('pageHeading')}
          </Heading>
        </Container>

        <Container>
          <SupportingSupporteeQuestionnaireSubmissionComponent
            user={node}
            supportees={supporteesFragmentRef}
            supporteeUserId={supporteeUserId}
          />
        </Container>
      </Section>

      <Section center color="white">
        <div className={css(informationStyle)}>
          <div>
            <Heading level={3}>{translation('whatElseHeading')}</Heading>

            <small>
              {translation('description')}&nbsp;
              {contract === 'aus' && (
                <>
                  <Link to="https://www.beyondblue.org.au/about-us/about-our-work/youthbeyondblue/the-check-in-app">
                    {translation('checkIn')}
                  </Link>&nbsp;{translation('or')}&nbsp;
                  <Link to="https://au.reachout.com/tools-and-apps/chats-for-life">
                    {translation('chatForLife')}
                  </Link>&nbsp;{translation('website')}&nbsp;
                </>
              )}
              {contract === 'ahs' && (
                <>
                  <Link to="https://kidshelpphone.ca/get-info/need-to-have-a-tough-conversation-with-someone-heres-how/">
                  {translation('kidsHelpPhone')}
                  </Link>&nbsp;{translation('or')}&nbsp;

                  <Link to="https://albertahealthservices.ca/amh/amh.aspx">
                    {translation('albertaHealthServices')}
                  </Link>&nbsp;{translation('website')}&nbsp;
                </>
              )}
              {contract === 'london' && (
                <>
                  <Link to="https://kidshelpphone.ca/get-info/need-to-have-a-tough-conversation-with-someone-heres-how/">
                    {translation('kidsHelpPhone')}
                  </Link>&nbsp;{translation('websiteOrContact')}&nbsp;

                  <Link to="mail:femapresearch@lhsc.on.ca">
                    femapresearch@lhsc.on.ca
                  </Link>{translation('callNumber')}&nbsp;
                </>
              )}
              {translation('toHelpWithConvo')}
            </small>
          </div>
          <div>
            <Heading level={3}>{translation('whatIsQuestionnaireHeading')}</Heading>

            <small>
              {translation('questionnaireDescription')}
            </small>
          </div>

          <div>
            <Heading level={3}>{translation('WhoSeeYourAnswers')}</Heading>
           
            <small>
              {translation('seeYourAnswerDescription')}
            </small>
          </div>

          <div>
            <Heading level={3}>
              {translation('oftenAnswerQuestionnaire')}
            </Heading>

            <small>
              {translation('oftenAnswerQuestionnaireDescription')}
            </small>
          </div>
        </div>
      </Section>
    </div>
  )
}

const SupportingSupporteeQuestionnaireSubmissionPage = () => (
  <SuspenseLoader>
    <SupportingSupporteeQuestionnaireSubmissionPageLoader />
  </SuspenseLoader>
)

export default SupportingSupporteeQuestionnaireSubmissionPage
