// @flow

import React from 'react'
import { connect } from 'react-fela'

import { Text } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {}

const styleRules = () => ({
  appVersion: {
    padding: '0 13px',
    margin: '0',
  },
})

const AppVersion = ({ styles }: PropsType) => (
  <Text size="sm" className={styles.appVersion}>
    Version {window.GLOBALS.appVersion}
  </Text>
)

export default connect(styleRules)(AppVersion)
