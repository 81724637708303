// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const styleRules = (props: StyleProps) => {
  const { theme } = props

  return {
    pullRight: {
      float: 'right',
    },
    input: {
      [theme.care.breakpoints.md]: {
        ...theme.care.typography.desktop.bodyLg,
      },
    },
  }
}
