// @flow

import React, { type Node } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { StatefullTabs } from 'react-ui/components/Tabs'
import Can from 'react-ui/utils/Can'

import GraphWithFilters from './GraphWithFilters'

import { type UserTrackableChartControls_formulaRanges } from './__generated__/UserTrackableChartControls_formulaRanges.graphql'
import { type UserTrackableChartControls_user } from './__generated__/UserTrackableChartControls_user.graphql'

type commonProps = {
  broadcastPointData?: () => any,
  children?: Node,
  formulaRanges: ?UserTrackableChartControls_formulaRanges,
}

type PropsType = commonProps & {
  user: UserTrackableChartControls_user,
}

const dataTabs = [
  {
    label: 'My Result',
    id: '#mood',
  },
  {
    label: 'Activity Result',
    id: '#activity',
  },
  {
    label: 'Sleep Result',
    id: '#sleep',
  },
]

const DEFAULT_TAB = '#mood'

export function buildGraphOptions(props: commonProps) {
  return (tabId?: string) => {
    return (
      <GraphWithFilters
        broadcastPointData={props.broadcastPointData}
        displayActivity={tabId === '#activity'}
        displaySleep={tabId === '#sleep'}
        {...(props: any)}
      />
    )
  }
}

export const UserTrackableChartControlsBase = ({
  broadcastPointData,
  children,
  formulaRanges,
  user: { fitbit: { connected: hasFitbit } },
}: PropsType) => {
  const initialTab = DEFAULT_TAB

  const renderChart = buildGraphOptions({
    broadcastPointData,
    formulaRanges,
    children,
  })

  return (
    <Can>
      {({ CAN_USE_FITBIT }) =>
        CAN_USE_FITBIT && hasFitbit ? (
          <StatefullTabs
            possibleTabs={dataTabs}
            initialTabId={initialTab}
            withAnchorTabs
          >
            {({ tabs, currentTabId }) => (
              <>
                {tabs}
                {renderChart(currentTabId)}
              </>
            )}
          </StatefullTabs>
        ) : (
          <>
            <div />
            {renderChart()}
          </>
        )
      }
    </Can>
  )
}

const withRelay = component =>
  createFragmentContainer(component, {
    user: graphql`
      fragment UserTrackableChartControls_user on User {
        fitbit {
          connected
        }
      }
    `,
    formulaRanges: graphql`
      fragment UserTrackableChartControls_formulaRanges on FormulaRange
        @relay(plural: true) {
        ...ScoreCardChartContext_formulaRanges
      }
    `,
  })

export const UserTrackableChartControls = withRelay(
  UserTrackableChartControlsBase,
)
