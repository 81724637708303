// @flow

// Settings Pages
import userNotificationPageTranslations from 'platform_web/pages/Settings/PersonalDetails/components/UserNotificationsPage/localizedStrings.json'
import userSettingsPageTranslations from 'platform_web/pages/Settings/PersonalDetails/components/UserSettingsPage/localizedStrings.json'
import userSettingsPersonalDetailsEditPageTranslations from 'platform_web/pages/Settings/PersonalDetails/components/UserSettingsPersonalDetailsEditPage/localizedStrings.json'
import userSettingsPersonalDetailsPageTranslations from 'platform_web/pages/Settings/PersonalDetails/components/UserSettingsPersonalDetailsPage/localizedStrings.json'
import userSettingsUserFieldPageTranslations from 'platform_web/pages/Settings/PersonalDetails/components/UserSettingsUserFieldPage/localizedStrings.json'

const settingsTranslations = {
  en: {
    userSettingsPage: userSettingsPageTranslations.en,
    userNotificationPage: userNotificationPageTranslations.en,
    userSettingsUserFieldPage: userSettingsUserFieldPageTranslations.en,
    userSettingsPersonalDetailsPage:
      userSettingsPersonalDetailsPageTranslations.en,
    userSettingsPersonalDetailsEditPage:
      userSettingsPersonalDetailsEditPageTranslations.en,
  },
  fr: {
    userSettingsPage: userSettingsPageTranslations.fr,
    userNotificationPage: userNotificationPageTranslations.fr,
    userSettingsUserFieldPage: userSettingsUserFieldPageTranslations.fr,
    userSettingsPersonalDetailsEditPage:
      userSettingsPersonalDetailsEditPageTranslations.fr,
    userSettingsPersonalDetailsPage:
      userSettingsPersonalDetailsPageTranslations.fr,
  },
}

export default settingsTranslations
