// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'

import { IndividualCliniciansListLoader } from 'components/IndividualCliniciansList'
import { ActionsDropDown, DropDownItem } from 'react-ui/components/DropDown'
import { Row } from 'react-ui/components/Grid'
import { H2 } from 'react-ui/components/Heading'
import EmrDisplay from 'react-ui/components/IndividualUserAdditionalInformationCard/EditEmrModal'
import Modal from 'react-ui/components/Modal'
import Section from 'react-ui/components/Section'
import { commit as commitDischarge } from 'mutations/Individual/Discharge'
import { commit as commitReintroduce } from 'mutations/Individual/Reintroduce'
import commitReinvite from 'mutations/Role/Reinvite'
import commitRemove from 'mutations/Role/Remove'
import { usePolicies } from 'platform_web/hooks/usePolicies'
import { Button } from 'care-ui'

import type { FelaPropsType, RoleType } from 'react-ui/typing'
import { type IndividualsTable_individual } from 'pages/Staff/Individuals/query/__generated__/IndividualsTable_individual.graphql'

type PropsType = FelaPropsType & {
  canBecomeActive: boolean,
  escalated: boolean,
  individual?: IndividualsTable_individual,
  individualId: string,
  legal_name?: string,
  roleId: string,
  roleType: RoleType,
  role_status: string,
  staff?: boolean,
  staffRoleExists: boolean,
}

type MutateType = any => void
type InputType = { id?: string, roleId?: string }

const StyleRules = ({ theme: { Small, Grid: { gutter } } }) => ({
  errorNotification: {
    ...Small,
    paddingLeft: gutter,
    paddingRight: gutter,
  },
  heading: {
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: gutter,
  },
  buttonGroups: {
    marginTop: `calc(${gutter} * 2)`,
    justifyContent: 'space-around',
    gap: '4px',
  },
})

const DischargeConfirmationModal = ({
  isOpen,
  legal_name,
  onConfirm,
  onToggleOpen,
  rules = {},
}) => {
  const handleOnDischarge = () => {
    onConfirm()
    onToggleOpen()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onToggleOpen}
      shrinkwrap
      showClose={false}
    >
      <Section color="white" short>
        <H2 extend={rules.heading}>Discharge</H2>
        <Row extend={rules.paragraph}>
          {legal_name || 'This individual'} has unresolved notifications.
        </Row>
        <Row extend={rules.paragraph}>
          Are you sure you want to discharge them?
        </Row>
        <Row extend={rules.buttonGroups}>
          <Button onClick={onToggleOpen} variant="text" type="reset">
            Cancel
          </Button>
          <Button onClick={handleOnDischarge} variant="primary">
            Continue to Discharge
          </Button>
        </Row>
      </Section>
    </Modal>
  )
}

const StaffIndividualsActions = (props: PropsType) => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    escalated,
    legal_name,
    roleId,
    roleType,
    individualId,
    role_status,
    canBecomeActive,
    staffRoleExists,
    rules,
    styles,
    staff,
    individual,
  } = props

  const onToggleOpen = () => setIsOpen(prev => !prev)

  const environment = useRelayEnvironment()
  const policies = usePolicies()
  const roleInput = { roleId }

  const canDoAdminActions = policies.CAN_PERFORM_ADMIN_ACTIONS_FOR_INDIVIDUALS

  const createClickHandler = (mutate: MutateType, input: InputType) => () => {
    mutate({
      environment,
      variables: { input },
    })
  }

  const errorNotification = (
    <p className={styles.errorNotification} key="errorNotification">
      This person is active at another service
    </p>
  )

  const staffExistErrorNotification = (
    <p className={styles.errorNotification} key="staffErrorNotification">
      This person is already a staff member
    </p>
  )

  const adminActions = () => {
    const actions = []

    if (staff && ['Active', 'Invited'].includes(role_status)) {
      actions.push(
        <EmrDisplay
          key={`renderEmr-${individualId}`}
          staff
          actions
          individual={individual}
          emrEditable
        />,
      )
    }

    if (['Active'].includes(role_status)) {
      actions.push(
        <DropDownItem
          key={`renderDischarge-${individualId}`}
          label="Discharge"
          onClick={
            escalated
              ? onToggleOpen
              : createClickHandler(commitDischarge, roleInput)
          }
        />,
      )
    }

    if (role_status === 'Discharged') {
      actions.push(
        <DropDownItem
          key={`renderReinvite-${individualId}`}
          disabled={!canBecomeActive || staffRoleExists}
          label="Re-invite"
          onClick={createClickHandler(commitReintroduce, roleInput)}
        />,
        !canBecomeActive && errorNotification,
        canBecomeActive && staffRoleExists && staffExistErrorNotification,
      )
    }

    if (role_status === 'Invited') {
      actions.push(
        <DropDownItem
          key={`renderRemove-${individualId}`}
          label="Remove"
          onClick={createClickHandler(commitRemove(roleType), roleInput)}
        />,
      )
    }

    return actions
  }

  return (
    <>
      <ActionsDropDown simple={staff} componentId={roleId}>
        {staff &&
          ['Invited', 'Active'].includes(role_status) && (
            <IndividualCliniciansListLoader individual={individual} />
          )}
        {['Invited', 'Removed'].includes(role_status) && (
          <DropDownItem
            key={`renderResend-${individualId}`}
            disabled={!canBecomeActive || staffRoleExists}
            label="Resend Invitation"
            onClick={createClickHandler(commitReinvite(roleType), roleInput)}
          />
        )}
        {['Invited', 'Removed'].includes(role_status) && (
          <>
            {!canBecomeActive && errorNotification}
            {canBecomeActive && staffRoleExists && staffExistErrorNotification}
          </>
        )}
        {canDoAdminActions && adminActions()}
      </ActionsDropDown>
      {canDoAdminActions && (
        <DischargeConfirmationModal
          rules={rules}
          isOpen={isOpen}
          onToggleOpen={onToggleOpen}
          legal_name={legal_name}
          onConfirm={createClickHandler(commitDischarge, roleInput)}
        />
      )}
    </>
  )
}

export default connect(StyleRules)(StaffIndividualsActions)
