// @flow

// Feedback Pages
import conditionalFormulaRangeDescriptionTranslations from 'platform_web/hooks/useConditionalFormulaRangeDescription/localizedStrings.json'
import onboardingStepsTranslations from 'platform_web/hooks/useOnboardingSteps/localizedStrings.json'

const hooksTranslations = {
  en: {
    conditionalFormulaRangeDescriptionHook:
      conditionalFormulaRangeDescriptionTranslations.en,
    onboardingStepsHook: onboardingStepsTranslations.en,
  },
  fr: {
    conditionalFormulaRangeDescriptionHook:
      conditionalFormulaRangeDescriptionTranslations.fr,
    onboardingStepsHook: onboardingStepsTranslations.fr,
  },
}

export default hooksTranslations
