// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Team from 'react-ui/assets/icons/team.svg'
import QuestionnaireInfoCard from 'react-ui/components/QuestionnaireInfoCard'

import { styleRules } from './UserQuestionnaireInfoWrapper.style'

import type { FelaStylesType } from 'react-ui/typing'

type PropsType = {
  firstContent?: Array<string>,
  firstTitle?: string,
  secondContent?: Array<string>,
  secondTitle?: string,
  styles: FelaStylesType,
}

const UserQuestionnaireInfoWrapper = (props: PropsType) => {
  const { firstContent, firstTitle, secondContent, secondTitle, styles } = props

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'userQuestionnaireComponent',
  })

  const benefits_of_innowell = [
    translation('benefitsOfInnowellOne'),
    translation('benefitsOfInnowellTwo'),
    translation('benefitsOfInnowellThree'),
    translation('benefitsOfInnowellFour'),
  ]

  const important_notes = [
    translation('importantNoteOne'),
    translation('importantNoteTwo'),
  ]

  return (
    <div className={styles.displayInline}>
      <QuestionnaireInfoCard
        title={firstTitle || translation('benefitsOfInnowellHeading')}
        points={firstContent || benefits_of_innowell}
        avatar={Team}
      />

      <QuestionnaireInfoCard
        title={secondTitle || translation('importantNotesHeading')}
        points={secondContent || important_notes}
        avatar={InfoCircle}
      />
    </div>
  )
}

export default connect(styleRules)(UserQuestionnaireInfoWrapper)
