// @flow

import * as React from 'react'
import { graphql } from 'react-relay'

import {
  HeroTableWithPercent,
  HeroTableWithTime,
} from 'react-ui/components/HeroTable'
import ReportCard from 'react-ui/components/ReportCard'

import ReportsView from './ReportsView'
import ReportWithSettings from './ReportWithSettings'

type PropsType = {
  print?: boolean,
  title: React.Node,
  variables: Object,
}

const query = graphql`
  query ContinuityCoordinationReportQuery(
    $from: DateTime!
    $to: DateTime!
    $tenants: [ID!]!
    $clinicians: [ID!]!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        continuity_service_discharge(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        continuity_system_use(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
      }
    }
  }
`

const ContinuityCoordinationReport = ({
  print,
  title,
  variables,
}: PropsType) => (
  <ReportsView print={print} title={title}>
    <ReportWithSettings query={query} variables={variables}>
      {data => {
        const {
          continuity_service_discharge,
          continuity_system_use,
        } = data.viewer.reports

        return (
          <React.Fragment>
            <ReportCard title="Discharged individuals">
              <div className="ContinuityServiceDischargeChart">
                <HeroTableWithPercent
                  traces={continuity_service_discharge.traces}
                />
              </div>
            </ReportCard>

            <ReportCard title="Individual use of the Innowell Platform">
              <div className="ContinuitySystemUseChart">
                <HeroTableWithTime traces={continuity_system_use.traces} />
              </div>
            </ReportCard>
          </React.Fragment>
        )
      }}
    </ReportWithSettings>
  </ReportsView>
)

export default ContinuityCoordinationReport
