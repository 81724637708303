// @flow

import React, { Suspense, useEffect } from 'react'

import TinyLoader from 'react-ui/components/LoadingSpinner/TinyLoader'

import useMultiSelect from '../../hooks/useMultiSelect'
import useEmployeesActions from '../hooks/useEmployeesActions'
import useEmployeesTable from '../hooks/useEmployeesTable'

import EmployeesTableControls from './EmployeesTableControls'
import MultiSelectActions from './MultiSelectActions'
import { Table } from './Table'

type PropsType = {
  queryType: string,
}

const StaffEmployeesTableLoader = (props: PropsType) => {
  const { queryType } = props

  const useEmployeesTablePayload = useEmployeesTable({ queryType })
  const { queryVariables, tableType } = useEmployeesTablePayload

  const multiSelectPayload = useMultiSelect()

  const {
    allSelected,
    hasSelectedRoleIds,
    setMultiSelectState,
    selectedUsers,
  } = multiSelectPayload

  const { actions } = useEmployeesActions({ tableType })

  // Feature Toggle
  const enableMultiSelect = window.GLOBALS?.features?.MULTI_SELECT

  useEffect(
    () => {
      setMultiSelectState({
        isMultiSelect: enableMultiSelect && !!actions.length,
      })
    },
    [tableType],
  )

  return (
    <>
      {/* Table Controls */}
      {!hasSelectedRoleIds &&
        !allSelected && (
          <EmployeesTableControls
            useEmployeesTablePayload={useEmployeesTablePayload}
          />
        )}

      {/* MultiSelect Actions */}
      {(hasSelectedRoleIds || allSelected) && (
        <MultiSelectActions
          selectedUsers={selectedUsers}
          actions={actions}
          multiSelectPayload={multiSelectPayload}
          queryVariables={queryVariables}
        />
      )}

      {/* Table Data */}
      <Suspense fallback={<TinyLoader message="Loading" />}>
        <Table
          useEmployeesTablePayload={useEmployeesTablePayload}
          multiSelectPayload={multiSelectPayload}
        />
      </Suspense>
    </>
  )
}

export default StaffEmployeesTableLoader
