// @flow

import { useSetState } from 'react-use'

import { COUNT } from '../constants/TableConstants'

export type UpdateQueryVariablesType = ({ [string]: string | boolean }) => void

export type QueryVariablesType = {
  [string]: string | boolean,
}

const defaultQueryVariables = {
  count: COUNT,
  search: '',
  sort: {
    column: 'name',
    direction: 'ASC',
  },
  sortCode: 'name-clinicianName',
}

const useCliniciansTable = () => {
  // Local state for Individual Table to handle filter, sort, and search query variables
  const [state, setState] = useSetState(defaultQueryVariables)

  const updateQueryVariables: UpdateQueryVariablesType = (
    updatedQueryVariables: QueryVariablesType,
  ) => {
    setState(updatedQueryVariables)
  }

  return {
    count: COUNT,
    updateQueryVariables,
    queryVariables: state,
  }
}

export default useCliniciansTable
