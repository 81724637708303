// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, FlexContainer, FlexItem, Heading, Text } from 'care-ui'

export type HelpNowCardProps = {
  provider: { [string]: string },
}

const HelpNowCard = (props: HelpNowCardProps) => {
  const { provider } = props

  const { logo_url, title, description, url, chat_url, phone_number } = provider

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'helpNowButtonComponent',
  })

  return (
    <Box
      border="subtle"
      borderRadius="sm"
      paddingX="sm"
      paddingY="sm"
      dataTestId="helpNowCard"
    >
      <FlexContainer wrap="nowrap" gap="xxl">
        <FlexItem>
          <Box width="100px" height="100px" boxShadow={1}>
            <img src={logo_url} alt="" width="100%" height="100%" />
          </Box>
        </FlexItem>

        <FlexItem>
          <FlexContainer direction="column">
            <>
              <Heading level={6} dataTestId="cardTitle">
                {title}
              </Heading>

              <Text>
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </Text>
            </>

            {/* Services | Buttons */}
            <FlexContainer gap="sm">
              {url && (
                <Button
                  variant="primary"
                  href={url}
                  target="_blank"
                  dataTestId="cardButton"
                >
                  {translation('website')}
                </Button>
              )}

              {chat_url && (
                <Button
                  variant="primary"
                  href={chat_url}
                  target="_blank"
                  dataTestId="cardButton"
                >
                  {translation('chat')}
                </Button>
              )}

              {phone_number && (
                <Button
                  variant="primary"
                  href={`tel:${phone_number}`}
                  dataTestId="cardButton"
                >
                  {`${translation('call')} ${phone_number}`}
                </Button>
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexItem>
      </FlexContainer>
    </Box>
  )
}

export default HelpNowCard
