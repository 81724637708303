//  @flow

import React, { useEffect } from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'
import useRouter from 'found/useRouter'
import { last } from 'lodash/fp'

import StaffDashboardSelection from 'components/Staff/StaffDashboardSelection'
import {
  clinicianInteractionEnd,
  clinicianInteractionStart,
} from 'services/analytics/events'
import { Button, Container } from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'

import type { ClinicianViewingIndividualBanner_clinician } from './__generated__/ClinicianViewingIndividualBanner_clinician.graphql'
import type { ClinicianViewingIndividualBanner_user } from './__generated__/ClinicianViewingIndividualBanner_user.graphql'

type PropsType = {
  clinician: ClinicianViewingIndividualBanner_clinician,
  user: ClinicianViewingIndividualBanner_user,
}

const buttonWrapperStyle = () => ({
  maxWidth: 'fit-content',
})

const ClinicianViewingIndividualBanner = (props: PropsType) => {
  const { match } = useRouter()
  const { params, routes } = match || {}
  const lastRoute = last(routes)
  const isGiveInputPage =
    lastRoute?.name ===
      'user_questionnaires_question_set_answer_by_clinician' ||
    lastRoute?.name === 'specificQuestionnaireShow'

  const { css } = useFela()

  useEffect(() => {
    clinicianInteractionStart()

    return () => {
      clinicianInteractionEnd()
    }
  }, [])

  const { user, clinician } = props

  return (
    <>
      <StaffDashboardSelection clinician={clinician} user={user} />
      {isGiveInputPage && (
        <Container>
          <div className={css(buttonWrapperStyle)}>
            <Button
              to={{
                name: 'user_dashboard',
                params: {
                  id: params.id,
                },
              }}
              variant="text"
              ariaLabel="Back to dashboard"
              dataTestId="back-to-dashboard"
              leftIcon={ChevronLeft}
            >
              Back to dashboard
            </Button>
          </div>
        </Container>
      )}
    </>
  )
}

const ClinicianViewingIndividualBannerLoader = createFragmentContainer(
  ClinicianViewingIndividualBanner,
  {
    user: graphql`
      fragment ClinicianViewingIndividualBanner_user on User {
        ...AvatarLoader_user
        ...StaffDashboardSelection_user
      }
    `,
    clinician: graphql`
      fragment ClinicianViewingIndividualBanner_clinician on User {
        ...StaffDashboardSelection_clinician
      }
    `,
  },
)

export default ClinicianViewingIndividualBannerLoader
