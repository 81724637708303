// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import useRouter from 'found/useRouter'
import { isEmpty, last } from 'lodash/fp'

import Button from 'react-ui/components/Button'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { jsonLocalStorage } from 'services/browserStorage'

const containerStyle = () => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1,
})

const buttonStyle = ({ theme }) => ({
  height: 'auto',
  lineHeight: 1,
  fontSize: '1rem',
  color: theme.palette.summaryGraphs.textColor,
})

const iconStyle = ({ theme }) => ({
  backgroundColor: theme.palette.summaryGraphs.backgroundColor,
  color: '#fff',
  borderRadius: '5px',
  padding: '3px 9px',
  marginRight: '5px',
})

const TrackableChartToggle = () => {
  const [chartSetting, setChartSetting] = useState()
  const { css } = useFela()

  const {
    unanswered_tenant_research_programs,
    show_individual_onboarding,
    completed_onboarding,
  } = useCurrentUser()

  const unanswered = !isEmpty(unanswered_tenant_research_programs)

  const { match } = useRouter()
  const { routes } = match || {}
  const lastRoute = last(routes)
  const isDashboardPage = ['dashboard', 'user_dashboard'].includes(
    lastRoute?.name,
  )

  const onboardingCompleted =
    !unanswered && !show_individual_onboarding && completed_onboarding

  useEffect(() => {
    const currentChartSetting =
      jsonLocalStorage.getItem('chartSetting') || 'GAUGE'
    setChartSetting(currentChartSetting)
  }, [])

  const NEW_SUMMARY_GRAPHS = window.GLOBALS.features?.NEW_SUMMARY_GRAPHS

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'trackableChartToggleComponent',
  })

  if (!NEW_SUMMARY_GRAPHS || !isDashboardPage || !onboardingCompleted)
    return null

  const toggleChart = () => {
    const newChartSetting = chartSetting === 'GAUGE' ? 'GRAPH' : 'GAUGE'
    jsonLocalStorage.setItem('chartSetting', newChartSetting)
    setChartSetting(newChartSetting)
    window.dispatchEvent(new Event('storage'))
  }

  const trendGraphsToggle = (
    <div className={css(containerStyle)} data-testid="trackableChartToggle">
      <span className={css(iconStyle)}>{translation('new')}</span>
      <div>
        {translation('checkoutMessage')}
        <Button onClick={toggleChart} extend={buttonStyle} invert naked>
          &nbsp;{translation('trendGraphs')}
        </Button>.
      </div>
    </div>
  )

  const gaugeToggle = (
    <div className={css(containerStyle)} data-testid="trackableChartToggle">
      <Button invert naked onClick={toggleChart} extend={buttonStyle}>
        {translation('switchBack')}
      </Button>
      &nbsp;{translation('oldDashboard')}
    </div>
  )

  return chartSetting === 'GRAPH' ? gaugeToggle : trendGraphsToggle
}

export default TrackableChartToggle
