// @flow

import React from 'react'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { some } from 'lodash/fp'

import { ActionsDropDown, DropDownItem } from 'react-ui/components/DropDown'
import { type UserMfaResetMutationVariables } from 'mutations/__generated__/UserMfaResetMutation.graphql'
import commitReinvite from 'mutations/Role/Reinvite'
import commitRemove from 'mutations/Role/Remove'
import { type RemoveGenericMutationVariables } from 'mutations/Role/Remove/__generated__/RemoveGenericMutation.graphql'
import { commit as commitMfaReset } from 'mutations/UserMfaReset'

import { type StaffActions_user } from './__generated__/StaffActions_user.graphql'

type RoleVariablesType = RemoveGenericMutationVariables
type UserVariablesType = UserMfaResetMutationVariables

type PropsType = {
  roles: Array<Object>,
  user: StaffActions_user,
}

const StaffActions = (props: PropsType) => {
  const { user, roles } = props
  const { id: userId, mfa_last_verified_at } = user
  const environment = useRelayEnvironment()

  const createRoleHandler = (action, { roleId, role_type }) => {
    const commitReinviteMutation = commitReinvite(role_type)
    const commitRemoveMutation = commitRemove(role_type)

    switch (action) {
      case 'reinvite':
        commitReinviteMutation({
          environment,
          variables: ({
            input: { roleId },
          }: RoleVariablesType),
        })
        break
      case 'remove':
        commitRemoveMutation({
          environment,
          variables: ({
            input: { roleId },
          }: RoleVariablesType),
        })
        break
      default:
        break
    }
  }

  const createUserHandler = (mutate, input) => {
    mutate({
      environment,
      variables: ({ input }: UserVariablesType),
    })
  }

  const renderResendInvitations = () => {
    const dropDownItems = []
    roles.map(
      ({ role_status, role_type, description, id: roleId }) =>
        ['Invited', 'Removed'].includes(role_status) &&
        dropDownItems.push(
          <DropDownItem
            key={`renderResendInvitations-${roleId}`}
            label={`Resend ${description} Invitation`}
            onClick={() => createRoleHandler('reinvite', { roleId, role_type })}
          />,
        ),
    )

    return dropDownItems
  }

  const renderRemoveRoles = () => {
    const dropDownItems = []
    roles.map(
      ({ role_status, role_type, description, id: roleId }) =>
        ['Invited', 'Active'].includes(role_status) &&
        dropDownItems.push(
          <DropDownItem
            key={`renderRemoveRoles-${roleId}`}
            label={`Remove ${description}`}
            onClick={() => createRoleHandler('remove', { roleId, role_type })}
          />,
        ),
    )

    return dropDownItems
  }

  const renderResetUserMfa = () => {
    const requiresMFA = some(role => role.tenant.requires_mfa)(roles)
    const mfaResetEligible = requiresMFA && mfa_last_verified_at

    return mfaResetEligible ? (
      <DropDownItem
        key={`renderResetUserMfa-${userId}`}
        label="Reset MFA"
        onClick={() => createUserHandler(commitMfaReset, { userId })}
      />
    ) : null
  }

  return (
    <ActionsDropDown componentId={userId} simple>
      {renderResendInvitations()}
      {renderRemoveRoles()}
      {renderResetUserMfa()}
    </ActionsDropDown>
  )
}

const StaffActionsLoader = createFragmentContainer(StaffActions, {
  user: graphql`
    fragment StaffActions_user on User {
      id
      mfa_last_verified_at
    }
  `,
})

export default StaffActionsLoader
