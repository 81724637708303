// @flow

import React from 'react'
import { connect } from 'react-fela'

import Accordion, { AccordionItem } from 'react-ui/components/Accordion'
import { Text } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => ({
  AccordionItem: {
    backgroundColor: 'white',
  },

  children: {
    padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(
      1.5,
    )}`,
  },

  headerContainer: {
    backgroundColor: theme.palette.section.white.backgroundColor,
  },

  header: {
    color: theme.Heading.color,
  },

  icon: {
    color: theme.care.palette.text.positive,
  },

  definitionDescription: {
    margin: `${theme.care.spacing.xs} 0 ${theme.care.spacing.sm}`,
    maxWidth: '70ch',
  },
})

type Props = FelaPropsType

const ServiceMetricsDefinitions = (props: Props) => {
  const { rules, styles } = props

  return (
    <>
      <Text size="lg" className={styles.definitionDescription}>
        {`Metrics are an important starting point for discussion around a
          service’s strengths and weaknesses. These metrics document the
          performance of your service after implementing the Innowell Platform
          into its pathways as usual practice. When sample sizes are small,
          descriptive statistics may be incorrect. Care should be taken with
          interpretation and extrapolation.`}
      </Text>
      <Accordion>
        <AccordionItem
          extend={(...args) => ({
            AccordionItem: rules.AccordionItem(...args),
            children: rules.children(...args),
            headerContainer: rules.headerContainer(...args),
            header: rules.header(...args),
            icon: rules.icon(...args),
          })}
          heading="Definitions"
        >
          <Text size="lg">
            <Text size="lg" as="span" bold>
              Time period:
            </Text>
            {` Unless otherwise stated, all metrics reference activities or times
              recorded during the selected time period. For example, the total
              number of individuals who responded to a questionnaire would only
              display the individuals who responded to the questionnaire during
              that selected time period.`}
          </Text>
          <Text size="lg">
            <Text size="lg" as="span" bold>
              Active User:
            </Text>
            {` A user account status becomes 'active' under insights if
              they have received a Platform invitation regardless if they have
              accepted the invitation or not, as long as the invitation was not
              'removed' and never 're-invited'.`}
          </Text>
          <Text size="lg">
            {`Active users include all individuals with an 'active' account status
              at any time during the selected time period. This includes a user
              that may have initially been 'active' and later switched to a 'read
              only' or 'discharged' status during the selected time period.`}
          </Text>
          <Text size="lg">
            {`Conversely, if a user had a 'read only' or 'discharged' status and
              was re-activated during the selected time period, they would also be
              counted as 'active'.`}
          </Text>
          <Text size="lg">
            <Text size="lg" as="span" bold>
              Innowell Platform email invitation:
            </Text>
            {` The time of Innowell Platform email invitation refers to the latest
              time between the time of first invitation sent and the time of
              invitation that was accepted by the user`}
          </Text>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default connect(styleRules)(ServiceMetricsDefinitions)
