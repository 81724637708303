// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import withRouter from 'found/withRouter'
import { flowRight } from 'lodash/fp'

import Spacer from 'react-ui/components/Spacer'
import { commit as commitFeedbackCreate } from 'mutations/Feedback/SubmitFeedback'
import { Heading, Text } from 'care-ui'

import { FeedbackFormLoader } from '../FeedbackForm'

import { feedbackPageViewer } from './query/FeedbackPage'

import type { FeedbackPage_viewer } from './query/__generated__/FeedbackPage_viewer.graphql'

type PropsType = {
  relay: Object,
  viewer: FeedbackPage_viewer,
}

const emptyFeedback = {
  topic: '',
  subject: '',
  description: '',
}

const FeedbackPage = (props: PropsType) => {
  const { viewer, relay: { environment } } = props

  const onFormSubmit = (values: Object, resetForm: () => void) => {
    commitFeedbackCreate({
      environment,
      viewer,
      onCompleted: resetForm,
      variables: {
        input: {
          feedback: {
            topic: values.topic,
            subject: values.subject,
            description: values.description,
          },
        },
      },
    })
  }

  const { t: translation } = useTranslation('feedback', {
    keyPrefix: 'feedbackPage.feedbackPage',
  })

  return (
    <Fragment>
      <Heading level={3} margin="xs">
        {translation('contactUsHeading')}
      </Heading>

      <Text>{translation('contactDescription')}</Text>

      <Spacer units={1} />

      <Text>
        <strong>{translation('reportingQuestion')}</strong>
        <Spacer units={0.5} />
        {translation('reportingAnswer')}
      </Text>

      <Spacer units={1} />

      <FeedbackFormLoader
        id="contactUsForm"
        onSubmit={onFormSubmit}
        feedback={emptyFeedback}
        viewer={viewer}
      />
    </Fragment>
  )
}

const withRelay = component =>
  createFragmentContainer(component, feedbackPageViewer)

export default flowRight([withRelay, withRouter])(FeedbackPage)
