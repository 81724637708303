// @flow

import React, { type Node } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import Link from 'found/Link'

import type { UserTrackableView_user_trackable } from './__generated__/UserTrackableView_user_trackable.graphql'

type PropsType = {
  children?: Node,
  user_trackable: UserTrackableView_user_trackable,
}

const UserTrackableView = (props: PropsType) => {
  const { children, user_trackable: { trackable, user } } = props

  return (
    <Link
      to={{
        name: 'user_score_card',
        params: { id: user.id, trackable_id: trackable.id },
      }}
    >
      {children || 'View Details'}
    </Link>
  )
}

export const UserTrackableViewLoader = createFragmentContainer(
  UserTrackableView,
  {
    user_trackable: graphql`
      fragment UserTrackableView_user_trackable on UserTrackable {
        id
        trackable {
          id
        }
        user {
          id
        }
      }
    `,
  },
)
