// @flow

// Supporting Pages
import supportingPagesTranslations from 'platform_web/pages/Supporting/localizedStrings.json'

const supportingTranslations = {
  en: {
    supportingPage: supportingPagesTranslations.en,
  },
  fr: {
    supportingPage: supportingPagesTranslations.fr,
  },
}

export default supportingTranslations
