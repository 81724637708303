// @flow

import React from 'react'
import { ThemeProvider, useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { merge } from 'lodash/fp'

import { useCurrentUser } from 'react-ui/contexts/UserContext'
import * as colors from 'react-ui/themes/synergy-base-theme/colors'
import {
  AssessmentRequesteeProvider,
  AssessmentRequestsList,
} from 'platform_web/features/AssessmentRequests'
import useChartToggle from 'platform_web/hooks/useChartToggle'
import { useFeatureToggle } from 'platform_web/hooks/useFeatureToggle'
import { Container, PageHeader, SuspenseLoader } from 'care-ui'

import { containerStyle } from './AssessmentRequestsPage.style'

const AssessmentRequestsPage = () => {
  const { theme } = useFela()
  const { id: currentUserId } = useCurrentUser()
  const { isSummaryGraph } = useChartToggle()

  const { REQUEST_ASSESSMENTS } = useFeatureToggle()

  const { t: translation } = useTranslation('assessment', {
    keyPrefix: 'assessmentRequestsPages',
  })

  if (!REQUEST_ASSESSMENTS) return null

  const themelette =
    isSummaryGraph &&
    merge(theme, {
      Button: {
        primary: {
          accent: colors.synBlue['800'],
          active: colors.synBlue['800'],
          base: colors.synBlue['900'],
          mutedBase: colors.synBlue['50'],
          mutedActive: colors.synBlue['100'],
          text: colors.white,
        },
      },
    })

  const linkTo = {
    name: 'dashboard',
  }

  return (
    <ThemeProvider theme={themelette}>
      <AssessmentRequesteeProvider>
        <PageHeader linkTo={linkTo}>
          {translation('requestedAssessmentsPageHeader')}
        </PageHeader>

        <SuspenseLoader>
          <Container extend={containerStyle}>
            <AssessmentRequestsList userId={currentUserId} />
          </Container>
        </SuspenseLoader>
      </AssessmentRequesteeProvider>
    </ThemeProvider>
  )
}

export default AssessmentRequestsPage
