// @flow

import React, { useState } from 'react'
import { graphql } from 'react-relay'

import HeroNumber from 'react-ui/components/HeroNumber'
import HeroTable from 'react-ui/components/HeroTable'
import ReportCard from 'react-ui/components/ReportCard'
import SegmentationTable from 'react-ui/components/SegmentationTable'
import createComponentId from 'shared/services/id'

import ReportWithSettings from '../reports/ReportWithSettings'

type PropsType = {
  componentId?: string,
  +dimensions: $ReadOnlyArray<{
    +key: string,
    +value: string,
  }>,
  +variables: Object,
}

const query = graphql`
  query SatisfactionIndividualSignInChartQuery(
    $from: DateTime!
    $to: DateTime!
    $tenants: [ID!]!
    $clinicians: [ID!]!
    $dimension: ReportsSatisfactionIndividualSignInDimensionEnum!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        satisfaction_individual_sign_in(
          dimension: $dimension
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }

        satisfaction_overall_individual_sign_in(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
      }
    }
  }
`

const defaultId = createComponentId(__filename)

const SatisfactionIndividualSignInChart = (props: PropsType) => {
  const { componentId = defaultId, dimensions, variables } = props

  const [selectedDimension, setSelectedDimension] = useState(dimensions[0].key)

  const allVariables = {
    ...variables,
    dimension: selectedDimension,
  }

  const onChangeDimension = event => {
    setSelectedDimension(event.target.value)
  }

  return (
    <div className={componentId}>
      <ReportWithSettings query={query} variables={allVariables}>
        {data => {
          const {
            satisfaction_individual_sign_in,
            satisfaction_overall_individual_sign_in,
          } = data.viewer.reports
          return (
            <ReportCard title="Use of the Innowell Platform">
              <HeroTable
                traces={satisfaction_overall_individual_sign_in.traces}
              />

              {/*
                 this rule is disabled as there is a smaller need atm for this and the effort to get it working
                 correctly is estimated high
                */}
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select onChange={onChangeDimension} value={selectedDimension}>
                {dimensions.map(({ key, value }) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>

              <SegmentationTable
                noHeaders
                formatNull={{
                  value0: true,
                }}
                traces={satisfaction_individual_sign_in.traces}
                columnConfig={{
                  value0: {
                    format: value => <HeroNumber format="0,0" value={value} />,
                    formatNull: true,
                  },
                }}
              />
            </ReportCard>
          )
        }}
      </ReportWithSettings>
    </div>
  )
}

export default SatisfactionIndividualSignInChart
