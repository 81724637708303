// @flow

import React from 'react'

import { UserCheckBox } from 'react-ui/components/Form'

import ServiceMetricsMultiSelect from '.'

type PropsType = {
  +clinicians: $ReadOnlyArray<{
    +id: string,
    +user: {
      +id?: string,
    },
  }>,
  onChange: (selectedClinicians: $ReadOnlyArray<string>) => void,
  +selectedClinicians: $ReadOnlyArray<string>,
}

const ServiceMetricsCliniciansSelectorBase = ({
  onChange,
  clinicians,
  selectedClinicians,
  ...props
}: PropsType) => (
  <ServiceMetricsMultiSelect
    data={clinicians}
    searchFields={['name']}
    selectedIds={selectedClinicians}
    singularLabel="clinician"
    onChange={onChange}
    renderOption={(clinician, optionProps) => (
      <UserCheckBox
        {...optionProps}
        block
        user={clinician.user}
        id={`service-metrics-clinician-selector-${clinician.id}`}
      />
    )}
    {...(props: any)}
  />
)

export default ServiceMetricsCliniciansSelectorBase
