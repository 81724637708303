// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import compose from 'lodash/fp/compose'

import FitbitLogo from 'react-ui/assets/images/FitbitLogo.svg'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import {
  trackFitbitConnected,
  trackFitbitNotInterested,
} from 'services/analytics/events'
import {
  privacyPolicyUrl,
  termsAndConditionsUrl,
} from 'services/commonExternalUrls'
import { Button } from 'care-ui'

import { styleRules } from './FitbitConnectActivity.style'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  authLink: string,
  closeModal?: boolean,
}

const FitbitConnectActivity = (props: PropTypes) => {
  const { authLink, closeModal, rules, styles } = props

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'fitbitPages.fitbitConnectActivity',
  })

  return (
    <Container extend={rules.container}>
      <FitbitLogo className={styles.logo} />
      <Heading level={2} extend={() => ({ textAlign: 'center' })}>
        {translation('modalHeading')}
      </Heading>

      <ul className={styles.list}>
        <li>{translation('bulletPoints.one')}</li>

        <li>{translation('bulletPoints.two')}</li>

        <li>{translation('bulletPoints.three')}</li>
      </ul>

      <span className={styles.note}>
        {translation('description')} &nbsp;
        <Link to={privacyPolicyUrl()}>{translation('privacyPolicy')}</Link>
        &nbsp;{translation('and')}&nbsp;
        <Link to={termsAndConditionsUrl()}>
          {translation('termsAndConditions')}
        </Link>.
      </span>

      <div className={styles.buttonGroup}>
        <Button
          dataTestId="fitbit-no"
          variant="secondary"
          onClick={compose(closeModal, trackFitbitNotInterested)}
        >
          {translation('notInterestedButton')}
        </Button>

        <Button
          dataTestId="fitbit-yes"
          variant="primary"
          onClick={trackFitbitConnected}
          href={authLink}
          target="_blank"
        >
          {translation('interestedButton')}
        </Button>
      </div>
    </Container>
  )
}

export default connect(styleRules)(FitbitConnectActivity)
