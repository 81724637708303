// @flow

import { useTranslation } from 'react-i18next'

export type OptionsType = {
  description: string,
  label: ?string,
}

const conditionalFormulaRangeDescription = params => {
  const {
    description,
    label,
    skip_reasons,
    translation,
    unAnsweredDescription,
  } = params

  const skippedLabel = translation('skipped')
  const unansweredLabel = translation('unanswered')

  if (label === skippedLabel) {
    return skip_reasons[description]
  }

  if (label === unansweredLabel) {
    return unAnsweredDescription
  }

  return description
}

function useConditionalFormulaRangeDescription(options: OptionsType) {
  const { description, label } = options || {}

  const { t: translation } = useTranslation('hooks', {
    keyPrefix: 'conditionalFormulaRangeDescriptionHook',
  })

  const skipReasonSuffix = translation('skipReasonSuffix')
  const unAnsweredDescription = `${translation(
    'unAnsweredDescription',
  )} ${skipReasonSuffix}`

  const skip_reasons = {
    irrelevant: `${translation('skipReasons.irrelevant')} ${skipReasonSuffix}`,
    later: `${translation('skipReasons.later')} ${skipReasonSuffix}`,
    with_clinician: `${translation(
      'skipReasons.with_clinician',
    )} ${skipReasonSuffix}`,
    with_support: `${translation(
      'skipReasons.with_support',
    )} ${skipReasonSuffix}`,
  }

  return {
    callbacks: {
      conditionalFormulaRangeDescription: () =>
        conditionalFormulaRangeDescription({
          description,
          label,
          skip_reasons,
          translation,
          unAnsweredDescription,
        }),
    },
  }
}

export default useConditionalFormulaRangeDescription
