// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import Background from 'react-ui/components/Background'
import { type UiStyleProps } from 'shared/ui/Forms/Fields/RadioSet/RadioSet'
import { Lock } from 'shared/ui/Typography/Icons'
import type {
  AnswerSetType,
  QuestionnaireType,
} from 'shared/utils/SimpleQuestionnaireTypes'

import OnboardingQuestionsForm from '../OnboardingQuestionsForm'

import { styleRules } from './OnboardingWizard.style'

import type { FelaRulesType } from 'react-ui/typing'

type PropsType = UiStyleProps & {
  handleWizardComplete: (values: AnswerSetType) => void,
  questionnaire: QuestionnaireType,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
}

const OnboardingWizard = (props: PropsType) => {
  const {
    handleWizardComplete,
    questionnaire,
    rules,
    styles = {},
    uiStyle,
  } = props

  const { t: translation } = useTranslation('onboarding', {
    keyPrefix: 'clinicianOnboardingPage.onboardingWizard',
  })

  return (
    <div className={styles.wizardContainer}>
      <div className={styles.asideContainer}>
        <Background
          backgroundName="primary"
          backgroundElements={['leavesHorizontal', 'cloudX1']}
          extend={rules}
        >
          <div className={styles.wizardContentContainer}>
            <p className={styles.wizardCardTitle}>{translation('aboutYou')}</p>

            <div className={styles.wizardFooterContainer}>
              <Lock addedClassName={styles.wizardLockIcon} />

              <p className={styles.wizardFooterText}>
                {translation('contentText')}
              </p>
            </div>
          </div>
        </Background>
      </div>

      <div className={styles.mainContentContainer}>
        <OnboardingQuestionsForm
          uiStyle={uiStyle}
          questionnaire={questionnaire}
          handleWizardComplete={handleWizardComplete}
        />
      </div>
    </div>
  )
}

export default connect(styleRules)(OnboardingWizard)
