/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type DataGridRowFooterContentLoader_user$ref = any;
type StaffClinicianActions_clinician$ref = any;
type StaffIndividualsList_clinician$ref = any;
type UserCardLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CliniciansTable_user$ref: FragmentReference;
declare export opaque type CliniciansTable_user$fragmentType: CliniciansTable_user$ref;
export type CliniciansTable_user = {|
  +current_role: {|
    +id?: string,
    +tenant?: ?{|
      +roles: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id?: string,
            +legal_name?: ?string,
            +preferred_name?: ?string,
            +clinician_assignments_as_clinician?: $ReadOnlyArray<{|
              +id: string
            |}>,
            +user?: {|
              +id: string,
              +name: string,
              +$fragmentRefs: AvatarLoader_user$ref & UserCardLoader_user$ref & DataGridRowFooterContentLoader_user$ref,
            |},
            +$fragmentRefs: StaffClinicianActions_clinician$ref & StaffIndividualsList_clinician$ref,
          |}
        |}>
      |}
    |},
  |},
  +id: string,
  +$refType: CliniciansTable_user$ref,
|};
export type CliniciansTable_user$data = CliniciansTable_user;
export type CliniciansTable_user$key = {
  +$data?: CliniciansTable_user$data,
  +$fragmentRefs: CliniciansTable_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  "current_role",
  "tenant",
  "roles"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CliniciansTablePaginationRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "CliniciansTable_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Tenant",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                {
                  "alias": "roles",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "roleTypes",
                      "value": [
                        "CLINICIAN"
                      ]
                    },
                    {
                      "kind": "Variable",
                      "name": "search",
                      "variableName": "search"
                    },
                    {
                      "kind": "Variable",
                      "name": "sort",
                      "variableName": "sort"
                    }
                  ],
                  "concreteType": "RoleConnection",
                  "kind": "LinkedField",
                  "name": "__CliniciansTable_roles_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RoleEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "legal_name",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "preferred_name",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ClinicianAssignment",
                                  "kind": "LinkedField",
                                  "name": "clinician_assignments_as_clinician",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "User",
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "name",
                                      "storageKey": null
                                    },
                                    {
                                      "args": null,
                                      "kind": "FragmentSpread",
                                      "name": "AvatarLoader_user"
                                    },
                                    {
                                      "args": null,
                                      "kind": "FragmentSpread",
                                      "name": "UserCardLoader_user"
                                    },
                                    {
                                      "args": null,
                                      "kind": "FragmentSpread",
                                      "name": "DataGridRowFooterContentLoader_user"
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "StaffClinicianActions_clinician"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "StaffIndividualsList_clinician"
                                }
                              ],
                              "type": "ClinicianRole",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cursor",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endCursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasNextPage",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '25a29e475b459c559993b404f0b8ac40';

module.exports = node;
