// @flow

import React from 'react'
import { useFela } from 'react-fela'

import Tooltip from 'react-ui/components/ToolTip'

type ClusterProbabilities = {
  +label: string,
  +probability: number,
}

type Props = {
  clusterProbabilities: $ReadOnlyArray<ClusterProbabilities>,
}

const textStyle = () => ({
  marginBottom: '1rem',
})

const listStyle = () => ({
  listStylePosition: 'inside',
  paddingLeft: '5px',
})

const listStyleItem = () => ({
  width: '70%',
  display: 'flex',
  justifyContent: 'space-between',
})

const StratifiedScoringTooltip = ({ clusterProbabilities }: Props) => {
  const { css } = useFela()

  const createStratifiedMarkdown = () => {
    const clusterProbabilitiesList = clusterProbabilities?.map((cluster, i) => (
      <li key={cluster.label} className={css(listStyleItem)}>
        <span>{`${i + 1}. ${cluster.label}`}</span>
        <span>{cluster.probability}%</span>
      </li>
    ))
    return (
      <div>
        <div className={css(textStyle)}>
          Stratified scoring provides an overall summary of a person&apos;s
          clinical needs based on their initial answers in 6 areas (depression,
          anxiety, psychosis, mania, functioning and suicidal thoughts and
          behaviours). The distinct groups have been derived using clustering
          analysis based on meaningful differences observed between the groups.
          This information should be used to guide personalised decisions about
          the level of care a person requires.
        </div>
        <div>Breakdown of this individual&apos;s cluster probability:</div>
        <ol className={css(listStyle)}>{clusterProbabilitiesList}</ol>
      </div>
    )
  }

  return (
    <Tooltip
      attachment="bottom right"
      targetAttachment="top left"
      width="500"
      offset="0px -90px"
      scale={0.5}
    >
      {createStratifiedMarkdown()}
    </Tooltip>
  )
}

export default StratifiedScoringTooltip
