// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { useRouter } from 'found'

import IndividualTasksItem from './IndividualTasksItem'

import type { AssessmentRequestsTask_individual } from './__generated__/AssessmentRequestsTask_individual.graphql'

type PropsType = {
  individual: ?AssessmentRequestsTask_individual,
}
const AssessmentRequestsTaskBase = ({ individual }: PropsType) => {
  const { router } = useRouter()
  const { user } = individual || {}

  const REQUEST_ASSESSMENTS = window.GLOBALS.features?.REQUEST_ASSESSMENTS

  if (!REQUEST_ASSESSMENTS) return null

  const routeName = user ? 'user_assessment_requests' : 'assessment_requests'
  const header = user ? 'Request assessment(s)' : 'View requested assessment(s)'
  const description = user
    ? 'Send an email with a list of assessments to be completed.'
    : 'View requested assessment(s) from your clinician and review previous results.'

  const handleClick = () => {
    router.push({
      name: routeName,
      params: {
        id: user?.id,
      },
    })
  }

  return (
    <IndividualTasksItem header={header} onClick={handleClick}>
      {description}
    </IndividualTasksItem>
  )
}

export default createFragmentContainer(AssessmentRequestsTaskBase, {
  individual: graphql`
    fragment AssessmentRequestsTask_individual on IndividualRole {
      user {
        id
      }
    }
  `,
})
