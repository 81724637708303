// @flow

// Onboarding Pages
import clinicianOnboardingTranslations from 'platform_web/pages/Onboarding/Clinician/localizedStrings.json'
import individualOnboardingTranslations from 'platform_web/pages/Onboarding/Individual/localizedStrings.json'
import individualQuestionnaireTranslations from 'platform_web/pages/Onboarding/IndividualQuestionnaire/components/Questionnaire/localizedStrings.json'
import setPasswordTranslations from 'platform_web/pages/Onboarding/SetPassword/localizedStrings.json'

const onboardingTranslations = {
  en: {
    clinicianOnboardingPage: clinicianOnboardingTranslations.en,
    individualOnboardingPage: individualOnboardingTranslations.en,
    individualQuestionnairePage: individualQuestionnaireTranslations.en,
    setPasswordOnboardingPage: setPasswordTranslations.en,
  },
  fr: {
    clinicianOnboardingPage: clinicianOnboardingTranslations.fr,
    individualOnboardingPage: individualOnboardingTranslations.fr,
    individualQuestionnairePage: individualQuestionnaireTranslations.fr,
    setPasswordOnboardingPage: setPasswordTranslations.fr,
  },
}

export default onboardingTranslations
